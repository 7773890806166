import { ArrowRightOutlined } from '@ant-design/icons';
import { Alert, Form, Input, Space } from 'antd';
import React from 'react';
import { FormItemList } from '../../../components';

const Replacement = ({ field, disabled }) => (
  <Space align="baseline" size={12}>
    <Form.Item
      {...field}
      key={`${field.key}-existingKV`}
      name={[field.name, 'existingKV']}
      rules={[
        { required: true, whitespace: true, message: `Please input the existing KV's name/key!` },
      ]}
      style={{ width: 190 }}
    >
      <Input disabled={disabled} placeholder="Existing KV" />
    </Form.Item>
    <ArrowRightOutlined />
    <Form.Item
      {...field}
      key={`${field.key}-newKV`}
      name={[field.name, 'newKV']}
      rules={[{ required: true, whitespace: true, message: `Please input the KV's new name/key!` }]}
      style={{ width: 190 }}
    >
      <Input disabled={disabled} placeholder="New KV" />
    </Form.Item>
  </Space>
);

const ReplaceAdServerTargetingKVsForm = ({ formInstance, isViewOnly, saveError }) => {
  return (
    <Form
      name="settings-replace-ad-server-targeting-kvs"
      form={formInstance}
      disabled={isViewOnly}
      preserve={false}
      requiredMark={false}
    >
      <FormItemList
        name={['value', 'replacements']}
        disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
        addPrompt="Add KV replacement"
        renderFormItem={({ field, formItemListName, disabled }) => (
          <Replacement
            formInstance={formInstance}
            field={field}
            disabled={disabled}
            parentFieldNamePath={formItemListName}
          />
        )}
      />
      {saveError && <Alert message={saveError.message} type="error" showIcon />}
    </Form>
  );
};

const settingConfig = {
  name: 'Replace Ad Server Targeting KVs',
  description: 'Sets new keys for existing ad server targeting key-value pairs.',
  Form: ReplaceAdServerTargetingKVsForm,
  initialSettingValue: {},
};

export default settingConfig;
