import { Form, Radio} from 'antd';

const RenderMode = ({ env, disabled }) => {  
  const radioOptions = [
    { label: 'Always', value: 'always' },    
    { label: 'Custom', value: 'api' },    
  ];
  return (
    <>
      <Form.Item label="Render Mode" name={[env, 'am_settings', 'render_mode']}
        tooltip={<div>
                  <p>Render Mode determines when the message is rendered</p>
                  <p>- Always: The message is always rendered if the criteria specified in these settings are met</p>
                  <p>- Custom: The message is rendered based on the results of an API call (Enable this setting and see API Mode for more details) </p>
                </div>}               
      >
        <Radio.Group options={radioOptions} optionType="button" disabled={disabled} />
      </Form.Item>
    </>
  );
};

export default RenderMode;
