import React from 'react';
import { generateErrorMessage } from '../../services/utils';
import { Alert, Grid, Empty } from 'antd';
import { Pie } from '@ant-design/plots';

const METRICS_PIE_10_COLOUR_PALETTE = [
    '#4eae91',
    '#519093',
    '#537195',
    '#8d6887',
    '#ad5c72',
    '#d54859',
    '#f27e7d',
    '#f2805a',
    '#ffd270',
    '#a7be7c',
];
const MetricsPie = React.memo(({ data, error, angleField, colorField, contentFormatter, tooltipFormatter, titleCustomHtml } = {}) => {
    const defaultContentFormatter = (_, values) => {
        return values.reduce((acc, v) => acc + v[angleField], 0);
    }

    const { useBreakpoint } = Grid;
    const screenSizes = useBreakpoint(); // { 'xs'|'sm'|'md'|'lg'|'xl'|'xxl': true|false }
    const isLoadingScreenSizes = Object.keys(screenSizes).length === 0;
    if (isLoadingScreenSizes) {
        return null; // This hack is required to ensure the height of the pie chart can be set according on the user's screen size
    }

    const isSmallScreen = screenSizes.xs && !screenSizes.sm;
    data = data?.map((v) => ({ ...v, [angleField]: Number(v[angleField]) }))
    const config = {
        loading: !data,
        data: data || [], // NOTE: Passing in a nil value for `data` will cause errors!
        angleField,
        colorField,
        height: screenSizes.xl ? 500 : 400,
        radius: 1,
        innerRadius: 0.7,
        appendPadding: 25,
        label: false,
        statistic: {
            title: {
                offsetY: isSmallScreen ? 40 : 46,
                style: { fontSize: isSmallScreen ? 15 : 17 },
                customHtml: titleCustomHtml,
            },
            content: {
                offsetY: isSmallScreen ? -24 : -28,
                style: { fontSize: isSmallScreen ? 26 : 31 },
                formatter: contentFormatter ? contentFormatter : defaultContentFormatter,
            },
        },
        tooltip: {
            formatter: tooltipFormatter,
        },
        legend: {
            offsetX: isSmallScreen ? 0 : -20,
            position: isSmallScreen ? 'bottom' : 'right',
            maxRow: 5,
        },
        theme: {
            colors10: METRICS_PIE_10_COLOUR_PALETTE,
            colors20: [...METRICS_PIE_10_COLOUR_PALETTE, '#7BB687'], // Add extra colour for "others" entry when org has more than 10 sites (which will display 10 sites + "others" = 11 pie segments)
        },
    };
    return error ? (
        <Empty description={<Alert message={generateErrorMessage(error)} type="error" showIcon />} />
    ) : (
        <Pie {...config} />
    );
});

export default MetricsPie;
