export function isDimensionSelectable({ dimension, selectedMetrics }) {
  return selectedMetrics.every((metric) => dimension.supported_metrics.includes(metric));
}

export function isMetricSelectable({ metric, selectedDimensions }) {
  return selectedDimensions.every((dimension) => metric.supported_dimensions.includes(dimension));
}

export function hasSelectableMetrics({ metrics, selectedDimensions }) {
  return metrics.some((metric) => isMetricSelectable({ metric, selectedDimensions }));
}
