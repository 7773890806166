import { Alert, Button, Card, Descriptions, List, Space, Tag, Typography } from 'antd';
import moment from 'moment';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import useSWRInfinite from 'swr/infinite';
import { CodeDiffViewerModal } from '../../components';
import api from '../../services/api';

const { Text } = Typography;

const PAGE_SIZE = 10;

const AUDIT_LOG_ACTION_TYPES = {
  CREATE: 'create',
  DELETE: 'delete',
  UPDATE: 'update',
};

/**
 * Returns a string or JSX with a user-friendly description of the audit log change `entry` passed in.
 */
function getEntryChangeDescription(entry) {
  const { action, changelog, type } = entry;

  const entityName = type === 'org' ? 'Organization' : type === 'website' ? 'Website' : ''; // Should never be `''` (means something went wrong)

  switch (action) {
    case AUDIT_LOG_ACTION_TYPES.CREATE:
      return `Created ${entityName}`;
    case AUDIT_LOG_ACTION_TYPES.DELETE:
      return `Deleted ${entityName}`;
    case AUDIT_LOG_ACTION_TYPES.UPDATE:
      return renderUpdateAction(changelog, entityName);
    default:
      // Something's gone wrong
      return `Unknown Change`;
  }
}
function renderUpdateAction(changelog, entityName) {
  const { before, after } = changelog;

  if (before?.Parameters?.ScriptSettings || after?.Parameters?.ScriptSettings) {
    // NOTE: With the current library the BE uses to generate the `changelog`, it's not possible to reliably determine when a setting has been added or removed; so we group all setting-related changes under "Changed Setting"
    return (
      <>
        Changed Setting (Prod):{' '}
        {renderSettingTags((before?.Parameters || after?.Parameters).ScriptSettings)}
      </>
    );
  } else if (
    before?.Parameters?.ScriptSettingsStaging ||
    after?.Parameters?.ScriptSettingsStaging
  ) {
    // NOTE: With the current library the BE uses to generate the `changelog`, it's not possible to reliably determine when a setting has been added or removed; so we group all setting-related changes under "Changed Setting"
    return (
      <>
        Changed Setting (Staging):{' '}
        {renderSettingTags((before?.Parameters || after?.Parameters).ScriptSettingsStaging)}
      </>
    );
  } else if (before?.Parameters?.Hook && after?.Parameters?.Hook) {
    return 'Updated Hook (Prod)';
  } else if (before?.Parameters?.Hook && !after?.Parameters?.Hook) {
    return 'Removed Hook (Prod)';
  } else if (!before?.Parameters?.Hook && after?.Parameters?.Hook) {
    return 'Added Hook (Prod)';
  } else if (before?.Parameters?.HookStaging && after?.Parameters?.HookStaging) {
    return 'Updated Hook (Staging)';
  } else if (before?.Parameters?.HookStaging && !after?.Parameters?.HookStaging) {
    return 'Removed Hook (Staging)';
  } else if (!before?.Parameters?.HookStaging && after?.Parameters?.HookStaging) {
    return 'Added Hook (Staging)';
  } else {
    return `Updated ${entityName}`;
  }
}
function renderSettingTags(settingsObj) {
  return Object.keys(settingsObj).map((settingKey) => (
    <Tag color="default" key={settingKey}>
      {settingKey.split('__')[0]}
    </Tag>
  ));
}

/**
 * Returns the <Link> component for the affected entity/scope of the audit log change `entry` passed in.
 */
const getEntryScopeEntityLink = (entry) => {
  const { type, org_id, org_name, website_id, website_domain } = entry;

  if (type === 'org') {
    return <Link to={`/orgs/${org_id}`}>{org_name}</Link>;
  } else if (type === 'website') {
    return <Link to={`/websites/${website_id}`}>{website_domain}</Link>;
  } else {
    // Something's gone wrong
    return 'Unknown';
  }
};

const AuditLogEntryDetails = ({ entry }) => {
  const { timestamp, user_email, changelog } = entry;
  const date = moment(timestamp);
  const value = {
    before: JSON.stringify(changelog.before, null, 2),
    after: JSON.stringify(changelog.after, null, 2),
  };

  return (
    <CodeDiffViewerModal
      modalTitle="Audit Log Entry Details"
      triggerRender={({ openModal }) => (
        <Button type="link" onClick={openModal}>
          Details
        </Button>
      )}
      value={value}
      contentBefore={
        <Descriptions bordered size="small" column={2}>
          <Descriptions.Item label="Date">{date.format('YYYY-MM-DD (HH:mm)')}</Descriptions.Item>
          <Descriptions.Item label="User">{user_email}</Descriptions.Item>
          <Descriptions.Item label="Scope">{getEntryScopeEntityLink(entry)}</Descriptions.Item>
          <Descriptions.Item label="Change">{getEntryChangeDescription(entry)}</Descriptions.Item>
        </Descriptions>
      }
    />
  );
};

const AuditLog = ({ orgId, websiteId }) => {
  let entityType = orgId ? 'Org' : 'Website';

  const getSWRKey = useCallback(
    (pageIndex, previousPageData) => {
      if (previousPageData && !previousPageData.entries) {
        // No more audit log entries being returned in the API response
        return null;
      }

      const apiFunctionName = `get${entityType}AuditLog`;

      const offset = pageIndex === 0 ? 0 : previousPageData.pagination.offset; // If not the first "page" of results, add `offset` cursor from previous page of audit log entries returned by API
      const pagination = { offset, page_size: PAGE_SIZE };
      const args = orgId ? { org_id: orgId, pagination } : { website_id: websiteId, pagination };

      return { apiFunctionName, args };
    },
    [entityType, orgId, websiteId]
  );

  const {
    data,
    size: page,
    setSize: setPage,
    isLoading: isLoadingInitial,
  } = useSWRInfinite(getSWRKey, ({ apiFunctionName, args }) => api[apiFunctionName](args));

  const entries = data?.flatMap(({ entries }) => entries); // `data` is an array of pages where each page is an array of entries returned in the response of that page's API call

  const shouldShowLoadMoreButton = data?.[data.length - 1]?.entries?.length === PAGE_SIZE;
  const isLoadingMore = page > 0 && data && typeof data[page - 1] === 'undefined';
  const loadMoreButton = shouldShowLoadMoreButton ? (
    <div
      style={{
        textAlign: 'center',
        margin: 16,
      }}
    >
      <Button
        onClick={() => {
          setPage(page + 1);
        }}
        loading={isLoadingMore}
      >
        Load more
      </Button>
    </div>
  ) : null;

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Alert
        message="Audit Log entries for newly created organizations & websites can take 30+ minutes to appear."
        type="info"
        showIcon
      />
      <Card>
        <List
          loading={isLoadingInitial}
          loadMore={loadMoreButton}
          dataSource={entries}
          renderItem={(entry) => {
            const date = moment(entry.timestamp);
            const relativeTime = date.fromNow();
            return (
              <List.Item actions={[<AuditLogEntryDetails entry={entry} />]}>
                <List.Item.Meta
                  description={
                    <Space size={[32, 0]} wrap>
                      <Text style={{ width: 140 }} ellipsis>
                        {relativeTime}
                        <br />
                        {date.format('YYYY-MM-DD (HH:mm)')}
                      </Text>
                      <Text style={{ width: 200 }} ellipsis>
                        {entry.user_email}
                      </Text>
                      <Text style={{ width: 250 }} ellipsis>
                        {getEntryScopeEntityLink(entry)}
                      </Text>
                      <Text ellipsis>{getEntryChangeDescription(entry)}</Text>
                    </Space>
                  }
                />
              </List.Item>
            );
          }}
        />
      </Card>
    </Space>
  );
};

export default AuditLog;
