import addAAXDemand from './addAAXDemand';
import addBTDemand from './addBTDemand';
import addBTXDemand from './addBTXDemand';
import addRunOfNetworkIds from './addRunOfNetworkIds';
import defaultCreativeSettings from './defaultCreativeSettings';
import enableAmazon from './enableAmazon';
import enableBTServer from './enableBTServer';
import enableBTServerForAAX from './enableBTServerForAAX';
import enableLegacySizesReplacer from './enableLegacySizesReplacer';
import enableRelay from './enableRelay';
import enableRelayForAAX from './enableRelayForAAX';
import enableSnigel from './enableSnigel';
import hideDOMElementsViaCSS from './hideDOMElementsViaCSS';
import insertCustomCSS from './insertCustomCSS';
import mapSlotsToSpaces from './mapSlotsToSpaces';
import prebidConfig from './prebidConfig';
import prebidTimeout from './prebidTimeout';
import priceFloor from './priceFloor';
import refreshOnReturnToPage from './refreshOnReturnToPage';
import replaceAdServerTargetingKVs from './replaceAdServerTargetingKVs';
import removeSizes from './removeSizes';
import rewriteGoogletagSlots from './rewriteGoogletagSlots';
import scrapeGoogletagSlots from './scrapeGoogletagSlots';
import scrapePrebid from './scrapePrebid';
import spaceRefreshOnInterval from './spaceRefreshOnInterval';

/**
 * Unique settings are settings which if set on both the org and website, must always use the website value.
 * The website setting will always override the corresponding setting in the org.
 */
const UNIQUE_SETTINGS = {
  addAAXDemand,
  addBTDemand,
  addBTXDemand,
  addRunOfNetworkIds,
  defaultCreativeSettings,
  enableAmazon,
  enableBTServer,
  enableBTServerForAAX,
  enableRelay,
  enableRelayForAAX,
  enableSnigel,
  enableLegacySizesReplacer,
  prebidTimeout,
  priceFloor,
  refreshOnReturnToPage,
  rewriteGAMSlots: rewriteGoogletagSlots, // (setting key is `rewriteGAMSlots` which follows now-outdated naming convention)
  spaceRefreshOnInterval,
};
Object.keys(UNIQUE_SETTINGS).forEach((key) => {
  UNIQUE_SETTINGS[key].isUniqueSetting = true;
});

/**
 * Shared settings are settings which if set on both the org and website, can either be used together or overriden by the website setting.
 * When used together, the org & website setting values are merged together according to the setting handler in the recovery script.
 * Otherwise, if desired, the website setting can be configured to override the corresponding setting in the org.
 *
 * Shared setting keys have `__org` & `__website` appended to them in order for both setting values to be used by the handler in the recovery script.
 */
const SHARED_SETTINGS = {
  hideDOMElementsViaCSS,
  insertCustomCSS,
  mapSlotsToSpaces,
  prebidConfig,
  removeSizes,
  replaceAdServerTargetingKVs,
  scrapeGoogletagSlots,
  scrapePrebid,
};

export const ORG_SETTINGS = Object.entries({ ...UNIQUE_SETTINGS, ...SHARED_SETTINGS }).reduce(
  (orgSettings, [settingKey, { initialSettingValue, ...settingData }]) => {
    const orgSettingKey = settingData.isUniqueSetting ? settingKey : `${settingKey}__org`;

    const initialSetting = { _enabled: false, value: initialSettingValue };
    orgSettings[orgSettingKey] = { ...settingData, initialSetting };
    return orgSettings;
  },
  {}
);

export const WEBSITE_SETTINGS = Object.entries({ ...UNIQUE_SETTINGS, ...SHARED_SETTINGS }).reduce(
  (websiteSettings, [settingKey, { initialSettingValue, ...settingData }]) => {
    const websiteSettingKey = settingData.isUniqueSetting ? settingKey : `${settingKey}__website`;

    const initialSetting = { _enabled: false, value: initialSettingValue };
    // Unique settings set on the website will always override the associated setting set on the org
    initialSetting._overrides_org = settingData.isUniqueSetting ? true : false;

    websiteSettings[websiteSettingKey] = { ...settingData, initialSetting };
    return websiteSettings;
  },
  {}
);

// TODO: Add tests/refactor to ensure these settings configs always have the shape you want them to
// TODO: Add tests to make sure the correct setting values get saved during API calls
