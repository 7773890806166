import { Alert, Form, Input } from 'antd';
import React from 'react';
import { CodeEditor } from '../..';
import { validateJson } from '../../../services/utils';

const relayAAXConfigPlaceholder = `{
  "pubmatic": {
    "publisherId": "935800"
  },
  "ix": {
    "siteId": "776528"
  }
}`;

const EnableRelayForAAXForm = ({ formInstance, isViewOnly, saveError }) => (
  <Form
    name="setting-enable-relay-for-aax"
    form={formInstance}
    disabled={isViewOnly}
    preserve={false}
    requiredMark={false}
  >
    <Form.Item
      name={['value', 'sid']}
      label="Seller ID (sid)"
      tooltip="Set to the corresponding `seller_id` found in `sellers.json`."
      rules={[
        {
          required: true,
          message: 'Please enter a valid Seller ID!',
        },
      ]}
    >
      <Input placeholder="AAX3221EY" />
    </Form.Item>
    <Form.Item
      name={['value', 'asi']}
      label="Seller domain (asi)"
      tooltip="Should typically be set to 'aax.media'; otherwise, set it to the domain of the `sellers.json` file."
      rules={[
        {
          required: true,
          message: 'Please enter a valid Seller domain (asi)!',
        },
      ]}
    >
      <Input placeholder="aax.media" />
    </Form.Item>
    <Form.Item
      name={['value', 'relayAAXConfig']}
      label="Relay AAX Config"
      tooltip="A valid JSON object which consists of bidders and their parameters"
      labelCol={{ span: 24 }}
      rules={[
        {
          required: true,
          validator: (rule, value) => validateJson(value),
          message: 'Please enter a valid relay AAX Config JSON object!',
        },
      ]}
    >
      <CodeEditor
        placeholder={relayAAXConfigPlaceholder}
        height={360}
        disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
      />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const settingConfig = {
  name: 'Enable Kevel Relay (AAX)',
  description:
    'Enables and configures AAX demand via the Relay bidder (s2s adapter) in our auctions.',
  Form: EnableRelayForAAXForm,
  // Transform relay config to and from JS Object <-> JSON string because form must use string object but we want to store the value as an Object
  transformBeforeInit: ({ relayAAXConfig, ...rest }) => ({
    relayAAXConfig: JSON.stringify(relayAAXConfig, null, 2),
    ...rest,
  }),
  transformBeforeSave: ({ relayAAXConfig, ...rest }) => ({
    relayAAXConfig: JSON.parse(relayAAXConfig),
    ...rest,
  }),
  initialSettingValue: {},
};

export default settingConfig;
