import { Button, message, Modal, Space } from 'antd';
import React, { useState } from 'react';
import { useAsync } from '../../services/hooks';
import { generateErrorMessage, validateJson } from '../../services/utils';
import CodeEditor from './CodeEditor';
import CodeDiffViewer from './CodeDiffViewer';

export const CodeDiffViewerModal = ({
  buttonText,
  triggerRender,
  modalTitle,
  contentBefore,
  width = 1000,
  value,
  language,
  splitView,
  leftTitle,
  rightTitle,
  diffViewerHeight,
}) => {
  const [isVisible, setVisibility] = useState(false);
  const openModal = () => {
    setVisibility(true);
  };

  return (
    <>
      {triggerRender ? (
        triggerRender({
          openModal,
        })
      ) : (
        <Button type="primary" onClick={openModal}>
          {buttonText}
        </Button>
      )}
      <Modal
        title={modalTitle}
        centered
        width={width}
        visible={isVisible}
        onCancel={() => {
          setVisibility(false); // Close modal
        }}
        footer={null}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          {contentBefore}
          <CodeDiffViewer
            value={value}
            language={language}
            splitView={splitView}
            leftTitle={leftTitle}
            rightTitle={rightTitle}
            height={diffViewerHeight}
          />
        </Space>
      </Modal>
    </>
  );
};

export const CodeEditorModalStatic = ({
  buttonText,
  triggerRender,
  modalTitle,
  value,
  language,
}) => {
  const [isVisible, setVisibility] = useState(false);
  const openModal = () => {
    setVisibility(true);
  };

  return (
    <>
      {triggerRender ? (
        triggerRender({
          openModal,
        })
      ) : (
        <Button type="primary" onClick={openModal}>
          {buttonText}
        </Button>
      )}
      <Modal
        title={modalTitle}
        centered
        width={800}
        visible={isVisible}
        onCancel={() => {
          setVisibility(false); // Close modal
        }}
        footer={null}
      >
        <CodeEditor value={value} language={language} />
      </Modal>
    </>
  );
};

const CodeEditorModal = ({
  buttonText,
  buttonProps = {},
  triggerRender,
  modalTitle,
  okText = 'Save',
  editorPlaceholder = 'Input code here...',
  ExtraFooterContent,
  language,
  transformBeforeSave = (values) => values,
  saveCode,
  onSuccess,
}) => {
  const [isVisible, setVisibility] = useState(false);
  const [editorCode, setEditorCode] = useState('');
  const { execute: save, status: processStatus } = useAsync(saveCode);
  const isLoading = processStatus === 'pending';
  const isEditable = !isLoading;
  const openModal = () => {
    setVisibility(true);
  };
  const closeModal = () => {
    setVisibility(false);
  };
  const onOk = () => {
    validateJson(editorCode)
      .then(transformBeforeSave)
      .then(save)
      .then(onSuccess)
      .then(closeModal)
      .catch((error) => {
        message.error(generateErrorMessage(error)); // TODO: Handle this
      });
  };

  return (
    <>
      {triggerRender ? (
        triggerRender({
          openModal,
        })
      ) : (
        <Button type="primary" onClick={openModal} {...buttonProps}>
          {buttonText}
        </Button>
      )}
      <Modal
        title={modalTitle}
        bodyStyle={{
          height: 600,
          overflowY: 'scroll',
        }}
        centered
        width={800}
        visible={isVisible}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        onOk={onOk}
        confirmLoading={isLoading}
        onCancel={closeModal}
        afterClose={() => {
          // Clear Editor contents (not sure why `destroyOnClose` doesn't do this for us, may be the way the Editor library works)
          setEditorCode('');
        }}
        footer={
          <>
            {ExtraFooterContent && <ExtraFooterContent setEditorCode={setEditorCode} />}
            <Button
              key="back"
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              loading={isLoading}
              onClick={() => {
                onOk();
              }}
            >
              {okText}
            </Button>
          </>
        }
      >
        <CodeEditor
          value={editorCode}
          onChange={(code) => {
            if (isEditable) {
              setEditorCode(code);
            }
          }} // Disable editing while upload is in process
          placeholder={editorPlaceholder}
          language={language}
        />
      </Modal>
    </>
  );
};

export default CodeEditorModal;
