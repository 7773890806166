import { EditOutlined } from '@ant-design/icons';
import { Alert, Checkbox, Col, Form, Input, message, Modal, Row, Space, Switch } from 'antd';
import React, { useCallback } from 'react';
import { mutate } from 'swr';
import { DisabledContent } from '../../components';
import api from '../../services/api';
import { SaveEntityModal } from '../../services/handlers';
import {
  compareAlphabetically,
  formatWebsiteDomain,
  generateErrorMessage,
  parseValidWebsiteDomains,
  validateWebsiteDomainField,
  validateWebsiteDomainsField,
} from '../../services/utils';

const EditWebsitesForm = ({
  formInstance,
  initialValues,
  saveError,
  isBulkEdit,
  org,
  orgWebsites,
}) => {
  // Track `prod_params.content_enabled` form value to grey-out related fields when content is not selected as enabled
  const contentEnabled = Form.useWatch(['prod_params', 'content_enabled'], formInstance);

  return (
    <Form
      name="edit-websites"
      form={formInstance}
      preserve={false}
      requiredMark={false}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      {isBulkEdit ? (
        <Alert
          style={{ marginBottom: '16px' }}
          message="CAUTION"
          description={`Values in the form fields below will be applied to all websites whose domains are included in the "Domains" field (and will overwrite any of these values currently set for those websites)! If a field is left unfilled, its default value will still be applied to the websites! Domains entered for websites which do not exist in the org will simply be ignored.`}
          type="warning"
        />
      ) : null}
      <Form.Item label="Organization">
        <span>
          {org.name} ({org.id})
        </span>
      </Form.Item>
      {isBulkEdit ? (
        <Form.Item
          name="domains"
          label="Domains"
          tooltip={`Each domain entered must correspond to the domain (case-sensitive) of a website found in the "${org.name}" organization.`}
          validateTrigger="onBlur"
          rules={[
            {
              validator: (rule, value) => validateWebsiteDomainsField(value, { orgWebsites }), // Only accepts valid domains that correspond to websites which exist in the org
            },
          ]}
        >
          <Input.TextArea
            placeholder="Enter each domain on a new line - e.g. &#13;domain1.com &#13;domain2.com"
            autoSize={{ minRows: 2, maxRows: 5 }}
          />
        </Form.Item>
      ) : (
        <Form.Item
          name="domain"
          label="Domain"
          rules={[
            {
              validator: (rule, value) => validateWebsiteDomainField(value), // Only accepts a valid domain
            },
            // When editing an existing website, show warning if the user attempts to change the domain
            {
              validator: (rule, value) =>
                value !== initialValues.domain
                  ? Promise.reject('Editing the Website domain can break revenue reporting.')
                  : Promise.resolve(value),
              warningOnly: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      )}
      <Form.Item name="widget" label="Widget" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item
        name={['prod_params', 'prebidjs_version']}
        label="Prebid.js Version"
        tooltip="Custom Prebid.js version set as part of some publishers' integrations (e.g. FirstImpression)."
      >
        <Input />
      </Form.Item>
      <Form.Item name={['prod_params', 'content_enabled']} label="Enabled" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
        <DisabledContent component={Row} isDisabled={!contentEnabled}>
          <Col span={12}>
            <Form.Item name={['prod_params', 'mobile_enabled']} valuePropName="checked" noStyle>
              <Checkbox>Mobile</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={['prod_params', 'dfp_enabled']} valuePropName="checked" noStyle>
              <Checkbox>GAM</Checkbox>
            </Form.Item>
          </Col>
        </DisabledContent>
      </Form.Item>
      {saveError && <Alert message={saveError.message} type="error" showIcon />}
    </Form>
  );
};

function onEditSuccess({ website, websites, errors, org, clearSelection }) {
  if (errors?.length > 0) {
    Modal.error({
      title: 'Errors occurred while editing websites',
      content: (
        <Space direction="vertical" size={8} style={{ marginTop: 8, width: '100%' }}>
          {errors.map((error, index) => (
            <Alert
              key={index}
              message={error?.meta?.domain}
              description={generateErrorMessage(error)}
              type="error"
            />
          ))}
          {websites.length === 1
            ? `Nevertheless, ${websites[0].domain} was still successfully updated.`
            : websites.length > 1
            ? `Nevertheless, ${websites.length} websites were still successfully updated.`
            : null}
        </Space>
      ),
      width: 500,
    });
  } else if (website || websites.length === 1) {
    let websiteDomain = website ? website.domain : websites[0].domain;
    message.success(`${websiteDomain} successfully updated!`);
  } else if (websites.length > 1) {
    message.success(`${websites.length} websites successfully updated!`);
  }

  if (clearSelection) {
    // If the bulk edit was for selected websites, clear the selection
    clearSelection();
  }

  // Trigger websites refresh to reflect update
  if (website) {
    mutate(['/WebsiteGet', website.id], website, false);
  }
  mutate(['/WebsiteList', org.id]);
}

export const EditWebsiteModal = ({
  buttonText = 'Edit',
  buttonProps,
  triggerRender,
  org,
  website,
  clearSelection, // optional - clears selection when editing 1 selected website
}) => {
  // Returns the edited/updated website entity we need to send to the /WebsiteBulkUpdate API call
  const transformBeforeSave = ({ domain, ...rest }) => ({
    org_id: org.id,
    id: website.id,
    domain: formatWebsiteDomain(domain),
    ...rest,
  });

  const onSuccess = useCallback(
    ({ website, websites, errors }) => {
      onEditSuccess({ website, websites, errors, org, clearSelection });
    },
    [org, clearSelection]
  );

  return (
    <SaveEntityModal
      buttonText={buttonText}
      buttonProps={buttonProps}
      triggerRender={triggerRender}
      modalTitle="Edit Website"
      transformBeforeSave={transformBeforeSave}
      saveEntity={api.updateWebsite}
      formComponent={EditWebsitesForm}
      formComponentProps={{ isBulkEdit: false, org }}
      formInitialValues={website}
      onSuccess={onSuccess}
    />
  );
};

export const EditWebsitesModal = ({
  org,
  orgWebsites = [],
  triggerRender,
  selectedWebsites, // optional - pre-populates Domains field with domain names of selected websites in the org to edit
  clearSelection, // optional - clears selection when editing selected websites
}) => {
  // Returns the edited/updated website entities we need to send to the /WebsiteBulkUpdate API call
  const transformBeforeSave = (values) => {
    const { validDomains } = parseValidWebsiteDomains(values.domains, { orgWebsites }); // `validDomains` will be guaranteed to be length > 1 if the form's Domains field was validated with `validateWebsiteDomainsField`
    const websitesToUpdate = validDomains.map((domain) =>
      orgWebsites.find((website) => website.domain === domain)
    );

    return websitesToUpdate.map(({ id, domain }) => ({
      id,
      domain,
      widget: values.widget,
      prod_params: values.prod_params,
    }));
  };

  const onSuccess = useCallback(
    ({ website, websites, errors }) => {
      onEditSuccess({ website, websites, errors, org, clearSelection });
    },
    [org, clearSelection]
  );

  const domainsFieldInitialValue = selectedWebsites
    ? selectedWebsites
        .map((selectedWebsite) => selectedWebsite?.domain || '')
        .sort(compareAlphabetically)
        .join('\n')
    : '';

  return (
    <SaveEntityModal
      buttonText="Edit"
      buttonProps={{
        icon: <EditOutlined />,
        type: 'default',
      }}
      triggerRender={triggerRender}
      modalTitle="Edit Websites"
      transformBeforeSave={transformBeforeSave}
      saveEntity={api.updateWebsites}
      formComponent={EditWebsitesForm}
      formComponentProps={{ isBulkEdit: true, org, orgWebsites }}
      formInitialValues={{
        domains: domainsFieldInitialValue,
        widget: false,
        prod_params: {
          mobile_enabled: false,
          dfp_enabled: false,
          prebidjs_version: '',
          content_enabled: false,
        },
      }}
      onSuccess={onSuccess}
    />
  );
};
