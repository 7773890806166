import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { SWRProvider } from './services/api';
import { StoreProvider } from './services/store';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  // <React.StrictMode> Removed until antd fixes this issue: https://github.com/ant-design/ant-design/issues/22493
  <StoreProvider>
    <SWRProvider>
      <App />
    </SWRProvider>
  </StoreProvider>,
  // </React.StrictMode>
  document.getElementById('root')
);

if (process.env.REACT_APP_USE_MOCK_API) {
  // Directs all the API requests to the mock API (using a Service Worker) instead of the development or production API
  // NOTE: Ensure this is only imported/run in your local dev environment!
  const { worker } = require('./test/browser');
  worker.start();
} else {
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister(); // NOTE: This interferes with the mock API Service Worker
}
