import { Form, Select, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import { SEARCH_PARAM_KEYS } from '../../services/constants';
import { useSessionUser } from '../../services/hooks';
import { sortAlphabeticallyWithBTOrgFirst } from '../../services/utils';

export const useScopePageByOrg = ({
  waitToScopeOrg,
  orgIdOverride,
  limitToOrgAdminAccess,
} = {}) => {
  const sessionUser = useSessionUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const orgIdParam = searchParams.get(SEARCH_PARAM_KEYS.ORG_ID);

  const { data: orgs, error: orgsError, isLoading: isLoadingOrgs } = useSWR('/OrgList', {
    revalidateOnFocus: false,
  });

  const filteredOrgs = orgs?.filter((org) =>
    sessionUser.hasAccessForOrg({
      orgId: org.id,
      permittedRoles: limitToOrgAdminAccess
        ? ['orgadmin']
        : ['orgadmin', 'orguser' /*, 'websiteuser'*/],
    })
  );

  const sortedOrgs = useMemo(
    () => (filteredOrgs ? sortAlphabeticallyWithBTOrgFirst(filteredOrgs) : filteredOrgs),
    [filteredOrgs]
  );

  let orgSelectProps = {
    isLoading: isLoadingOrgs || waitToScopeOrg,
    isLocked: filteredOrgs?.length === 1 || orgIdOverride,
    onChange: useCallback(
      (selectedOrgId) => {
        // Disable `onChange` when org id is forced with `orgIdOverride`
        if (orgIdOverride) {
          return;
        }

        // Update the org id search param in the URL to the newly selected org's id (which will in turn also update the `scopedOrg`)
        const newSearchParams = new URLSearchParams(searchParams);
        if (selectedOrgId) {
          newSearchParams.set(SEARCH_PARAM_KEYS.ORG_ID, selectedOrgId);
        }
        setSearchParams(newSearchParams);
      },
      [orgIdOverride, searchParams, setSearchParams]
    ),
  };

  let scopedOrg;
  if (!waitToScopeOrg && sortedOrgs?.length) {
    orgSelectProps.options = sortedOrgs.map((org) => ({ label: org.name, value: org.id }));

    // Set scoped org according to the following (by order of priority): `orgIdOverride` -> `orgIdParam` -> `sessionUser.scopedOrgId` -> first selectable org in `sortedOrgs`
    if (orgIdOverride) {
      scopedOrg = sortedOrgs.find(({ id }) => id == orgIdOverride); // ignore current org id param in URL and use override provided
    } else if (orgIdParam) {
      scopedOrg = sortedOrgs.find(({ id }) => id == orgIdParam); // use org id param in URL (default behaviour)
    } else if (sessionUser.scopedOrgId) {
      scopedOrg = sortedOrgs.find(({ id }) => id == sessionUser.scopedOrgId); // use the org id stored in the global user state (fallback for when no org id param is available)
    }

    if (!scopedOrg) {
      // If there are no org id sources (override, search param, global user state),
      // or if the org id source to use is not found in the list of orgs they have access to for this page,
      // use the first selectable org as a final fallback
      scopedOrg = sortedOrgs?.[0];
    }

    orgSelectProps.value = scopedOrg?.id;
  }

  // Update the org id in the URL & global user state to reflect the currently selected scoped org when necessary
  useEffect(() => {
    if (scopedOrg?.id) {
      if (orgIdParam != scopedOrg.id) {
        setSearchParams({ [SEARCH_PARAM_KEYS.ORG_ID]: scopedOrg.id }, { replace: true });
      }
      if (sessionUser.scopedOrgId != scopedOrg.id) {
        sessionUser.setScopedOrgId(scopedOrg.id);
      }
    }
  }, [scopedOrg, orgIdParam, setSearchParams, sessionUser]);

  return { scopedOrg, orgSelectProps, orgsError };
};

export const ScopePageByOrg = ({
  scopedOrg,
  orgSelectProps: { isLocked, options, isLoading, onChange, value },
}) => (
  <Form.Item label="Organization" style={{ minWidth: 330 }}>
    {isLocked ? (
      <Typography.Text strong>{scopedOrg.name}</Typography.Text>
    ) : (
      <Select
        options={options}
        loading={isLoading}
        onChange={onChange}
        value={value}
        showSearch
        optionFilterProp="label"
      />
    )}
  </Form.Item>
);
