import { KeyOutlined, LogoutOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Layout, Menu, Skeleton, Space } from 'antd';
import React, { useState } from 'react';
import api from '../services/api';
import { useSessionUser } from '../services/hooks';
import { useStore } from '../services/store';
import { capitalize } from '../services/utils';
import ChangePassword from './ChangePassword';
import ImpersonateUser from './ImpersonateUser';
import styles from './Navbar.module.less';
import UniversalSearch from './UniversalSearch';

const Navbar = ({ height }) => {
  const [isChangePasswordModalVisible, setChangePasswordModalVisibility] = useState(false);
  const [isImpersonateUserModalVisible, setImpersonateUserModalVisibility] = useState(false);

  const {
    isImpersonatingUser,
    stopImpersonatingUser,
    isLoaded: userIsLoaded,
    isInternalUser: userIsInternalUser,
    isSystemAdmin: userIsSystemAdmin,
    email: userEmail,
  } = useSessionUser();
  const presumedUserName = userEmail && capitalize(userEmail.split('@')[0]);

  const { dispatch } = useStore();
  const onMenuClick = ({ key }) => {
    if (key === 'change-password') {
      setChangePasswordModalVisibility(true);
    } else if (key === 'impersonate-user') {
      setImpersonateUserModalVisibility(true);
    } else if (key === 'stop-impersonating-user') {
      stopImpersonatingUser();
    } else if (key === 'logout') {
      api.signOut().then(() => {
        dispatch({ type: 'UNAUTHENTICATE' });
      });
    }
  };

  const menu = (
    <Menu
      className={styles.userDropdown}
      onClick={onMenuClick}
      items={[
        {
          key: 'profile',
          label: userEmail,
          icon: <UserOutlined />,
          disabled: true,
          className: styles.userEmail,
        },
        ...(isImpersonatingUser
          ? [
              { type: 'divider' },
              {
                key: 'stop-impersonating-user',
                label: 'Stop Impersonating User',
                icon: <LogoutOutlined />,
                danger: true,
              },
            ]
          : [
              { key: 'change-password', label: 'Change Password', icon: <KeyOutlined /> },
              ...(userIsSystemAdmin
                ? [
                    {
                      key: 'impersonate-user',
                      label: 'Impersonate User',
                      icon: <UserSwitchOutlined />,
                    },
                  ]
                : []),
              { type: 'divider' },
              { key: 'logout', label: 'Logout', icon: <LogoutOutlined />, danger: true },
            ]),
      ]}
    />
  );

  return (
    <Layout.Header className={styles.navbar} style={{ height }}>
      <div className={styles.navbarContainer}>
        <div className={`${styles.navbarItem} ${styles.noHover}`}>
          {userIsLoaded && userIsInternalUser ? <UniversalSearch /> : null}
        </div>
        <Dropdown className={styles.navbarItem} overlay={menu} placement="bottomRight">
          {presumedUserName ? (
            <Space>
              <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                {presumedUserName.charAt(0)}
              </Avatar>
              {presumedUserName}
            </Space>
          ) : (
            <Skeleton
              className={styles.userAvatarSkeleton}
              active
              avatar={{ shape: 'circle' }}
              title={{ width: 50 }}
              paragraph={false}
            />
          )}
        </Dropdown>
      </div>
      <ChangePassword
        isVisible={isChangePasswordModalVisible}
        setVisibility={setChangePasswordModalVisibility}
      />
      <ImpersonateUser
        isVisible={isImpersonateUserModalVisible}
        setVisibility={setImpersonateUserModalVisibility}
      />
    </Layout.Header>
  );
};

export default Navbar;
