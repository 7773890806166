import { Alert, Button, Col, Form, Input, Row, Typography } from 'antd';
import React from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import logo from '../assets/logos/bt-logo-round-word-inline-blue-alpha.svg';
import api from '../services/api';
import { useAsync } from '../services/hooks';
import { useStore } from '../services/store';

const Login = (props) => {
  const { state, dispatch } = useStore();
  const location = useLocation();

  const { execute: signIn, status, error } = useAsync(api.signIn);

  const isLoggedIn = state.isAuthenticated();

  const onFinish = ({ password, email }) => {
    signIn({ password, email }).then((response) => {
      if (response?.token) {
        dispatch({ type: 'AUTHENTICATE', token: response.token });
      }
    });
  };

  if (isLoggedIn) {
    // Redirect to the home page if already logged-in (and attempted to access the login page directly)
    // Otherwise, once logged-in, redirect to whatever page the un-authenticated user was attempting to go to before being redirected to the login page
    return <Navigate to={location.state?.from?.pathname || '/'} replace />;
  } else {
    return (
      <>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ width: '100%', padding: '0 20px 80px' }}
        >
          <Col style={{ width: 490 }}>
            <img
              src={logo}
              alt="Blockthrough logo"
              style={{ display: 'block', margin: '0 auto', height: 160, maxWidth: '100%' }}
            />
            <Typography.Title
              level={1}
              style={{ textAlign: 'center', color: '#0083FF', marginTop: '-32px', fontWeight: 300 }}
            >
              Welcome
            </Typography.Title>
            <Form
              name="login"
              onFinish={onFinish}
              layout="vertical"
              style={{ minHeight: 230, maxWidth: 340, margin: '0 auto' }}
            >
              <Form.Item>
                <Typography.Text style={{ fontSize: '14px' }}>
                  Don't have an account yet? Please reach out to your Account Manager or
                  <a href="mailto:sales@blockthrough.com"> sales@blockthrough.com </a>
                  for assistance.
                </Typography.Text>
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: 'Please enter your email!' },
                  { type: 'email', message: 'Please input a valid email address!' },
                ]}
              >
                <Input autoComplete="username" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Please enter your password!' }]}
              >
                <Input.Password autoComplete="current-password" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={status === 'pending'}
                  style={{ width: '100%' }}
                >
                  Log in
                </Button>
              </Form.Item>
              <Form.Item style={{ textAlign: 'center' }}>
                <Link to="/forgot_password">Reset Password</Link>
              </Form.Item>

              {error && <Alert message={error.message} type="error" showIcon />}
            </Form>
          </Col>
        </Row>
      </>
    );
  }
};

export default Login;
