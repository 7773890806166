import React, { useCallback, useState } from 'react';
import { Alert, Button, Dropdown, Form, Menu, message, Modal, Select, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { mutate } from 'swr';
import api from '../../../services/api';
import { generateErrorMessage } from '../../../services/utils';

import {
  JOB_ACTIONS_MODAL_PROPS,
  JOB_RUN_STATUSES,
  JOB_STATUSES,
  JOB_SCHEDULES,
  LINEITEM_ACTIONS,
  LINEITEM_TYPES,
  LINEITEM_TYPES_OPTIONS,
} from './lineItemJobConstants';

export const getLineItemFilters = (actionsObj = {}, actionType) =>
  Object.keys(actionsObj).filter((action) => actionsObj[action] === actionType);

const EditGAMExclusionForm = ({ job, form, saveError }) => {
  const selectedExclusions = Form.useWatch('exclude_line_item_types', form) || [];
  const selectedRevertExclusions = Form.useWatch('revert_line_item_types', form) || [];
  const availableExclusions = LINEITEM_TYPES_OPTIONS.filter(
    (type) => !selectedRevertExclusions.includes(type.value)
  );
  const availableRevertExclusions = LINEITEM_TYPES_OPTIONS.filter(
    (type) => !selectedExclusions.includes(type.value)
  );

  return (
    <Form
      name="edit-gam-exclusion"
      preserve={false}
      requiredMark={false}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      form={form}
      initialValues={{
        exclude_line_item_types: getLineItemFilters(
          job.line_item_actions,
          LINEITEM_ACTIONS.LINEITEM_ACTION_EXCLUSION
        ),
        revert_line_item_types: getLineItemFilters(
          job.line_item_actions,
          LINEITEM_ACTIONS.LINEITEM_ACTION_REVERT_EXCLUSION
        ),
      }}
    >
      <Form.Item name="name" label="Name">
        <span>{job.name}</span>
      </Form.Item>
      <Form.Item name="network_code" label="Network Code">
        <span>{job.network_code}</span>
      </Form.Item>
      <Form.Item name="exclude_line_item_types" label="Exclude Line Item Type">
        <Select mode="tags" options={availableExclusions} />
      </Form.Item>
      {job.schedule !== JOB_SCHEDULES.JOB_SCHEDULE_HOURLY ? (
        <Form.Item name="revert_line_item_types" label="Revert Line Item Type">
          <Select mode="tags" options={availableRevertExclusions} />
        </Form.Item>
      ) : null}

      {saveError && <Alert message={saveError} type="error" showIcon />}
    </Form>
  );
};

const basicManualActions = [
  {
    label: 'Edit',
    key: 'edit',
  },
  {
    label: 'Dry Run',
    key: 'dry_run',
  },
  {
    label: 'Manual Run',
    key: 'manual_run',
  },
];

const getActionItems = (job, hasScheduledJob) => {
  let availableActions;
  if (job.schedule === JOB_SCHEDULES.JOB_SCHEDULE_HOURLY) {
    availableActions =
      job.job_status === JOB_STATUSES.JOB_STATUS_ENABLED
        ? [
            {
              label: 'Disable',
              key: 'disable',
            },
          ]
        : [
            {
              label: 'Enable',
              key: 'enable',
            },
            {
              label: 'Edit',
              key: 'edit',
            },
          ];
  } else if (!hasScheduledJob) {
    availableActions = [
      {
        label: 'Schedule',
        key: 'schedule',
      },
      ...basicManualActions,
    ];
  } else {
    availableActions = basicManualActions;
  }
  if (job.run_status !== JOB_RUN_STATUSES.JOB_RUN_STATUS_RUNNING) {
    availableActions = [
      ...availableActions,
      {
        label: 'Delete',
        key: 'delete',
        danger: true,
      },
    ];
  }
  return availableActions;
};

const JobActionsModal = ({ action, job, form, saveError }) => {
  switch (action) {
    case 'edit':
      return <EditGAMExclusionForm job={job} form={form} saveError={saveError} />;
    default:
      return <Space direction="vertical">{JOB_ACTIONS_MODAL_PROPS[action].modalText}</Space>;
  }
};

const JobActions = ({ job, jobs }) => {
  const hasScheduledJob = jobs.some((job) => job.schedule === JOB_SCHEDULES.JOB_SCHEDULE_HOURLY);
  const scheduledEnabledJob = jobs.find(
    (job) =>
      job.schedule === JOB_SCHEDULES.JOB_SCHEDULE_HOURLY &&
      job.job_status === JOB_STATUSES.JOB_STATUS_ENABLED
  );

  // Storing saveError in state since the value needs to be reset in useCallback
  const [saveError, setSaveError] = useState(null);
  const [isJobActionsModalOpen, setIsJobActionsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const openModal = useCallback(() => {
    form.setFieldsValue({
      exclude_line_item_types: getLineItemFilters(
        job.line_item_actions,
        LINEITEM_ACTIONS.LINEITEM_ACTION_EXCLUSION
      ),
      revert_line_item_types: getLineItemFilters(
        job.line_item_actions,
        LINEITEM_ACTIONS.LINEITEM_ACTION_REVERT_EXCLUSION
      ),
    });

    setIsJobActionsModalOpen(true);
  }, [form, job.line_item_actions, setIsJobActionsModalOpen]);

  const closeModal = useCallback(() => {
    setTimeout(() => {
      // Clear/reset the form fields
      form.resetFields();
      setSaveError(null);
    }, 100); // use timeout to avoid content jank as the form modal closes

    setIsJobActionsModalOpen(false);
  }, [form, setIsJobActionsModalOpen]);

  const [JobActionsModalContent, setJobActionsModalContent] = useState({});
  const [jobActionsModalProps, setJobActionsModalProps] = useState({
    onCancel: closeModal,
  });

  const items = getActionItems(job, hasScheduledJob);

  const onDropdownClick = (e) => {
    const action = e.key;

    if (action === 'enable' || action === 'disable') {
      return api.updateLineItem({ ...job, enable: action === 'enable' }).then(() => {
        mutate(['/LineItemJobList', job.org_id]);
        return message.success(
          `Line item job successfully ${action === 'enable' ? 'enabled' : 'disabled'}!`
        );
      });
    }

    const onJobActionOk = async () => {
      const { id, name, network_code, org_id, line_item_actions, schedule } = job;
      const triggerManualRun = (isDryRun, lineItemActions, successMessage) => {
        return api
          .triggerLineItemJob({
            id,
            line_item_actions: lineItemActions,
            dry_run: isDryRun,
          })
          .then(() => {
            mutate(['/LineItemJobList', job.org_id]);
            closeModal();
            return message.success(successMessage);
          })
          .catch((error) => setSaveError(generateErrorMessage(error)));
      };
      const disableScheduledJob = () => {
        return api.updateLineItem({ ...scheduledEnabledJob, enable: false }); // disable schedule before running manual job
      };
      switch (action) {
        case 'edit':
          const isScheduledJob = schedule === JOB_SCHEDULES.JOB_SCHEDULE_HOURLY;
          const {
            exclude_line_item_types,
            revert_line_item_types = [],
          } = await form.validateFields();
          const formattedLineItemTypes = LINEITEM_TYPES.reduce(
            (obj, type) => ({
              ...obj,
              [type]: revert_line_item_types.includes(type)
                ? LINEITEM_ACTIONS.LINEITEM_ACTION_REVERT_EXCLUSION
                : exclude_line_item_types.includes(type)
                ? LINEITEM_ACTIONS.LINEITEM_ACTION_EXCLUSION
                : LINEITEM_ACTIONS.LINEITEM_ACTION_IGNORE,
            }),
            {}
          );
          const updateJobAndTriggerRun = () => {
            return api
              .updateLineItem({
                id,
                name,
                network_code,
                enable: true,
                line_item_actions: formattedLineItemTypes,
              })
              .then(() =>
                triggerManualRun(
                  false,
                  formattedLineItemTypes,
                  'Manual exclusion line item successfully updated!'
                )
              )
              .catch((error) => setSaveError(generateErrorMessage(error)));
          };
          if (scheduledEnabledJob && !isScheduledJob) {
            return disableScheduledJob().then(() => updateJobAndTriggerRun());
          } else if (isScheduledJob) {
            // if this job is a scheduled job we don't need to trigger a manual run
            return api
              .updateLineItem({
                id,
                name,
                network_code,
                line_item_actions: formattedLineItemTypes,
              })
              .then(() => {
                mutate(['/LineItemJobList', job.org_id]);
                closeModal();
                return message.success('Scheduled exclusion line item successfully updated!');
              })
              .catch((error) => setSaveError(generateErrorMessage(error)));
          } else {
            return updateJobAndTriggerRun();
          }
        case 'dry_run':
        case 'manual_run':
          const isDryRun = action === 'dry_run';
          const successMessage = `${isDryRun ? 'Dry' : 'Manual'} run successfully triggered!`;
          if (scheduledEnabledJob) {
            return disableScheduledJob().then(() =>
              triggerManualRun(isDryRun, line_item_actions, successMessage)
            );
          } else {
            return triggerManualRun(isDryRun, line_item_actions, successMessage);
          }
        case 'schedule':
          // when creating a scheduled job based on a manual job, we need to remove any revert line action types as the api does not accept this
          const newLineItemActions = { ...line_item_actions };
          for (const lineItemType in newLineItemActions) {
            newLineItemActions[lineItemType] =
              newLineItemActions[lineItemType] === LINEITEM_ACTIONS.LINEITEM_ACTION_REVERT_EXCLUSION
                ? LINEITEM_ACTIONS.LINEITEM_ACTION_IGNORE
                : newLineItemActions[lineItemType];
          }
          return api
            .createLineItem({
              name,
              network_code,
              org_id,
              line_item_actions: newLineItemActions,
              schedule: JOB_SCHEDULES.JOB_SCHEDULE_HOURLY,
            })
            .then(() => {
              mutate(['/LineItemJobList', job.org_id]);
              closeModal();
              return message.success('Line item job successfully scheduled!');
            })
            .catch((error) => setSaveError(generateErrorMessage(error)));
        case 'delete':
          return api
            .deleteLineItem(job.id)
            .then(() => {
              mutate(['/LineItemJobList', job.org_id]);
              closeModal();
              return message.success('Line item job successfully deleted!');
            })
            .catch((error) => setSaveError(generateErrorMessage(error)));
        default:
          return closeModal();
      }
    };

    setJobActionsModalContent({ action });
    setJobActionsModalProps((prevJobActionsModalProps) => ({
      ...prevJobActionsModalProps,
      title: JOB_ACTIONS_MODAL_PROPS[action].title,
      okText: JOB_ACTIONS_MODAL_PROPS[action].okText,
      onOk: onJobActionOk,
      okButtonProps: { danger: action === 'delete' },
    }));

    openModal();
  };
  return (
    <>
      <Dropdown
        disabled={
          job.run_status === JOB_RUN_STATUSES.JOB_RUN_STATUS_PENDING_CREATE ||
          jobs.some(
            (job) =>
              job.run_status === JOB_RUN_STATUSES.JOB_RUN_STATUS_RUNNING &&
              job.schedule !== JOB_SCHEDULES.JOB_SCHEDULE_HOURLY
          )
        }
        overlay={<Menu items={items} onClick={onDropdownClick} />}
        style={{ width: '100px' }}
      >
        <Button>
          <Space>
            Actions
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      <Modal visible={isJobActionsModalOpen} {...jobActionsModalProps}>
        <JobActionsModal {...JobActionsModalContent} job={job} form={form} saveError={saveError} />
      </Modal>
    </>
  );
};

export default JobActions;
