import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Collapse, Dropdown, Form } from 'antd';
import React from 'react';
import { FormItemList } from '../../../components';
import { WhenDevice, WhenStringProp } from './formComponents';

const BypassScrapingSlot = ({ formInstance, field, disabled, parentFieldNamePath }) => {
  const fieldValue = formInstance.getFieldValue([...parentFieldNamePath, field.name]);

  if (!fieldValue) {
    // Don't render anything until we've determined if we're bypassing scraping by 'div ID' or by 'slot path'
    return null;
  }

  // Render different form items depending on if we're bypassing scraping by 'div ID' or by 'slot path'
  // (Either `whenDivId` or `whenSlotPath` will be set in the initial value when added in <FormItemList>.)
  const isBypassByDivId = !!fieldValue.whenDivId;
  const isBypassBySlotPath = !!fieldValue.whenSlotPath;

  const whenStringPropProps = {
    field,
    disabled,
    formInstance,
    parentFieldNamePath,
  };
  if (isBypassByDivId) {
    whenStringPropProps.propKey = 'divId';
    whenStringPropProps.propDisplayName = 'div ID';
    whenStringPropProps.conditionPropKey = 'whenDivId';
  } else if (isBypassBySlotPath) {
    whenStringPropProps.propKey = 'slotPath';
    whenStringPropProps.propDisplayName = 'Slot Path';
    whenStringPropProps.conditionPropKey = 'whenSlotPath';
    whenStringPropProps.tagsSelectorInputWidth = '100%';
  }

  return (
    <>
      <WhenStringProp {...whenStringPropProps} />
      <WhenDevice field={field} />
    </>
  );
};

const ScrapeGoogletagSlotsForm = ({ formInstance, isViewOnly, saveError }) => {
  return (
    <Form
      name="settings-scrape-googletag-slots"
      form={formInstance}
      disabled={isViewOnly}
      preserve={false}
      requiredMark={false}
    >
      <Collapse defaultActiveKey={['filterOutSlots']}>
        <Collapse.Panel key="filterOutSlots" header="Bypass Scraping for Specific googletag Slots">
          <FormItemList
            name={['value', 'filterOut', 'slots']}
            disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
            renderAddItem={({ add }) => (
              <Dropdown
                menu={{
                  items: [
                    {
                      label: 'By googletag slot div ID',
                      key: 'by-div-id',
                    },
                    {
                      label: 'By googletag slot path',
                      key: 'by-slot-path',
                    },
                  ],
                  onClick: ({ key }) => {
                    if (key === 'by-div-id') {
                      add({ whenDivId: 'equalsOneOf', whenDevice: 'any' });
                    } else if (key === 'by-slot-path') {
                      add({ whenSlotPath: 'equalsOneOf', whenDevice: 'any' });
                    }
                  },
                }}
              >
                <Button type="dashed" block icon={<PlusOutlined />}>
                  Add slots to bypass scraping
                </Button>
              </Dropdown>
            )}
            renderFormItem={({ field, formItemListName, disabled }) => (
              <BypassScrapingSlot
                formInstance={formInstance}
                field={field}
                disabled={disabled}
                parentFieldNamePath={formItemListName}
              />
            )}
          />
        </Collapse.Panel>
      </Collapse>
      {saveError && <Alert message={saveError.message} type="error" showIcon />}
    </Form>
  );
};

const settingConfig = {
  name: 'Scrape googletag Slots',
  description:
    'Whether or not to scrape slots defined in the GPT `googletag` variable on the page to create BT Spaces.',
  Form: ScrapeGoogletagSlotsForm,
  initialSettingValue: {},
};

export default settingConfig;
