import { AutoComplete, Form } from 'antd';

const FontFormItem = ({ env }) => {
  const safeWebFonts = [
    { value: 'Helvetica' },
    { value: 'Arial' },
    { value: 'Arial Black' },
    { value: 'Verdana' },
    { value: 'Tahoma' },
    { value: 'Trebuchet MS' },
    { value: 'Impact' },
    { value: 'Gill Sans' },
    { value: 'Times New Roman' },
    { value: 'Georgia' },
    { value: 'Palatino' },
    { value: 'Baskerville' },
    { value: 'Andalé Mono' },
    { value: 'Courier' },
    { value: 'Lucida' },
    { value: 'Monaco' },
    { value: 'Bradley Hand' },
    { value: 'Brush Script MT' },
    { value: 'Luminari fantasy' },
    { value: 'Comic Sans MS' },
  ];

  return (
    <Form.Item
      label="Font Type"
      name={[env, 'am_settings', 'font_type']}
      tooltip={
        'Type in any value or select from the autocomplete list. The font must be loaded by the publisher.'
      }
    >
      <AutoComplete options={safeWebFonts} />
    </Form.Item>
  );
};

export default FontFormItem;
