import { PlusOutlined } from '@ant-design/icons';
import { Alert, Checkbox, Form, Input, message, Modal, Space } from 'antd';
import moment from 'moment';
import React from 'react';
import useSWR, { mutate } from 'swr';
import { OnceLoaded, SearchableEntityTableStatic } from '../../components';
import api from '../../services/api';
import { TIME_ZONE } from '../../services/constants';
import { SaveEntityModal } from '../../services/handlers';
import {
  compareAlphabetically,
  compareChronologically,
  parseValidWebsiteDomains,
  validateWebsiteDomainsField,
} from '../../services/utils';

function formatWhitelistRuleType(ruleType) {
  switch (ruleType) {
    case 'generic':
      return 'Generic Whitelist Rules';
    case 'css_element':
      return 'CSS Selector Rule';
    case 'loader_script':
      return 'Loader Script Rule';
    default:
      return '';
  }
}
export function formatWhitelistRuleErrorMsg(error = {}) {
  const ruleTypeStr = formatWhitelistRuleType(error.meta?.rule_type);
  if (error.meta?.rule) {
    return `${ruleTypeStr} "${error.meta?.rule}" - ${error.msg}`;
  } else {
    return `${ruleTypeStr} - ${error.msg}`;
  }
}

// Returns a list of non-empty whitelist rules in the newline-delimited `whitelistRulesString` provided
function parseWhitelistRules(whitelistRulesString = '') {
  return whitelistRulesString
    .split('\n')
    .map((rule) => rule.trim())
    .filter((rule) => rule); // ignore any blank lines
}

/**
 * Whitelist Rules-related components
 */
const AddWhitelistRulesForm = ({
  org,
  orgWebsites,
  addIsForBulkDomains,
  formInstance,
  saveError,
}) => {
  return (
    <Form
      name="whitelist-rules"
      form={formInstance}
      preserve={false}
      requiredMark={false}
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 22 }}
    >
      {addIsForBulkDomains ? (
        <>
          <Form.Item label="Organization">
            <span>
              {org.name} ({org.id})
            </span>
          </Form.Item>
          <Form.Item
            name="domains"
            label="Domains"
            tooltip={`Each domain entered must correspond to the domain (case-sensitive) of a website found in the "${org.name}" organization.`}
            validateTrigger="onBlur"
            rules={[
              {
                validator: (rule, value) => validateWebsiteDomainsField(value, { orgWebsites }), // Only accepts valid domains that correspond to websites which exist in the org
              },
            ]}
          >
            <Input.TextArea
              autoSize={{ minRows: 8, maxRows: 8 }}
              placeholder="Enter each domain on a new line - e.g. &#13;domain1.com &#13;domain2.com"
            />
          </Form.Item>
        </>
      ) : null}
      <Form.Item name="generic" label="Generic" valuePropName="checked">
        <Checkbox>Request generic whitelist rules</Checkbox>
      </Form.Item>
      <Form.Item name="cssSelectors" label="CSS Selectors">
        <Input.TextArea
          autoSize={{ minRows: 8, maxRows: 8 }}
          placeholder="Enter each selector rule on a new line - e.g.&#13;#@#.ad-active &#13;#@#.ad-container"
        />
      </Form.Item>
      <Form.Item name="loaderScripts" label="Loader Scripts">
        <Input.TextArea
          autoSize={{ minRows: 8, maxRows: 8 }}
          placeholder="Enter each loader script rule on a new line - e.g.&#13;@@||example.com/ads.js &#13;@@||example.com/main.js"
        />
      </Form.Item>
      <Form.Item
        name="forced"
        label="Force"
        valuePropName="checked"
        tooltip="Allows the submission of whitelist rules which have previously been requested to Eyeo (and which are otherwise blocked from being requested again for the same domain)"
      >
        <Checkbox>Force whitelist rules request</Checkbox>
      </Form.Item>

      {saveError && <Alert message={saveError.message} type="error" showIcon />}
    </Form>
  );
};

export const AddWhitelistRulesModal = ({
  buttonText = 'Add',
  buttonProps = { icon: <PlusOutlined /> },
  triggerRender,
  org,
  website, // optional - the org's website whose domain we can request whitelist rules for (if not provided, we display a Domains field which can be filled with domains of the org's websites that the whitelist rules get requested for)
  orgWebsites, // required if `website` is not provided - the org's websites which are used to ensure only domains which correspond to websites found in the org are provided in the Domains field
  selectedWebsites, // optional - pre-populates Domains field with domain names of selected websites in the org to request whitelist rules for
  clearSelection, // optional - clears selection after requesting whitelist rules for selected websites in the org
}) => {
  const addIsForBulkDomains = !website;

  const transformBeforeSave = (values) => {
    const transformedValues = { publisher: org.name, forced: !!values.forced };

    let domains;
    if (addIsForBulkDomains) {
      // Modal/form is bound to a specific org (i.e. is opened within the Org Details page) - therefore we expect the Domains field to have existed in the form (and `values.domains` to be populated)
      const { validDomains } = parseValidWebsiteDomains(values.domains, { orgWebsites }); // // `validDomains` will be guaranteed to be length > 1 if the form's Domains field was validated with `validateWebsiteDomainsField`

      domains = validDomains;
    } else {
      // Modal/form is bound to a specific website (i.e. is opened within the Website Details page)
      domains = [website.domain];
    }

    if (values.generic) {
      transformedValues.generic = { domains };
    }

    const cssSelectorWhitelistRules = parseWhitelistRules(values.cssSelectors);
    if (cssSelectorWhitelistRules.length > 0) {
      transformedValues.css_elements = [
        {
          rules: cssSelectorWhitelistRules,
          domains,
        },
      ];
    }
    const loaderScriptWhitelistRules = parseWhitelistRules(values.loaderScripts);
    if (loaderScriptWhitelistRules.length > 0) {
      transformedValues.loader_scripts = [
        {
          rules: loaderScriptWhitelistRules,
          domains,
        },
      ];
    }

    return transformedValues;
  };

  const domainsFieldInitialValue = selectedWebsites
    ? selectedWebsites
        .map((selectedWebsite) => selectedWebsite?.domain || '')
        .sort(compareAlphabetically)
        .join('\n')
    : '';

  return (
    <SaveEntityModal
      buttonText={buttonText}
      buttonProps={buttonProps}
      triggerRender={triggerRender}
      modalTitle={addIsForBulkDomains ? 'Add Whitelist Rules (Bulk)' : 'Add Whitelist Rules'}
      validateFormOnSubmit={(values) => {
        // If there are no whitelist rules to request, do not proceed with making the call to `api.createWhitelistRules`,
        // and throw an Error (with a message that will be shown to the user at the bottom of the form).
        const genericRulesRequested = !!values.generic;
        const cssSelectorWhitelistRules = parseWhitelistRules(values.cssSelectors);
        const loaderScriptWhitelistRules = parseWhitelistRules(values.loaderScripts);
        if (
          !genericRulesRequested &&
          cssSelectorWhitelistRules.length === 0 &&
          loaderScriptWhitelistRules.length === 0
        ) {
          throw new Error('No whitelist rules to request have been provided!');
        }
      }}
      transformBeforeSave={transformBeforeSave}
      saveEntity={api.createWhitelistRules}
      onSuccess={({ errors, domains }) => {
        if (errors?.length > 0) {
          const domainsWithFailedRuleRequests = [
            ...new Set(errors.map((error) => error?.meta?.domain).filter((domain) => !!domain)),
          ].sort(compareAlphabetically); // list of (sorted) unique domains with failed whitelist rule requests

          Modal.error({
            title: 'Errors occurred while requesting whitelist rules',
            content: (
              <Space direction="vertical" size={8} style={{ marginTop: 8, width: '100%' }}>
                {errors.map((error, index) => (
                  <Alert
                    key={index}
                    message={error?.meta?.domain}
                    description={formatWhitelistRuleErrorMsg(error)}
                    type="error"
                  />
                ))}
                {errors.length > 0
                  ? `As a result, no whitelist rules could be requested for${
                      domainsWithFailedRuleRequests.length < 10
                        ? `: ${domainsWithFailedRuleRequests.join(', ')}`
                        : ` these ${domainsWithFailedRuleRequests.length} affected websites`
                    }.`
                  : ''}
                {domains.length > 0
                  ? `Nevertheless, the whitelist rules for the other ${domains.length} website${
                      domains.length > 1 ? 's' : ''
                    } were successfully requested.`
                  : null}
              </Space>
            ),
            width: 500,
          });
        } else {
          message.success(
            `Whitelist rules for ${
              addIsForBulkDomains ? org.name : website.domain
            } successfully requested!`
          );
        }

        if (clearSelection) {
          // If whitelist rules were being requested for selected websites, clear the selection
          clearSelection();
        }

        if (!addIsForBulkDomains) {
          // Trigger whitelist rules refresh to reflect update in WhitelistRulesTable for website
          mutate(['/WhitelistRulesRecordList', website.domain]);
        }
      }}
      formComponent={AddWhitelistRulesForm}
      formComponentProps={{ org, orgWebsites, addIsForBulkDomains }}
      formInitialValues={{ domains: domainsFieldInitialValue }}
    />
  );
};

const WhitelistRulesTable = ({ org, website, isViewOnly }) => {
  const { data: whitelistRules, error: whitelistRulesError } = useSWR(
    ['/WhitelistRulesRecordList', website.domain],
    () => api.listWhitelistRules({ domain: website.domain })
  );

  return (
    <OnceLoaded
      error={whitelistRulesError}
      isLoading={!whitelistRules}
      render={() => (
        <SearchableEntityTableStatic
          title="Whitelist Rules"
          staticTableData={whitelistRules}
          textSearchFieldNames={['rule', 'rule_type']}
          rowKey="id"
          actions={
            isViewOnly ? null : (
              <AddWhitelistRulesModal key="add-whitelist-rules" org={org} website={website} />
            )
          }
          columns={[
            {
              title: 'Filter',
              dataIndex: 'rule',
              width: 260,
            },
            {
              title: `Type`,
              dataIndex: 'rule_type',
              width: 110,
            },
            {
              title: `Ticket ID`,
              dataIndex: 'ticket_id',
              width: 150,
            },
            {
              // TODO: Remove repetition of this (and other columns)
              title: `Created (${TIME_ZONE})`,
              dataIndex: 'requested_at',
              defaultSortOrder: 'ascend',
              sortDirections: ['ascend', 'descend', 'ascend'],
              sorter: (a, b) => compareChronologically(a.requested_at, b.requested_at),
              render: (dateISOString) => {
                const date = moment(dateISOString);
                return (
                  <Space>
                    <span>{date.format('YYYY-MM-DD')}</span>
                    <span>{date.format('(HH:mm)')}</span>
                  </Space>
                );
              },
              width: 240,
            },
          ]}
        />
      )}
    />
  );
};

export default WhitelistRulesTable;
