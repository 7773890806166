import { Alert, Form } from 'antd';
import React from 'react';
import { CodeEditor } from '../../../components';
import { validateJson } from '../../../services/utils';

const demandConfigPlaceholder = `{
    "trustedstack": {
      "asi": "blockthrough.com",
      "sid": "5741666032943100",
      "params": { "cid": "customer_id", "crid": "placement id" }
    },
    "snigel": {
      "asi": "blockthrough.com",
      "sid": "5741666032943100",
      "params": { "accountId": "1870", "site": "site.com", "placement": "top_leaderboard" }
    }
  }`;

const AddBTXDemandForm = ({ formInstance, isViewOnly, saveError }) => (
  <Form
    name="settings-add-bt-demand"
    form={formInstance}
    disabled={isViewOnly}
    preserve={false}
    requiredMark={false}
  >
    <Form.Item
      name={['value', 'demandConfig']}
      label="BTX Demand Config"
      tooltip="BTX Demand Config JSON object - If specifying a bidder's params by placement, provide them in an array where each entry includes the corresponding Space name."
      labelCol={{ span: 24 }}
      rules={[
        {
          required: true,
          validator: (rule, value) => validateJson(value),
          message: 'Please enter a valid BT Demand Config JSON object!',
        },
      ]}
    >
      <CodeEditor
        placeholder={demandConfigPlaceholder}
        height={360}
        disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
      />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const settingConfig = {
  name: 'Enable BTX Demand',
  description: 'Enables and configures BTX Demand bidders.',
  Form: AddBTXDemandForm,
  // Transform bt demand config to and from JS Object <-> JSON string because form must use string object but we want to store the value as an Object
  transformBeforeInit: ({ demandConfig, ...rest }) => ({
    demandConfig: JSON.stringify(demandConfig, null, 2),
    ...rest,
  }),
  transformBeforeSave: ({ demandConfig, ...rest }) => ({
    demandConfig: JSON.parse(demandConfig),
    ...rest,
  }),
  initialSettingValue: {},
};

export default settingConfig;
