import { Alert, Form, Input } from 'antd';
import React from 'react';
import { CodeEditor } from '../..';
import { validateJson } from '../../../services/utils';

const btServerConfigPlaceholder = `{
  "pubmatic": {
    "publisherId": "935800"
  },
  "ix": {
    "siteId": "776528"
  }
}`;

const EnableBTServerForm = ({ formInstance, isViewOnly, saveError }) => (
  <Form
    name="settings-enable-bt-server"
    form={formInstance}
    disabled={isViewOnly}
    preserve={false}
    requiredMark={false}
  >
    <Form.Item
      name={['value', 'siteId']}
      label="Seller ID (sid)"
      tooltip="If left empty, the Organization ID will be used by default. Otherwise, this can be set to override that default value if the `seller_id` in `blockthrough.com/sellers.json` differs from the Organization ID."
      rules={[
        {
          whitespace: true,
          message: 'Please enter a valid Seller ID!',
        },
      ]}
    >
      <Input placeholder="5741666032943100" />
    </Form.Item>
    <Form.Item
      name={['value', 'btServerConfig']}
      label="BT Server Config"
      tooltip="A valid JSON object which consists of bidders and their parameters"
      labelCol={{ span: 24 }}
      rules={[
        {
          required: true,
          validator: (rule, value) => validateJson(value),
          message: 'Please enter a valid BT Server Config JSON object!',
        },
      ]}
    >
      <CodeEditor
        placeholder={btServerConfigPlaceholder}
        height={360}
        disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
      />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const settingConfig = {
  name: 'Enable BT Server (BT)',
  description: 'Enables and configures BT Demand via the BT adapter in our auctions.',
  Form: EnableBTServerForm,
  // Transform BT server config to and from JS Object <-> JSON string because form must use string object but we want to store the value as an Object
  transformBeforeInit: ({ btServerConfig, ...rest }) => ({
    btServerConfig: JSON.stringify(btServerConfig, null, 2),
    ...rest,
  }),
  transformBeforeSave: ({ btServerConfig, ...rest }) => ({
    btServerConfig: JSON.parse(btServerConfig),
    ...rest,
  }),
  initialSettingValue: {},
};

export default settingConfig;
