import { Alert, Button, Checkbox, Form, Input, message, Tooltip, Upload } from 'antd';
import { parse } from 'papaparse';
import React from 'react';
import { CodeEditor } from '../../../../components';
import { generateErrorMessage, validateJson } from '../../../../services/utils';
import generateAmazonCPMChart from './generateAmazonCPMChart';

const EnableAmazonForm = ({ formInstance, isViewOnly, saveError }) => (
  <Form
    name="settings-enable-amazon"
    form={formInstance}
    disabled={isViewOnly}
    preserve={false}
    requiredMark={false}
  >
    <Tooltip
      title="By default we delete the bidder's `hb_pb` KV from the ad server targeting list because GAM has unique line items for Amazon demand - when this is checked we will treat the Amazon bid as a regular Prebid bid."
      placement="leftTop"
    >
      <Form.Item
        name={['value', 'keepHbPb']}
        valuePropName="checked"
        style={{ marginBottom: '8px' }}
      >
        <Checkbox>Treat Amazon as a regular Prebid bid</Checkbox>
      </Form.Item>
    </Tooltip>
    <div style={{ position: 'relative', zIndex: 1 }}>
      <div style={{ position: 'absolute', right: 0 }}>
        <Upload
          accept=".csv"
          showUploadList={false}
          beforeUpload={(csvFile) => {
            parse(csvFile, {
              skipEmptyLines: true,
              transform: (cellValue) => cellValue.trim().toLowerCase(), // assumes all cell values are parsed as strings!
              error: () => {
                message.error('Error trying to parse the CSV file');
              },
              complete: (results) => {
                try {
                  if (results.errors.length > 0) {
                    console.error(results.errors);
                    throw new Error('@ papaparse - check console logs');
                  } else {
                    const amazonCPMChartObject = generateAmazonCPMChart(results.data);
                    if (!amazonCPMChartObject) {
                      throw new Error('@ generateAmazonCPMChart');
                    }

                    const amazonCPMChart = JSON.stringify(amazonCPMChartObject, null, 2); // form field value expects a stringified JSON object
                    formInstance.setFieldsValue({
                      value: {
                        amazonConfig: { amazonCPMChart },
                      },
                    });

                    message.success(`CPM Chart successfully generated from the CSV file`);
                  }
                } catch (error) {
                  message.error(
                    `Error trying to parse the CSV file. ${generateErrorMessage(error)}`
                  );
                }
              },
            });

            // Prevent upload (we don't need to upload the file to a server, we just want to parse it)
            return false;
          }}
        >
          <Tooltip
            title={
              <>
                <div>Parses a line item order CSV file to populate the CPM chart JSON object.</div>
                <div>
                  CAUTION: This will overwrite whatever is currently entered in the "CPM Chart"
                  field!
                </div>
              </>
            }
            overlayStyle={{ maxWidth: 300 }}
          >
            <Button>Populate CPM Chart</Button>
          </Tooltip>
        </Upload>
      </div>
    </div>
    <Form.Item
      name={['value', 'amazonConfig', 'amazonCPMChart']}
      label="CPM Chart"
      labelCol={{ span: 24 }}
      rules={[
        {
          required: true,
          validator: (rule, value) => validateJson(value),
          message: 'Please enter a valid CPM chart JSON object!',
        },
      ]}
    >
      <CodeEditor
        placeholder="CPM Chart JSON Object"
        height={360}
        disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
      />
    </Form.Item>
    <Form.Item
      name={['value', 'amazonConfig', 'amazonCPMCurrency']}
      label="CPM Currency"
      tooltip="If left empty, the default `USD` currency will be used."
      rules={[
        {
          // TODO: implement some sort of currency string validation
          whitespace: true,
        },
      ]}
    >
      <Input placeholder="USD" />
    </Form.Item>
    <Form.Item
      name={['value', 'pubID']}
      label="Publisher ID"
      rules={[
        {
          required: true,
          whitespace: true,
          message: 'Please enter a Publisher ID!',
        },
      ]}
    >
      <Input placeholder="Publisher ID" />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const settingConfig = {
  name: 'Enable Amazon',
  description: 'Enables and configures the Amazon bidder for our auctions.',
  Form: EnableAmazonForm,
  // Transform amazonCPMChart to and from JS Object <-> JSON string because form must use string object but we want to store the value as an Object
  transformBeforeInit: ({ amazonConfig, ...rest }) => ({
    amazonConfig: {
      amazonCPMChart: JSON.stringify(amazonConfig?.amazonCPMChart, null, 2),
      amazonCPMCurrency: amazonConfig?.amazonCPMCurrency,
    },
    ...rest,
  }),
  transformBeforeSave: ({ amazonConfig, ...rest }) => ({
    amazonConfig: {
      amazonCPMChart: JSON.parse(amazonConfig?.amazonCPMChart),
      amazonCPMCurrency: amazonConfig?.amazonCPMCurrency?.toUpperCase(), // Ensure currency entered by user is saved in uppercase
    },
    ...rest,
  }),
  initialSettingValue: {},
};

export default settingConfig;
