import { rest } from 'msw';
import { metrics, orgs } from './mock_api';

import { PRIVATE_SERVICE_PATH } from '../services/api';
const baseUrl = process.env.REACT_APP_DASH_API_URL;
const apiPathAdmin = `${baseUrl}${PRIVATE_SERVICE_PATH}`;

const handlers = [
  rest.post(`${apiPathAdmin}/Session`, async (req, res, ctx) => {
    return res(
      ctx.json({
        user: {
          id: '5693898546479104',
          creation_date: '2020-10-19T19:40:11.940424Z',
          email: 'test@blockthrough.com',
          admin: true,
          verified: true,
          org_ids: [],
          org_access: [],
          permissions: [],
        },
      })
    );
  }),

  rest.post(`${apiPathAdmin}/OrgList`, async (req, res, ctx) => {
    return res(ctx.json(orgs.list()));
  }),
  rest.post(`${apiPathAdmin}/OrgCreate`, async (req, res, ctx) => {
    try {
      const org = orgs.create(req.body.params);
      return res(ctx.json({ org }));
    } catch (error) {
      return res(ctx.status(400), ctx.json({ msg: error.message }));
    }
  }),

  rest.post(`${apiPathAdmin}/PageViewMetrics`, async (req, res, ctx) => {
    return res(ctx.json(metrics.pageViews.list(req.body)));
  }),
  rest.post(`${apiPathAdmin}/AAPageViewMetrics`, async (req, res, ctx) => {
    return res(ctx.json(metrics.aaPageViews.list(req.body)));
  }),
  rest.post(`${apiPathAdmin}/AuctionMetrics`, async (req, res, ctx) => {
    return res(ctx.json(metrics.auctions.list(req.body)));
  }),
  rest.post(`${apiPathAdmin}/ImpressionMetrics`, async (req, res, ctx) => {
    return res(ctx.json(metrics.impressions.list(req.body)));
  }),
  rest.post(`${apiPathAdmin}/GrossRevenueMetrics`, async (req, res, ctx) => {
    return res(ctx.json(metrics.grossRevenue.list(req.body)));
  }),
  rest.post(`${apiPathAdmin}/CPMMetrics`, async (req, res, ctx) => {
    return res(ctx.json(metrics.cpm.list(req.body)));
  }),
  rest.post(`${apiPathAdmin}/TopSites`, async (req, res, ctx) => {
    return res(ctx.json(metrics.topSites.list(req.body)));
  }),
];

export { apiPathAdmin, handlers, rest };
