import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Collapse, Dropdown, Form, Input, Radio, Space } from 'antd';
import React from 'react';
import { DisabledContent, FormItemList, HelpTooltip, TagsSelector } from '../../../components';
import { WhenDevice, WhenStringProp } from './formComponents';

const BiddersToRemove = ({ field, disabled }) => (
  <Form.Item
    {...field}
    key={`${field.key}-bidders`}
    name={[field.name, 'bidders']}
    label="Remove"
    colon={false}
    rules={[
      {
        required: true,
        message: 'Please input at least 1 bidder!',
      },
    ]}
  >
    <TagsSelector addPrompt="Bidder" disabled={disabled} />
  </Form.Item>
);

const BypassScrapingAdUnit = ({ formInstance, field, disabled, parentFieldNamePath }) => {
  const fieldValue = formInstance.getFieldValue([...parentFieldNamePath, field.name]);

  if (!fieldValue) {
    // Don't render anything until we've determined if we're bypassing scraping by 'code' or by 'slot path'
    return null;
  }

  // Render different form items depending on if we're bypassing scraping by 'code' or by 'slot path'
  // (Either `whenCode` or `whenSlotPath` will be set in the initial value when added in <FormItemList>.)
  const isBypassByCode = !!fieldValue.whenCode;
  const isBypassBySlotPath = !!fieldValue.whenSlotPath;

  const whenStringPropProps = {
    field,
    disabled,
    formInstance,
    parentFieldNamePath,
  };
  if (isBypassByCode) {
    whenStringPropProps.propKey = 'code';
    whenStringPropProps.propDisplayName = 'Code';
    whenStringPropProps.conditionPropKey = 'whenCode';
  } else if (isBypassBySlotPath) {
    whenStringPropProps.propKey = 'slotPath';
    whenStringPropProps.propDisplayName = 'Slot Path';
    whenStringPropProps.conditionPropKey = 'whenSlotPath';
    whenStringPropProps.tagsSelectorInputWidth = '100%';
  }

  return (
    <>
      <WhenStringProp {...whenStringPropProps} />
      <WhenDevice field={field} />
    </>
  );
};

const ScrapePrebidForm = ({ formInstance, isViewOnly, saveError }) => {
  // Track `value.prebidWrapper` form value - when it's selected, grey-out "Prebid Ad Units Variable" field
  const isPrebidWrapperEnabled = Form.useWatch(['value', 'prebidWrapper'], formInstance);

  return (
    <Form
      name="settings-scrape-prebid"
      form={formInstance}
      disabled={isViewOnly}
      preserve={false}
      requiredMark={false}
    >
      <Space direction="vertical" size={24} style={{ width: '100%' }}>
        <Collapse defaultActiveKey={['adUnitSource']}>
          <Collapse.Panel key="adUnitSource" header="Ad Unit Source">
            <Form.Item name={['value', 'prebidWrapper']} noStyle>
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={undefined}>
                    <DisabledContent isDisabled={isPrebidWrapperEnabled}>
                      <Space>
                        <span>
                          Prebid Ad Units Variable
                          <HelpTooltip title="Prebid Ad Units Variable defaults to `pbjs.adUnits` if a custom variable is not specified here." />
                        </span>
                        <Form.Item name={['value', 'adUnitsVar']}>
                          <Input placeholder="pbjs.adUnits" />
                        </Form.Item>
                      </Space>
                    </DisabledContent>
                  </Radio>

                  <Radio value="rubicon">
                    Rubicon Wrapper
                    <HelpTooltip
                      title={`Scrapes Rubicon Project's "Demand Manager" Prebid wrapper. Overrides Prebid Ad Units Variable.`}
                    />
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Collapse.Panel>
        </Collapse>

        <Collapse defaultActiveKey={['forAllAdUnits', 'forAdUnits']}>
          <Collapse.Panel key="forAllAdUnits" header="Remove Bidders for All Prebid Ad Units">
            <FormItemList
              name={['value', 'parse', 'removeBidders', 'forAllAdUnits']}
              disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
              addPrompt="Add bidders to remove"
              addInitialValue={{ whenDevice: 'any' }}
              renderFormItem={({ field, disabled }) => (
                <>
                  <WhenDevice field={field} />
                  <BiddersToRemove field={field} disabled={disabled} />
                </>
              )}
            />
          </Collapse.Panel>
          <Collapse.Panel key="forAdUnits" header="Remove Bidders for Specific Prebid Ad Units">
            <FormItemList
              name={['value', 'parse', 'removeBidders', 'forAdUnits']}
              disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
              addPrompt="Add bidders to remove"
              addInitialValue={{ whenCode: 'equalsOneOf', whenDevice: 'any' }}
              renderFormItem={({ field, formItemListName, disabled }) => (
                <>
                  <WhenStringProp
                    propKey="code"
                    propDisplayName="Code"
                    conditionPropKey="whenCode"
                    field={field}
                    disabled={disabled}
                    formInstance={formInstance}
                    parentFieldNamePath={formItemListName}
                  />
                  <WhenDevice field={field} />
                  <BiddersToRemove field={field} disabled={disabled} />
                </>
              )}
            />
          </Collapse.Panel>
        </Collapse>

        <Collapse defaultActiveKey={['filterOutAdUnits']}>
          <Collapse.Panel
            key="filterOutAdUnits"
            header="Bypass Scraping for Specific Prebid Ad Units"
          >
            <FormItemList
              name={['value', 'filterOut', 'adUnits']}
              disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
              renderAddItem={({ add }) => (
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: 'By Prebid ad unit code',
                        key: 'by-code',
                      },
                      {
                        label: 'By googletag slot path',
                        key: 'by-slot-path',
                      },
                    ],
                    onClick: ({ key }) => {
                      if (key === 'by-code') {
                        add({ whenCode: 'equalsOneOf', whenDevice: 'any' });
                      } else if (key === 'by-slot-path') {
                        add({ whenSlotPath: 'equalsOneOf', whenDevice: 'any' });
                      }
                    },
                  }}
                >
                  <Button type="dashed" block icon={<PlusOutlined />}>
                    Add ad units to bypass scraping
                  </Button>
                </Dropdown>
              )}
              renderFormItem={({ field, formItemListName, disabled }) => (
                <BypassScrapingAdUnit
                  formInstance={formInstance}
                  field={field}
                  disabled={disabled}
                  parentFieldNamePath={formItemListName}
                />
              )}
            />
          </Collapse.Panel>
        </Collapse>
      </Space>
      {saveError && <Alert message={saveError.message} type="error" showIcon />}
    </Form>
  );
};

const settingConfig = {
  name: 'Scrape Prebid Ad Units',
  description:
    'Whether or not (and how) to scrape ad units defined in the Prebid.js variable on the page to create BT Spaces.',
  Form: ScrapePrebidForm,
  transformBeforeSave: ({ adUnitsVar, ...rest }) => {
    // Trim any whitespace from the Prebid Ad Units Variable if provided (ignore/omit if whitespace-only)
    if (adUnitsVar?.trim()) {
      return { adUnitsVar: adUnitsVar.trim(), ...rest };
    } else {
      return rest;
    }
  },
  initialSettingValue: {},
};

export default settingConfig;
