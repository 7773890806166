import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Select, Space, message } from 'antd';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { SearchableEntityTableStatic } from '../../../components';
import api from '../../../services/api';
import { SaveEntityModal } from '../../../services/handlers';
import { compareAlphabetically, generateErrorMessage } from '../../../services/utils';
import LineItemJobRuns from './LineItemJobRuns';
import LineItemJobs from './LineItemJobs';
import {
  JOB_SCHEDULES,
  LINEITEM_ACTIONS,
  LINEITEM_TYPES,
  LINEITEM_TYPES_OPTIONS,
} from './lineItemJobConstants';

const AddGAMExclusionForm = ({ formInstance, saveError }) => (
  <Form
    name="add-gam-exclusion"
    form={formInstance}
    preserve={false}
    requiredMark={false}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
  >
    <Form.Item
      name="network_code"
      label="Network Code"
      rules={[
        {
          // Ensure the network code is provided and corresponds to a numeric value
          validator: (rule, networkCode) =>
            networkCode?.trim() && !isNaN(networkCode) && !networkCode.includes('.')
              ? Promise.resolve(networkCode)
              : Promise.reject('Please input a valid GAM network code!'),
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item name="line_item_types" label="Line Item Type">
      <Select mode="tags" options={LINEITEM_TYPES_OPTIONS} />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const AddGAMExclusion = ({ lineItemJobs, gamNetworks, orgId, networksError }) => {
  return (
    // TODO: Handle networksError
    <div
      style={{
        maxHeight: 60,
        overflowY: 'auto',
      }}
    >
      <SaveEntityModal
        key="add-gam-exclusion"
        triggerRender={({ openModal }) => (
          <Button onClick={openModal} type="primary">
            <PlusOutlined /> Add
          </Button>
        )}
        modalTitle="Add GAM Exclusion"
        validateFormOnSubmit={(values) => {
          const existingManualJob = lineItemJobs
            ?.find((job) => job.network_code === values.network_code)
            ?.jobs.find(({ schedule }) => schedule === JOB_SCHEDULES.JOB_SCHEDULE_OFF);
          if (existingManualJob) {
            throw new Error(
              'A manual exclusion job has already been created for this GAM network!'
            );
          }
        }}
        transformBeforeSave={({ network_code, line_item_types, ...values }) => {
          try {
            const networkName = gamNetworks
              ?.find((network) => network.network_code === network_code)
              ?.network_names?.at(-1);

            const formattedLineItemTypes = LINEITEM_TYPES.reduce(
              (obj, type) => ({
                ...obj,
                [type]: line_item_types.includes(type)
                  ? LINEITEM_ACTIONS.LINEITEM_ACTION_EXCLUSION
                  : LINEITEM_ACTIONS.LINEITEM_ACTION_IGNORE,
              }),
              {}
            );

            return {
              org_id: orgId,
              network_code: network_code.trim(), // Trim any leading/trailing whitespace from GAM network code
              line_item_actions: formattedLineItemTypes,
              schedule: JOB_SCHEDULES.JOB_SCHEDULE_OFF,
              name: networkName,
              ...values,
            };
          } catch (error) {
            generateErrorMessage(error);
          }
        }}
        saveEntity={api.createLineItem}
        onSuccess={() => {
          message.success(`Line item succcesfully created!`);
          mutate(['/LineItemJobList', orgId]); // Refresh list of org's GAM Networks to reflect new addition
        }}
        formComponent={AddGAMExclusionForm}
      />
    </div>
  );
};

const GAMExclusions = ({ orgId, isViewOnly }) => {
  const { data: gamNetworks } = useSWR(['/GAMNetworkList', orgId], () =>
    api.listGAMNetworks({ org_id: orgId })
  );
  const { data: lineItemJobsObj } = useSWR(['/LineItemJobList', orgId], () =>
    api.listLineItems({ org_id: orgId })
  );

  const refreshTime = lineItemJobsObj?.refreshTime;
  const [showJobRuns, setShowJobRuns] = useState(false);
  const [currentJob, setCurrentJob] = useState(null);

  // Since the api returns an array of all line item jobs, we have to organize them by network code so that they can be grouped together
  const lineItemsTableData = useMemo(() => {
    const jobsByNetwork = lineItemJobsObj?.jobs
      .filter((value) => !value.deleted)
      .reduce((obj, job) => {
        if (obj[job.network_code]) {
          obj[job.network_code].jobs.push(job);
        } else {
          obj[job.network_code] = {
            network_code: job.network_code,
            name: job.name,
            jobs: [job],
          };
        }
        return obj;
      }, {});
    return jobsByNetwork && Object.values(jobsByNetwork);
  }, [lineItemJobsObj]);

  const setStatusMessage = () => {
    // Need to specify the latest time the data was fetched to ensure action is taken based on most up-to-date data
    const message =
      'Statuses on this page are NOT live. Please refresh before initiating any actions.';
    return refreshTime
      ? `${message} Last refreshed on ${moment(refreshTime).format('MMM D, YYYY [at] LT')}`
      : message;
  };

  return showJobRuns ? (
    <LineItemJobRuns job={currentJob} setShowJobRuns={setShowJobRuns} />
  ) : (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Alert message={setStatusMessage()} type="info" showIcon />
      <SearchableEntityTableStatic
        title="Exclusions"
        rowKey="name"
        staticTableData={lineItemsTableData}
        isLoading={!lineItemsTableData}
        textSearchFieldNames={['name']}
        textSearchPlaceholder="Search exclusions by network..."
        defaultExpandedRowKeys={currentJob ? [currentJob.name] : []}
        expandable={{
          expandedRowRender: (lineItem) => (
            <LineItemJobs
              jobs={lineItem.jobs.sort((a, b) => compareAlphabetically(b.schedule, a.schedule))}
              setShowJobRuns={setShowJobRuns}
              setCurrentJob={setCurrentJob}
              isViewOnly={isViewOnly}
            />
          ),
        }}
        actions={
          isViewOnly ? null : (
            <AddGAMExclusion
              key="add-gam-exclusion"
              lineItemJobs={lineItemsTableData}
              gamNetworks={gamNetworks}
              orgId={orgId}
            />
          )
        }
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => compareAlphabetically(a.name, b.name),
            width: '25%',
          },
          {
            title: 'Network Code',
            dataIndex: 'network_code',
          },
        ]}
      />
    </Space>
  );
};

export default GAMExclusions;
