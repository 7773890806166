import { PlusOutlined } from '@ant-design/icons';
import { Alert, Checkbox, Form, Input, message, Modal, Radio, Space } from 'antd';
import { mutate } from 'swr';
import api from '../../services/api';
import { SaveEntityModal } from '../../services/handlers';
import { parseValidWebsiteDomains, validateWebsiteDomainsField } from '../../services/utils';
import { formatWhitelistRuleErrorMsg } from '../Website/WhitelistRulesTable';
import { generateErrorMessage } from '../../services/utils';

const AddWebsitesForm = ({ org, formInstance, saveError }) => (
  <Form
    name="add-websites"
    form={formInstance}
    preserve={false}
    requiredMark={false}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
  >
    <Form.Item label="Organization">
      <span>
        {org.name} ({org.id})
      </span>
    </Form.Item>
    <Form.Item
      name="domains"
      label="Domains"
      validateTrigger="onBlur"
      rules={[
        {
          validator: (rule, value) => validateWebsiteDomainsField(value), // Only accepts valid domains
        },
      ]}
    >
      <Input.TextArea
        autoSize={{ minRows: 8, maxRows: 8 }}
        placeholder="Enter each domain on a new line - e.g. &#13;domain1.com &#13;domain2.com"
      />
    </Form.Item>
    <Form.Item name="widget" valuePropName="checked" label="Widget">
      <Checkbox />
    </Form.Item>
    <Form.Item
      name="whitelisting"
      label="Whitelisting"
      rules={[
        {
          required: true,
          message: 'Please select one of the options!',
        },
      ]}
    >
      <Radio.Group>
        <Space direction="vertical" style={{ marginTop: 4 }}>
          <Radio value="generic" defaultChecked>
            Request generic whitelist rules
          </Radio>
          <Radio value="generic-force">Force request generic whitelist rules</Radio>
          <Radio value="none">Do not request generic whitelist rules</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

export const AddWebsitesModal = ({ org, triggerRender }) => {
  const transformBeforeSave = ({ domains, widget, whitelisting }) => ({
    domains: parseValidWebsiteDomains(domains).validDomains, // `validDomains` will be guaranteed to be length > 1 if the form's Domains field was validated with `validateWebsiteDomainsField`
    widget,
    whitelisting,
  });

  const saveWebsites = ({ domains, widget, whitelisting }) =>
    api
      .createWebsites({ org_id: org.id, params: domains.map((domain) => ({ domain, widget })) })
      .then((createWebsitesResponse) => {
        // Send request for generic whitelist rules for successfully created websites
        if (createWebsitesResponse.websites.length > 0 && whitelisting !== 'none') {
          return api
            .createWhitelistRules({
              publisher: org.name,
              generic: {
                domains: createWebsitesResponse.websites.map(({ domain }) => domain),
              },
              forced: whitelisting === 'generic-force',
            })
            .then((createWhitelistRulesResponse) => {
              // Combine websites/errors from the api.createWebsites & api.createWhitelistRules responses
              return {
                websitesCreated: createWebsitesResponse.websites,
                domainsWhitelisted: createWhitelistRulesResponse.domains,
                errors: [
                  ...createWebsitesResponse.errors,
                  ...createWhitelistRulesResponse.errors.map((error) => ({
                    ...error,
                    isWhitelistingError: true,
                  })),
                ],
              };
            });
        } else {
          // Return websites/errors from the api.createWebsites response
          return {
            websitesCreated: createWebsitesResponse.websites,
            errors: createWebsitesResponse.errors,
          };
        }
      });

  return (
    <SaveEntityModal
      buttonText="Add"
      buttonProps={{
        icon: <PlusOutlined />,
      }}
      triggerRender={triggerRender}
      modalTitle="Add Websites"
      transformBeforeSave={transformBeforeSave}
      saveEntity={saveWebsites}
      formComponent={AddWebsitesForm}
      formComponentProps={{ org }}
      formInitialValues={{
        whitelisting: 'generic', // Add default value to custom "Whitelisting" form field
      }}
      onSuccess={({ websitesCreated = [], domainsWhitelisted = [], errors }) => {
        if (errors?.length > 0) {
          const websiteCreationMsg =
            websitesCreated.length === 1
              ? `${websitesCreated[0].domain} was successfully created`
              : websitesCreated.length > 1
              ? `${websitesCreated.length} websites were successfully created`
              : '';
          const domainWhitelistingMsg =
            domainsWhitelisted.length === 1
              ? `generic whitelist rules for ${domainsWhitelisted[0]} were successfully requested`
              : domainsWhitelisted.length > 1
              ? `generic whitelist rules for ${domainsWhitelisted.length} websites were successfully requested`
              : '';

          Modal.error({
            title: 'Errors occurred while adding websites',
            content: (
              <Space direction="vertical" size={8} style={{ marginTop: 8, width: '100%' }}>
                {errors.map((error = {}, index) => (
                  <Alert
                    key={index}
                    message={error.meta?.domain}
                    description={
                      error.isWhitelistingError
                        ? formatWhitelistRuleErrorMsg(error)
                        : generateErrorMessage(error)
                    }
                    type="error"
                  />
                ))}
                {websiteCreationMsg
                  ? `Nevertheless, ${websiteCreationMsg}${
                      websiteCreationMsg && domainWhitelistingMsg ? ', and ' : ''
                    }${domainWhitelistingMsg}.`
                  : null}
              </Space>
            ),
            width: 500,
          });
        } else {
          const whitelistingOccurred = domainsWhitelisted?.length > 0;
          message.success(
            websitesCreated.length === 1
              ? `${websitesCreated[0].domain} successfully created${
                  whitelistingOccurred ? ' & whitelisted' : ''
                }!`
              : `${websitesCreated.length} websites successfully created${
                  whitelistingOccurred ? ' & whitelisted' : ''
                }!`
          );
        }

        mutate(['/WebsiteList', org.id]);
      }}
    />
  );
};
