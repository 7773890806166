import { Button } from 'antd';

const MessageWallPreview = ({ formInstance, env, orgId }) => {

    const previewHandler = () => {
        const fieldsValue = formInstance.getFieldsValue()[env]['am_settings'];

        // prevent MW disabling
        fieldsValue['country_code_mode'] = 'disabled';
        fieldsValue['allowlisted_urls'] = [];
        fieldsValue['render_interval_days'] = 0;
        fieldsValue['page_views_to_render'] = 0;

        const sParam = window.btoa(encodeURIComponent(JSON.stringify(fieldsValue)));
        const myUrlWithParams = new URL(`${window.location.origin}/message-wall.html`);
        myUrlWithParams.searchParams.append("s", sParam);
        myUrlWithParams.searchParams.append("o", orgId);
        myUrlWithParams.searchParams.append("preview", "true")

        window.open(myUrlWithParams, '_blank').focus();
    }


    return (
        <Button type="default" onClick={previewHandler}>Preview</Button>
    )
}


export default MessageWallPreview;

