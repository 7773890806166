import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import styles from './HelpTooltip.module.less';

const HelpTooltip = (tooltipProps) => (
  <Tooltip {...tooltipProps}>
    <QuestionCircleOutlined className={styles.helpTooltipIcon} />
  </Tooltip>
);

export default HelpTooltip;
