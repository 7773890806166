import { Alert, Form } from 'antd';
import React from 'react';
import { FormItemList, TagsSelector } from '../../../components';
import { WhenDevice } from './formComponents';

const HideDOMElementsViaCSSForm = ({ formInstance, isViewOnly, saveError }) => (
  <Form
    name="settings-hide-dom-elements-via-css"
    form={formInstance}
    disabled={isViewOnly}
    preserve={false}
    requiredMark={false}
  >
    <FormItemList
      name="value"
      disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
      addPrompt="Add DOM Elements to hide"
      addInitialValue={{ whenDevice: 'any' }}
      renderFormItem={({ field, disabled }) => (
        <>
          <WhenDevice field={field} />
          <Form.Item
            {...field}
            name={[field.name, 'selectors']}
            label="Element CSS Selectors"
            rules={[
              {
                required: true,
                // validator: (rule, value) => validateJson(value),
                message: 'Please enter at least 1 CSS selector!',
              },
            ]}
          >
            <TagsSelector disabled={disabled} />
          </Form.Item>
        </>
      )}
    />
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const settingConfig = {
  name: 'Hide DOM Elements via CSS',
  description:
    'A list of CSS selectors whose DOM elements will have the `display: none !important;` CSS style rule applied to them.',
  // renderExpand: (setting) => <div>{JSON.stringify(setting)}</div>,
  Form: HideDOMElementsViaCSSForm,
  initialSettingValue: [],
};

export default settingConfig;
