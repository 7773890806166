import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Badge, Button, Input } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { useDebouncedCallback } from 'use-debounce';
import api from '../services/api';
import { useStore } from '../services/store';
import { compareAlphabetically, sortAlphabetically } from '../services/utils';
import styles from './UniversalSearch.module.less';

function renderWebsiteResult(website, handleSelect) {
  const websitePagePath = `/websites/${website.id}`;
  const websiteOrgPagePath = `/orgs/${website.org_id}`;
  const isEnabled = website?.prod_params?.content_enabled;

  return {
    website,
    value: websitePagePath,
    label: (
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div style={{ display: 'flex', width: '60%' }}>
          <Badge status={isEnabled ? 'success' : 'default'} />
          <span
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: 8 }}
            title={website.domain}
          >
            {website.domain}
          </span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '40%' }}>
          <Button
            type="link"
            size="small"
            onClick={(event) => {
              handleSelect(websiteOrgPagePath, {
                org: { id: website.org_id, name: website.org_name },
              });
              event.stopPropagation(); // Stop propagation, otherwise event bubbles up and Autocomplete links to the Website Details page
            }}
            style={{
              fontSize: 12,
              fontStyle: 'italic',
              maxWidth: '100%',
            }}
          >
            <span
              style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                verticalAlign: 'middle',
              }}
              title={website.org_name}
            >
              {website.org_name}
            </span>
          </Button>
        </div>
      </div>
    ),
  };
}
function renderOrganizationResult(org) {
  const orgPagePath = `/orgs/${org.id}`;
  const isEnabled = !org.disabled;

  return {
    org,
    value: orgPagePath,
    label: <Badge status={isEnabled ? 'success' : 'default'} text={org.name} />,
  };
}

function sortWebsites(websites) {
  return [...websites].sort((websiteA, websiteB) => {
    // First, sort websites by org name
    const orgSortValue = compareAlphabetically(websiteA.org_name, websiteB.org_name);
    if (orgSortValue === 0) {
      // The websites are in the same org, sort them by website domain
      return compareAlphabetically(websiteA.domain, websiteB.domain);
    } else {
      return orgSortValue;
    }
  });
}

const UniversalSearch = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useStore();

  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchRequestQuery, setSearchRequestQuery] = useState(''); // When changed, triggers new Search API calls

  const { data: websiteSearchResults, error: websiteSearchResultsError } = useSWR(
    searchRequestQuery ? ['/WebsiteSearch', searchRequestQuery] : null, // Search for matching websites when searchRequestQuery is not empty (falsey)
    () => api.searchWebsites({ query: searchRequestQuery, pagination: { page_size: 5 } })
  );

  const { data: orgSearchResults, error: orgSearchResultsError } = useSWR(
    searchRequestQuery ? ['/OrgSearch', searchRequestQuery] : null, // Search for matching orgs when searchRequestQuery is not empty (falsey)
    () => api.searchOrgs({ query: searchRequestQuery, pagination: { page_size: 4 } })
  );

  const triggerNewSearchCalls = useDebouncedCallback((newSearchRequestQuery) => {
    setSearchRequestQuery(newSearchRequestQuery);
  }, 600); // Trigger search on 0.6s debounce

  const handleSearchInputChange = useCallback(
    (newSearchInputValue) => {
      setSearchInputValue(newSearchInputValue);
      triggerNewSearchCalls.callback(newSearchInputValue);
    },
    [setSearchInputValue, triggerNewSearchCalls]
  );

  const handleSelect = useCallback(
    (orgOrWebsitePagePath, { website, org }) => {
      handleSearchInputChange(''); // Clear search input/query, dropdown options, etc.
      dispatch({ type: 'ADD_TO_RECENT_SEARCH', newEntry: { website, org } }); // Add selected result (whether it was an `org` or `website`) to recent search results
      navigate(orgOrWebsitePagePath); // Proceed to org or website details page
    },
    [handleSearchInputChange, dispatch, navigate]
  );

  const [dropdownOptions, setDropdownOptions] = useState([]);

  // When the user has yet to type in a query, show "recent search results" (results which were recently searched AND navigated to).
  const recentSearchDropdownOptions = useMemo(
    () =>
      state.recentSearch.length > 0
        ? [
            {
              label: 'Recent',
              options: state.recentSearch.map(({ org, website }) =>
                org
                  ? renderOrganizationResult(org)
                  : website
                  ? renderWebsiteResult(website, handleSelect)
                  : { label: 'Something Went Wrong', value: '' }
              ),
            },
          ]
        : [],
    [state.recentSearch, handleSelect]
  );

  // Populate AutoComplete dropdown options
  useEffect(() => {
    if (!searchRequestQuery) {
      setDropdownOptions(recentSearchDropdownOptions);
    } else if (orgSearchResults && websiteSearchResults) {
      const options = [];
      if (orgSearchResults.length > 0) {
        options.push({
          label: 'Organizations',
          options: sortAlphabetically(orgSearchResults, 'name').map((org) =>
            renderOrganizationResult(org)
          ),
        });
      }
      if (websiteSearchResults.length > 0) {
        options.push({
          label: 'Websites',
          options: sortWebsites(websiteSearchResults).map((website) =>
            renderWebsiteResult(website, handleSelect)
          ),
        });
      }
      setDropdownOptions(options);
    }
  }, [
    searchRequestQuery,
    websiteSearchResults,
    orgSearchResults,
    recentSearchDropdownOptions,
    handleSelect,
  ]);

  const isDebouncing = searchInputValue !== searchRequestQuery;
  const errorLoadingResults = websiteSearchResultsError || orgSearchResultsError;
  const isLoadingResults = !(websiteSearchResults && orgSearchResults) && !errorLoadingResults;
  const shouldShowLoadingIndicator = searchInputValue && (isDebouncing || isLoadingResults);

  return (
    <AutoComplete
      dropdownClassName={styles.searchResultsDropdown}
      options={dropdownOptions}
      onSearch={handleSearchInputChange}
      onSelect={handleSelect}
      notFoundContent={
        searchInputValue
          ? errorLoadingResults
            ? 'An error occurred loading the search results.'
            : shouldShowLoadingIndicator
            ? 'Loading...'
            : 'No orgs/websites found.'
          : null
      }
      value={searchInputValue}
    >
      <Input
        placeholder="Search organizations & websites..."
        suffix={shouldShowLoadingIndicator ? <LoadingOutlined /> : <SearchOutlined />}
        bordered={false}
        style={{ borderBottom: '1px solid #d9d9d9', width: 360 }}
      />
    </AutoComplete>
  );
};

export default UniversalSearch;
