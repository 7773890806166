import { CloseOutlined, HolderOutlined } from '@ant-design/icons';
import { Table, Typography } from 'antd';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import styles from './DragAndDropTable.module.less';

const DraggableBodyRow = ({
  index,
  rowData,
  rowKey,
  fixedFirstRowKeyValue,
  className,
  ...restProps
}) => {
  if (rowData) {
    const rowKeyValue = rowData?.[rowKey];
    if (rowKeyValue === fixedFirstRowKeyValue) {
      return <tr className={className} {...restProps} />;
    } else {
      return (
        <Draggable key={rowKeyValue} draggableId={rowKeyValue.toString()} index={index}>
          {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
            <tr
              ref={innerRef}
              className={`${className} ${isDragging ? styles.isDragging : ''}`}
              {...restProps}
              {...draggableProps}
              {...dragHandleProps}
            />
          )}
        </Draggable>
      );
    }
  }
  // TODO: temporary fix for crashing when rowData is undefined
  return (
    <tr className={className} {...restProps}>
      <td>
        <Typography.Text italic style={{ display: 'block', textAlign: 'center' }}>
          None Selected
        </Typography.Text>
      </td>
    </tr>
  );
};

const createDroppableTableBody = (name) => ({ children, ...props }) => (
  <Droppable droppableId={name}>
    {({ innerRef, droppableProps, placeholder }) => (
      <tbody ref={innerRef} {...props} {...droppableProps}>
        {children}
        {placeholder}
      </tbody>
    )}
  </Droppable>
);

const reorderRows = (rows, sourceIndex, destinationIndex) => {
  const newRows = [...rows];

  // Move row currently at `sourceIndex` to its new `destinationIndex`
  const [row] = newRows.splice(sourceIndex, 1);
  newRows.splice(destinationIndex, 0, row);

  return newRows;
};

const DragAndDropTable = ({
  name,
  columns = [],
  data,
  setData,
  rowKey = 'key',
  fixedFirstRowKeyValue,
  className,
}) => {
  const components = {
    body: {
      wrapper: createDroppableTableBody(name),
      row: DraggableBodyRow,
    },
  };

  const onDragEnd = ({ source, destination }) => {
    if (destination) {
      setData(reorderRows(data, source.index, destination.index));
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Table
        className={[className, styles.dragAndDropTable]}
        locale={{
          emptyText: (
            <Typography.Text italic style={{ display: 'block', textAlign: 'center' }}>
              None Selected
            </Typography.Text>
          ),
        }}
        columns={[
          {
            key: 'drag-icon',
            className: 'drag-icon',
            render: (_, row) => (row[rowKey] === fixedFirstRowKeyValue ? null : <HolderOutlined />),
          },
          ...columns,
          {
            key: 'close-action',
            className: 'close-action',
            render: (_, row) => (
              <CloseOutlined
                style={{ fontSize: 12, padding: 4 }}
                onClick={() => {
                  setData(data.filter((dimension) => dimension[rowKey] !== row[rowKey]));
                }}
              />
            ),
          },
        ]}
        dataSource={data}
        rowKey={rowKey}
        components={components}
        pagination={false}
        size="small"
        onRow={(rowData, index) => {
          const attr = {
            index,
            rowData,
            rowKey,
            fixedFirstRowKeyValue,
          };
          return attr;
        }}
      />
    </DragDropContext>
  );
};

export default DragAndDropTable;
