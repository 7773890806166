import { Form, Radio, Typography } from 'antd';
import { TagOutlined } from '@ant-design/icons';

const { Text } = Typography;

const ScriptLoadingMode = ({ env, disabled, orgId, form }) => {
  const scriptLoadingMode = Form.useWatch([env, 'am_settings', 'script_loading_mode'], form);
  const radioOptions = [
    { label: 'Inject From Tag', value: 'inject_from_tag_script' },
    {
      label: (
        <>
          Standalone
          <Text
            copyable={
              disabled
                ? false
                : {
                    icon: (
                      <TagOutlined
                        style={{
                          color:
                            scriptLoadingMode === 'inject_from_tag_script' ? 'gray' : undefined,
                        }}
                      />
                    ),
                    tooltips: ['Copy standalone RL script tag', 'Copied'],
                    text: `<script type="module" async src="https://cdn.btmessage.com/script/rlink.js?o=${orgId}"></script>`,
                  }
            }
          />
        </>
      ),
      value: 'standalone',
    },
  ];
  return (
    <>
      <Form.Item label="Script Loading Mode" name={[env, 'am_settings', 'script_loading_mode']}>
        <Radio.Group options={radioOptions} optionType="button" disabled={disabled} />
      </Form.Item>
    </>
  );
};

export default ScriptLoadingMode;
