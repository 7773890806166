import { Alert, Form } from 'antd';
import React from 'react';
import { CodeEditor } from '../..';
import { validateJson } from '../../../services/utils';

const runOfNetworkIdsConfigPlaceholder = `{
  "appnexus": {
    "placementId": 27493658
  },
  "ix": {
    "siteId": "809203"
  },
  "onetag": {
    "pubId": "75804861b76a852"
  },
  "pubmatic": [
    {
      "AdThrive_Below_Post_1_desktop": {
        "adSlot": "AdThrive_Below_Post_1",
        "publisherId": "160377"
      }
    }
  ]
}
`;

const AddPublisherRONIDsForm = ({ formInstance, isViewOnly, saveError }) => (
  <Form
    name="settings-add-run-of-network-ids"
    form={formInstance}
    disabled={isViewOnly}
    preserve={false}
    requiredMark={false}
  >
    <Form.Item
      name={['value', 'runOfNetworkIdsConfig']}
      label="Run Of Network IDs Config"
      tooltip="Run Of Network IDs Config JSON object - If specifying params by placement, provide them in an array where each entry includes the corresponding Space name."
      labelCol={{ span: 24 }}
      rules={[
        {
          required: true,
          validator: (rule, value) => validateJson(value),
          message: 'Please enter a valid Run Of Network Config JSON object!',
        },
      ]}
    >
      <CodeEditor
        placeholder={runOfNetworkIdsConfigPlaceholder}
        height={370}
        disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
      />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const settingConfig = {
  name: `Add publisher's Run Of Network IDs`,
  description: "Enables and configures publisher's Run Of Network IDs",
  Form: AddPublisherRONIDsForm,
  // Transform RON ids config to and from JS Object <-> JSON string because form must use string object but we want to store the value as an Object
  transformBeforeInit: ({ runOfNetworkIdsConfig, ...rest }) => ({
    runOfNetworkIdsConfig: JSON.stringify(runOfNetworkIdsConfig, null, 2),
    ...rest,
  }),
  transformBeforeSave: ({ runOfNetworkIdsConfig, ...rest }) => ({
    runOfNetworkIdsConfig: JSON.parse(runOfNetworkIdsConfig),
    ...rest,
  }),
  initialSettingValue: {},
};

export default settingConfig;
