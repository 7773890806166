import { Alert, Form, Radio, Space } from 'antd';
import React from 'react';

export function createBooleanSettingForm(formName, optionText) {
  return ({ formInstance, isViewOnly, saveError }) => (
    <Form
      name={formName}
      form={formInstance}
      disabled={isViewOnly}
      preserve={false}
      requiredMark={false}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Form.Item
        name="value"
        rules={[
          {
            required: true,
            message: 'Please select one of the options!',
          },
        ]}
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value={true}>{optionText}</Radio>
            <Radio value={false}>Do not {optionText}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      {saveError && <Alert message={saveError.message} type="error" showIcon />}
    </Form>
  );
}
