import React from 'react';

import { Alert, Button, Divider, Form, Input, message, Space, Tag, Typography } from 'antd';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { mutate } from 'swr';

import api from '../../../services/api';
import { compareAlphabetically } from '../../../services/utils';
import { DeleteEntityPopconfirm, SaveEntityModal } from '../../../services/handlers';
import {
  SearchableEntityTable,
  ShortenedItemsWithTooltip,
  TagsSelector,
} from '../../../components';
import styles from './GAMNetworks.module.less';
import { TIME_ZONE } from '../../../services/constants';
import { useAsync } from '../../../services/hooks';

const EXPECTED_GAM_NETWORK_EMAIL = 'blockthrough@reports-215419.iam.gserviceaccount.com';

const getLatestNetworkName = (networkNames) => networkNames.at(-1);
const hasWhiteSpaces = (networkName) => networkName.length !== networkName.trim().length;

const linkButtonProps = {
  type: 'link',
  size: 'small',
  style: {
    width: 14,
    height: 'auto',
    marginLeft: 4,
    marginBottom: 3,
    verticalAlign: 'middle',
  },
};

const statusMapper = {
  unknown: {
    color: 'default',
    label: 'Unknown',
  },
  connected: {
    color: 'success',
    label: 'Connected',
  },
  disconnected: {
    color: 'error',
    label: 'Disconnected',
  },
  not_found: {
    color: 'error',
    label: 'Not Found',
  },
  error: {
    color: 'error',
    label: 'Error',
  },
};

const expandedContentItems = [
  {
    key: 'network_names',
    label: 'Network Name',
  },
  {
    key: 'network_code',
    label: 'Network Code',
  },
  {
    key: 'timezone',
    label: 'Timezone',
  },
  {
    key: 'currency',
    label: 'Currency',
  },
  {
    key: 'network_name_aliases',
    label: 'Network Name Alias',
  },
  {
    key: 'connection_status',
    label: 'Status',
  },
  {
    key: 'role_name',
    label: 'Role Name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'created_at',
    label: `Created (${TIME_ZONE})`,
  },
  {
    key: 'updated_at',
    label: `Updated (${TIME_ZONE})`,
  },
  {
    key: 'last_seen',
    label: `Last Seen (${TIME_ZONE})`,
  },
];

const AddGAMNetworkForm = ({ formInstance, saveError }) => (
  <Form
    name="gam-network"
    form={formInstance}
    preserve={false}
    requiredMark={false}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
  >
    <Form.Item
      name="network_name"
      label="Network Name"
      rules={[
        {
          required: true,
          whitespace: true, // Providing only whitespace is considered invalid
          message: 'Please input a GAM network name!',
        },
        {
          warningOnly: true,
          pattern: /^[^\s].*[^\s]$/,
          message: 'GAM network name contains whitespaces.',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="network_code"
      label="Network Code"
      rules={[
        {
          // Ensure the network code is provided and corresponds to a numeric value
          validator: (rule, networkCode) =>
            networkCode?.trim() && !isNaN(networkCode) && !networkCode.includes('.')
              ? Promise.resolve(networkCode)
              : Promise.reject('Please input a valid GAM network code!'),
        },
      ]}
    >
      <Input />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const AddGAMNetwork = ({ orgId, networksError }) => (
  // TODO: Handle networksError
  <div
    style={{
      maxHeight: 60,
      overflowY: 'auto',
    }}
  >
    <SaveEntityModal
      key="add-gam-network"
      triggerRender={({ openModal }) => (
        <Button onClick={openModal} type="primary">
          <PlusOutlined /> Add
        </Button>
      )}
      modalTitle="Add GAM Network"
      transformBeforeSave={({ network_code, network_name, ...values }) => ({
        org_id: orgId,
        network_code: network_code.trim(), // Trim any leading/trailing whitespace from GAM network code
        network_names: [network_name],
        ...values,
      })}
      saveEntity={api.createGAMNetwork}
      onSuccess={({ gam_network: { network_name } }) => {
        message.success(`${network_name} succcesfully added to organization!`);
        mutate(['/GAMNetworkList', orgId]); // Refresh list of org's GAM Networks to reflect new addition
      }}
      formComponent={AddGAMNetworkForm}
    />
  </div>
);

const EditGAMNetworkForm = ({ formInstance, saveError }) => {
  return (
    <Form
      name="edit-gam-network"
      form={formInstance}
      preserve={false}
      requiredMark={false}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Form.Item
        name="network_names"
        label="Network Names"
        rules={[
          {
            required: true,
            message: 'Please input at least 1 network name!',
          },
        ]}
      >
        <TagsSelector inputWidth="100%" inputSize="middle" />
      </Form.Item>
      <Form.Item name="network_code" label="Network Code">
        <Input disabled />
      </Form.Item>
      {saveError && <Alert message={saveError.message} type="error" showIcon />}
    </Form>
  );
};

const EditGAMNetwork = ({ network }) => {
  return (
    <div
      style={{
        maxHeight: 60,
        overflowY: 'auto',
      }}
    >
      <SaveEntityModal
        key="edit-gam-network-settings"
        triggerRender={({ openModal }) => (
          <Button {...linkButtonProps} onClick={openModal} icon={<EditOutlined />} />
        )}
        modalTitle="Edit GAM Network Settings"
        transformBeforeSave={(values) => ({
          id: network.id,
          ...values,
        })}
        saveEntity={api.updateGAMNetwork}
        onSuccess={() => {
          message.success('GAM Network settings successfully updated!');
          mutate(['/GAMNetworkList', network.org_id]);
        }}
        formComponent={EditGAMNetworkForm}
        formInitialValues={network}
      />
    </div>
  );
};

const DeleteGAMNetwork = ({ network: { id }, onDeleted }) => {
  const { execute: deleteNetwork } = useAsync(() => api.deleteGAMNetwork(id));
  return (
    <DeleteEntityPopconfirm
      prompt="Are you sure you want to remove this GAM Network from the organization?"
      onDeleted={onDeleted}
      deleteEntity={deleteNetwork}
    />
  );
};

const ExpandedContentItemValue = ({ itemKey, itemValue, network, isViewOnly }) => {
  switch (itemKey) {
    case 'created_at':
    case 'updated_at':
    case 'last_seen':
      const date = itemValue && moment(itemValue);
      return (
        <>
          <span style={{ whiteSpace: 'nowrap' }}>{date?.format('YYYY-MM-DD') || 'N/A'}</span>
          <span style={{ marginLeft: '4px' }}>{date?.format('(HH:mm)')}</span>
        </>
      );
    case 'email':
      return (
        <>
          <Typography.Text
            className={itemValue ? styles.ellipsisText : ''}
            ellipsis={{ tooltip: itemValue }}
          >
            {itemValue || 'N/A'}
          </Typography.Text>
          {itemValue === EXPECTED_GAM_NETWORK_EMAIL ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" style={{ marginLeft: '4px' }} />
          ) : (
            <CloseCircleTwoTone twoToneColor="#ff0000" style={{ marginLeft: '4px' }} />
          )}
        </>
      );
    case 'network_name_aliases':
      return <ShortenedItemsWithTooltip items={itemValue} />;
    case 'timezone': // TODO: we can remove this if/when backend makes change to convert this to abbreviated value
      return itemValue.replaceAll('_', ' ') || 'N/A';
    case 'connection_status':
      return statusMapper[itemValue].label;
    case 'network_names':
      return (
        <div style={{ display: 'flex' }}>
          {hasWhiteSpaces(getLatestNetworkName(itemValue)) ? (
            <u style={{ whiteSpace: 'pre' }}>{getLatestNetworkName(itemValue)}</u>
          ) : (
            <Typography.Text
              className={styles.ellipsisText}
              ellipsis={{ tooltip: getLatestNetworkName(itemValue) }}
            >
              {getLatestNetworkName(itemValue)}
            </Typography.Text>
          )}
          {isViewOnly ? null : <EditGAMNetwork network={network} />}
        </div>
      );
    default:
      return (
        <Typography.Text className={styles.ellipsisText} ellipsis={{ tooltip: itemValue }}>
          {itemValue || 'N/A'}
        </Typography.Text>
      );
  }
};

const ExpandedContent = ({ network, isViewOnly }) => {
  return (
    <div className={styles.expandedContentContainer}>
      {expandedContentItems.map((item, index) => (
        <div
          className={styles.expandedContentItem}
          key={`expanded-content-item-${index}-${item.key}`}
        >
          <Typography.Text strong>{item.label}</Typography.Text>
          <Space>
            <ExpandedContentItemValue
              itemKey={item.key}
              itemValue={network[item.key]}
              network={network}
              isViewOnly={isViewOnly}
            />
          </Space>
        </div>
      ))}
    </div>
  );
};

const GAMNetworks = ({ orgId, isViewOnly }) => {
  const gamNetworksSwrKey = orgId ? ['/GAMNetworkList', orgId] : null;

  return (
    <SearchableEntityTable
      title="GAM Network Settings"
      swrKey={gamNetworksSwrKey}
      swrFetcher={() => api.listGAMNetworks({ org_id: orgId })}
      textSearchFieldNames={['network_name']}
      textSearchPlaceholder="Search networks by name..."
      expandable={{
        expandedRowRender: (network) => (
          <ExpandedContent network={network} isViewOnly={isViewOnly} />
        ),
      }}
      actions={isViewOnly ? null : <AddGAMNetwork key="add-gam-network" orgId={orgId} />}
      columns={[
        {
          title: 'Network Name',
          dataIndex: 'network_names',
          defaultSortOrder: 'ascend',
          sortDirections: ['ascend', 'descend', 'ascend'],
          sorter: (a, b) =>
            compareAlphabetically(
              getLatestNetworkName(a.network_names),
              getLatestNetworkName(b.network_names)
            ),
          render: (network_names) =>
            hasWhiteSpaces(getLatestNetworkName(network_names)) ? (
              <u style={{ whiteSpace: 'pre' }}>{getLatestNetworkName(network_names)}</u>
            ) : (
              getLatestNetworkName(network_names) || 'N/A'
            ),
        },
        {
          title: 'Network Code',
          dataIndex: 'network_code',
          sortDirections: ['ascend', 'descend', 'ascend'],
          render: (network_code) => network_code || 'N/A',
        },
        {
          title: 'Role Name',
          dataIndex: 'role_name',
          sortDirections: ['ascend', 'descend', 'ascend'],
          sorter: (a, b) => compareAlphabetically(a.role_name, b.role_name),
          render: (role_name, { is_service_account }) => {
            return (
              <Space>
                {role_name || 'N/A'}
                {is_service_account ? <Tag color="processing">Service</Tag> : null}
              </Space>
            );
          },
        },
        {
          title: 'Timezone',
          dataIndex: 'timezone',
          sortDirections: ['ascend', 'descend', 'ascend'],
          sorter: (a, b) => compareAlphabetically(a.timezone, b.timezone),
          render: (timezone) => timezone.replaceAll('_', ' ') || 'N/A',
        },
        {
          title: 'Currency',
          dataIndex: 'currency',
          sortDirections: ['ascend', 'descend', 'ascend'],
          sorter: (a, b) => compareAlphabetically(a.currency, b.currency),
          render: (currency) => currency || 'N/A',
        },
        {
          title: 'Status',
          dataIndex: 'connection_status',
          sortDirections: ['ascend', 'descend', 'ascend'],
          sorter: (a, b) => compareAlphabetically(a.connection_status, b.connection_status),
          render: (connection_status) => {
            const status = statusMapper[connection_status];
            return <Tag color={status.color}>{status.label}</Tag>;
          },
        },
        {
          key: 'actions',
          hidden: isViewOnly,
          render: (_, network) => (
            <Space size={0} split={<Divider type="vertical" />}>
              <DeleteGAMNetwork
                network={network}
                onDeleted={() => {
                  message.success(`${network.network_name} successfully removed!`);
                  mutate(['/GAMNetworkList', orgId]); // Refresh list of org's GAM networks to reflect deletion
                }}
              />
            </Space>
          ),
          width: 77,
        },
      ].filter((column) => !column.hidden)}
    />
  );
};

export default GAMNetworks;
