import { Tooltip } from 'antd';
import React from 'react';

const ShortenedItemsWithTooltip = ({ items = [] }) => {
  if (items.length === 0) {
    return 'N/A';
  }
  const [firstItem, ...otherItems] = items;
  return (
    <>
      {firstItem}
      {otherItems?.length > 0 ? (
        <span>
          {' & '}
          {otherItems.length === 1 ? (
            otherItems[0]
          ) : (
            <Tooltip title={otherItems.join(', ')}>{otherItems.length} more</Tooltip>
          )}
        </span>
      ) : null}
    </>
  );
};

export default ShortenedItemsWithTooltip;
