import { Alert, Form, InputNumber } from 'antd';
import React from 'react';

const PrebidTimeoutForm = ({ formInstance, isViewOnly, saveError }) => {
  return (
    <Form
      name="settings-prebid-timeout"
      form={formInstance}
      disabled={isViewOnly}
      preserve={false}
      requiredMark={false}
    >
      <Form.Item
        name="value"
        label="Prebid Timeout in ms"
        rules={[
          {
            required: true,
            message: 'Please enter a Prebid Timeout value',
          },
        ]}
        placeholder="2000"
      >
        <InputNumber />
      </Form.Item>
      {saveError && <Alert message={saveError.message} type="error" showIcon />}
    </Form>
  );
};

const settingConfig = {
  name: 'Prebid Timeout',
  description:
    'The Prebid.js Global Bidder Timeout to apply (overrides the default of 2000ms when set)',
  Form: PrebidTimeoutForm,
  initialSettingValue: 2000,
};

export default settingConfig;
