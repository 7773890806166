import { Form } from 'antd';
import styles from './ScopePage.module.less';

export const ScopePageForm = ({ children }) => (
  <Form layout="inline" className={styles.scopePageForm}>
    {children}
  </Form>
);

export { ScopePageByOrg, useScopePageByOrg } from './ScopePageByOrg';
