import moment from 'moment';

export const BLOCKTHROUGH_ORG_ID = '-1'; // the Org ID assigned to internal Admin & System Admin users

export const TIME_ZONE = `GMT${moment().format('Z')}`; // UTC offset for user's local timezone

// Matches (what appears to be) a valid domain name for a string
// NOTE: It will not match/consider the string to be a valid domain name if it has leading or trailing whitespace!
// Source: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch08s15.html
export const DOMAIN_REGEX = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;

// Matches "http(s)://", "www.", or "http(s)://www." (case-insensitive) at the start of a string
// NOTE: It will not match instances if the string has leading whitespace!
export const HTTP_REGEX = /^(?:https?:\/\/)?(?:www\.)?/i;

// NOTE: Make sure to omit periods at end of error code `text`!
export const ERROR_CODES = {
  '0': {
    text: 'an unknown error occurred',
  },
  '1': {
    text: 'invalid data provided',
  },
  '2': {
    text:
      'you do not have the correct access to make this change. Please reach out to your Organization Admin or Blockthrough Publisher Success Manager',
  },
  '3': {
    text: 'entry not found',
  },
  '4': {
    text: 'an entry with this name already exists',
  },
  '7': {
    text:
      'to ensure your account is secure, we have updated our password policy. Please reset your password to log in again',
  },
};

export const DASHBOARD_HELPFUL_LINKS = {
  '/': [
    {
      name: 'Dashboard Overview',
      link: 'https://blockthrough.com/knowledge-base/dashboard-overview/',
    },
    { name: 'Metrics Overview', link: 'https://blockthrough.com/knowledge-base/metrics-overview/' },
    { name: 'Dashboard Tables', link: 'https://blockthrough.com/knowledge-base/dashboard-tables/' },
  ],
  '/users': [
    { name: 'User Management', link: 'https://blockthrough.com/knowledge-base/user-management/' },
  ],
  '/reports': [
    {
      name: 'Report Builder',
      link: 'https://blockthrough.com/knowledge-base/custom-report-builder-overview/',
    },
    {
      name: 'Template Reports',
      link: 'https://blockthrough.com/knowledge-base/template-reports',
    },
    { name: 'Quick Actions', link: 'https://blockthrough.com/knowledge-base/quick-actions' },
  ],
  '/reports/create': [
    {
      name: 'Create a report',
      link: 'https://blockthrough.com/knowledge-base/create-a-report/',
    },
  ],
};

export const SEARCH_PARAM_KEYS = {
  ORG_ID: 'org_id',
  DATE_START: 'date_start',
  DATE_END: 'date_end',
  INCLUDE_BACKFILL: 'backfill',
};
