import { Form, Switch } from 'antd';

const RenderOptionsFormItem = ({ env, form }) => {
  const singleClickAllowlistFieldName = [env, 'am_settings', 'single_click_enabled'];
  const renderToAAUsersFieldName = [env, 'am_settings', 'allow_render_to_aa_users'];

  const isRenderToAAUsersAllowed = Form.useWatch(renderToAAUsersFieldName, form);

  return (
    <>
      <Form.Item
        label="Single Click Allowlist"
        name={singleClickAllowlistFieldName}
        valuePropName="checked"
      >
        <Switch disabled={ isRenderToAAUsersAllowed } />
      </Form.Item>
      <Form.Item
        label="Render To Acceptable Ads Users"
        name={renderToAAUsersFieldName}
        valuePropName="checked"
      >
        <Switch onChange={(isEnabled) => {
          if (isEnabled) {
            // When the "Render to AA Users" is enabled, automatically also enable "Single Click Allowlist"
            form.setFieldValue(singleClickAllowlistFieldName, true);
          }
        }}/>
      </Form.Item>
    </>
  )
}

export default RenderOptionsFormItem;