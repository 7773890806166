import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Descriptions,
  message,
  PageHeader,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import {
  AuditLog,
  CodeDiffViewerModal,
  CodeEditorModalStatic,
  OnceLoaded,
  SettingsTable,
  WebsiteSoftMessageWallSettings,
} from '../../components';
import { PageContents } from '../../layout';
import api from '../../services/api';
import { TIME_ZONE } from '../../services/constants';
import { confirmDelete } from '../../services/handlers';
import { useAsync, useSessionUser } from '../../services/hooks';
import { removeCommentsFromJSCode } from '../../services/utils';
import { EditWebsiteModal } from '../Organization/EditWebsites';
import StaticSpacesTable from './StaticSpacesTable';
import WhitelistRulesTable from './WhitelistRulesTable';

const DeleteWebsite = ({ website }) => {
  const navigate = useNavigate();
  const { execute: deleteWebsite } = useAsync(() => api.deleteWebsite(website.id));

  return (
    <Button
      danger
      onClick={() => {
        confirmDelete({
          deleteEntity: deleteWebsite,
          onDeleted: () => {
            message.success(`${website.domain} successfully deleted!`);

            // Redirect to the parent org page
            navigate(`/orgs/${website.org_id}`);
            // TODO: ^ Instead, redirect to last page ("back") or most appropriate page once Websites section is a thing?
            // mutate('/WebsiteList'); // (May be required if redirecting to Websites section (not yet a thing))
          },
          prompt: 'Are you sure you want to delete this website?',
        });
      }}
    >
      Delete Website
    </Button>
  );
};

const DEFAULT_TAB_KEY = 'spaces';

const Website = () => {
  const { websiteId } = useParams();

  // NOTE: Only internal (i.e Admin & System Admin) users can view this page, and only System Admin users have edit permissions
  const { isSystemAdmin } = useSessionUser();
  const isViewOnly = !isSystemAdmin;

  const [activeTabKey, setActiveTabKey] = useState(DEFAULT_TAB_KEY);
  // Reset tab content if navigating from one website's Website Details page to another website's
  useEffect(() => {
    setActiveTabKey(DEFAULT_TAB_KEY);
  }, [websiteId]);

  const { data: website, error: websiteError } = useSWR(['/WebsiteGet', websiteId]);
  const { data: org, error: orgError } = useSWR(() => ['/OrgGet', website.org_id]);

  const isEnabled = website?.prod_params?.content_enabled;
  const isMobileEnabled = website?.prod_params?.mobile_enabled;
  const isGamEnabled = website?.prod_params?.dfp_enabled;

  return (
    <OnceLoaded
      error={websiteError || orgError}
      isLoading={!website || !org}
      render={() => (
        <>
          <PageHeader
            key="header"
            ghost={false}
            title={
              <a href={`https://${website.domain}`} target="_blank" rel="noopener noreferrer">
                {website.domain}
              </a>
            }
            subTitle={
              <Space>
                <Typography.Text copyable type="secondary">
                  {website.id}
                </Typography.Text>
                {website.widget ? <Tag>Widget</Tag> : null}
              </Space>
            }
            breadcrumb={{
              routes: [
                {
                  path: '/orgs',
                  breadcrumbName: 'Organizations',
                },
                {
                  path: `/orgs/${org.id}`,
                  breadcrumbName: org.name,
                },
                {
                  path: 'ignore', // used for BreadcrumbItem 'key' prop (required)
                },
              ],
              itemRender: ({ path, breadcrumbName }) => <Link to={path}>{breadcrumbName}</Link>,
            }}
            extra={
              isViewOnly
                ? null
                : [
                    <DeleteWebsite key="delete-website" website={website} />,
                    <EditWebsiteModal
                      key="edit-website"
                      buttonText="Edit Website"
                      org={org}
                      website={website}
                    />,
                  ]
            }
            footer={
              // Bug in antd doesn't properly update active tab on page navigation between 2 websites, asign unique `key` per page/website to fix
              // NOTE: Only appears to be an issue here, and not in Organization.js - no clue why
              <Tabs key={websiteId} activekey={activeTabKey} onChange={setActiveTabKey}>
                <Tabs.TabPane tab="BT Spaces" key="spaces" />
                <Tabs.TabPane tab="Settings" key="settings" />
                <Tabs.TabPane tab="Whitelist Rules" key="whitelist-rules" />
                <Tabs.TabPane tab="Readership Link" key="soft-message-wall" />
                <Tabs.TabPane tab="Audit Log" key="audit-log" />
              </Tabs>
            }
          >
            <Descriptions size="small" column={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
              <Descriptions.Item label="Status">
                <Space>
                  <Badge
                    status={isEnabled ? 'success' : 'default'}
                    text={isEnabled ? 'Enabled' : 'Disabled'}
                  />
                  <div>
                    {isMobileEnabled && (
                      <Tag color={isEnabled ? 'processing' : 'default'}>Mobile</Tag>
                    )}
                    {isGamEnabled && <Tag color={isEnabled ? 'warning' : 'default'}>GAM</Tag>}
                  </div>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Hooks">
                <Space>
                  {website?.prod_params?.hook && (
                    <CodeEditorModalStatic
                      triggerRender={({ openModal }) => (
                        <Button type="link" size="small" onClick={openModal}>
                          Production
                        </Button>
                      )}
                      modalTitle={`Production Hook (${website.domain})`}
                      value={website.prod_params.hook}
                      language="js"
                    />
                  )}
                  {website?.stg_params?.hook && (
                    <CodeEditorModalStatic
                      triggerRender={({ openModal }) => (
                        <Button type="link" size="small" onClick={openModal}>
                          Staging
                        </Button>
                      )}
                      modalTitle={`Staging Hook (${website.domain})`}
                      value={website.stg_params.hook}
                      language="js"
                    />
                  )}
                  {website?.prod_params?.hook &&
                  website?.stg_params?.hook &&
                  removeCommentsFromJSCode(website.prod_params.hook) !==
                    removeCommentsFromJSCode(website.stg_params.hook) ? (
                    <CodeDiffViewerModal
                      modalTitle={`Production Hook vs. Staging Hook (${website.domain})`}
                      triggerRender={({ openModal }) => (
                        <Tooltip
                          title="Production & Staging hooks are not the same."
                          overlayStyle={{ maxWidth: 350 }}
                        >
                          <Button type="link" size="small" onClick={openModal}>
                            <ExclamationCircleOutlined />
                          </Button>
                        </Tooltip>
                      )}
                      width={1400}
                      language="js"
                      leftTitle="Production"
                      rightTitle="Staging"
                      diffViewerHeight={600}
                      value={{
                        before: website.prod_params.hook,
                        after: website.stg_params.hook,
                      }}
                    />
                  ) : null}
                </Space>
                {!website?.prod_params?.hook && !website?.stg_params?.hook && 'None'}
              </Descriptions.Item>
              <Descriptions.Item label="Created">
                <Space>
                  <span>{moment(website.created_at).format('MMM D, YYYY [at] LT')}</span>
                  <span>({TIME_ZONE})</span>
                </Space>
              </Descriptions.Item>
              {website?.prod_params?.prebidjs_version && (
                <Descriptions.Item label="Prebid.js Version">
                  {website.prod_params.prebidjs_version}
                </Descriptions.Item>
              )}
            </Descriptions>
          </PageHeader>
          <PageContents>
            {activeTabKey === 'spaces' ? (
              <StaticSpacesTable website={website} isViewOnly={isViewOnly} />
            ) : activeTabKey === 'settings' ? (
              <SettingsTable org={org} website={website} isViewOnly={isViewOnly} />
            ) : activeTabKey === 'whitelist-rules' ? (
              <WhitelistRulesTable org={org} website={website} isViewOnly={isViewOnly} />
            ) : activeTabKey === 'soft-message-wall' ? (
              <WebsiteSoftMessageWallSettings org={org} website={website} isViewOnly={isViewOnly} />
            ) : activeTabKey === 'audit-log' ? (
              <AuditLog websiteId={website.id} />
            ) : null}
          </PageContents>
        </>
      )}
    />
  );
};
export default Website;
