import { Alert, Form } from 'antd';
import React from 'react';
import { CodeEditor } from '../../../components';
import { validateJson } from '../../../services/utils';

const PrebidConfigForm = ({ formInstance, isViewOnly, saveError }) => (
  <Form
    name="settings-prebid-config"
    form={formInstance}
    disabled={isViewOnly}
    preserve={false}
    requiredMark={false}
  >
    <Form.Item
      name="value"
      rules={[
        {
          required: true,
          validator: (rule, value) => validateJson(value),
          message: 'Please enter a valid Prebid config JSON!',
        },
      ]}
    >
      <CodeEditor
        placeholder="Prebid config object"
        height={360}
        disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
      />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const settingConfig = {
  name: 'Prebid Config',
  description: 'A config JSON object that will be set on Prebid.js running on the page.',
  Form: PrebidConfigForm,
  // Transform to and from JS Object <-> JSON string because form must use string object but we want to store the value as an Object
  transformBeforeInit: (prebidConfigObj) => JSON.stringify(prebidConfigObj, null, 2),
  transformBeforeSave: (prebidConfigJSONString) => JSON.parse(prebidConfigJSONString),
  initialSettingValue: {},
};

export default settingConfig;
