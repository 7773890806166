const languages = [
    { code: "DE", name: "German" },
    { code: "EN", name: "English" },
    { code: "ES", name: "Spanish" },
    { code: "FR", name: "French" },
    { code: "IT", name: "Italian" },
    { code: "JA", name: "Japanese" },
    { code: "NL", name: "Dutch" },
    { code: "PT", name: "Portuguese" },
    { code: "RU", name: "Russian" },
    { code: "SV", name: "Swedish" },
    { code: "ZH", name: "Chinese" },
    { code: "zh_TW", name: "Chinese (Taiwan)" },
]

const mostPopularLanguages = ["EN", "ES", "FR", "DE", "IT"];

export {
    languages,
    mostPopularLanguages
}
