import { Input } from 'antd';
import { without } from 'lodash';
import React from 'react';
import styles from './ReportBuilder.module.less';

const ScheduleDays = ({ options, onChange, value: scheduledDays = [] }) => {
  const scheduledStatusByDay = options.reduce(
    (acc, { value: day }) => ({ ...acc, [day]: scheduledDays.includes(day) }),
    {}
  );

  const onClick = (event) => {
    const { value: day, checked } = event.target;
    const isCurrentlyScheduled = scheduledStatusByDay[day];

    let newScheduledDays;
    if (!checked && isCurrentlyScheduled) {
      // Remove unselected day from scheduled days
      newScheduledDays = without(scheduledDays, day);
    } else if (checked && !isCurrentlyScheduled) {
      // Add selected day to scheduled days
      newScheduledDays = [...scheduledDays, day];
    }

    onChange(newScheduledDays);
  };

  return (
    <div className={styles.scheduleDays}>
      {options.map((option, index) => (
        <div key={`input-${index}-${option.value}`}>
          <Input
            type="checkbox"
            name="day"
            value={option.value}
            id={option.value}
            checked={scheduledStatusByDay[option.value]}
            onChange={onClick}
          />
          <label htmlFor={option.value} className={styles.dayLabel}>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default ScheduleDays;
