import { Alert, Button, Layout } from 'antd';
import React from 'react';
import { useSessionUser } from '../services/hooks';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const NAVBAR_HEIGHT_PX = 48;

const Container = ({ children }) => {
  const { email: userEmail, isImpersonatingUser, stopImpersonatingUser } = useSessionUser();

  return (
    <>
      {isImpersonatingUser ? (
        <Alert
          type="info"
          message={`You are currently viewing the dashboard as user ${userEmail}`}
          banner
          action={
            <Button size="small" type="primary" shape="round" ghost onClick={stopImpersonatingUser}>
              Stop
            </Button>
          }
        />
      ) : null}
      <Layout hasSider style={{ minHeight: '100vh' }}>
        <Sidebar />
        <Layout>
          <Navbar height={NAVBAR_HEIGHT_PX} />
          <Layout.Content style={{ minHeight: `calc(100vh - ${NAVBAR_HEIGHT_PX}px)` }}>
            {children}
          </Layout.Content>
        </Layout>
      </Layout>
    </>
  );
};

export default Container;
