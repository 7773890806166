import { languages } from './languages'
import { Form, Input, Select } from 'antd';
import TitleFormItem from '../TitleFormItem';
import { MODE_CUSTOM_CTA } from '../ModeFormItem';
import { useEffect, useState } from 'react';
import defaultTranslations from './translations';

const LanguageInput = ({ env, language, onChange, existKeys, viewMode, formInstance }) => {
    const [translations, setTranslations] = useState(defaultTranslations.en)
    const fieldNames = {
        label: "name", value: "code"
    }

    useEffect(() => {
        const lang = language.toLowerCase();
        const translation = defaultTranslations[lang];
        setTranslations(translation)

        return () => {
            formInstance.setFieldValue([env, 'am_settings', 'i18n', language], undefined);
        }
    }, [])

    useEffect(() => {
        formInstance.setFieldValue([env, 'am_settings', 'i18n', language, 'default_translations'], translations)
    }, [env, language, translations])

    return (
        <>
            <div className='ant-form-item'>
                <div className="ant-row">
                    <div className="ant-col ant-form-item-label">
                        <label>
                            Languages
                        </label>
                    </div>
                    <div className="ant-col ant-form-item-control">
                        <div className="ant-form-item-control-input">
                            <div className="ant-form-item-control-input-content">
                                <Select
                                    showSearch
                                    className="ant-select-in-form-item"
                                    placeholder="Select a language"
                                    optionFilterProp="children"
                                    defaultValue={language}
                                    fieldNames={fieldNames}
                                    onChange={onChange}
                                    filterOption={(input, option) =>
                                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={languages.filter(v => {
                                        return !existKeys?.includes(v.code)
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Form.Item label="Name" name={[env, 'am_settings', 'i18n', language, 'name']}>
                <Input />
            </Form.Item>
            <TitleFormItem env={env} language={language} />
            <Form.Item label={`Landing Body Text`} name={[env, 'am_settings', 'i18n', language, 'landing_text']}>
                <Input.TextArea autoSize />
            </Form.Item>
            <Form.Item label={`Call to action`} name={[env, 'am_settings', 'i18n', language, 'call_to_action']}>
                <Input />
            </Form.Item>
            <Form.Item name={[env, 'am_settings', 'i18n', language, 'default_translations']} style={{height: 0}}>
                <Input hidden />
            </Form.Item>

            {viewMode === MODE_CUSTOM_CTA &&
                <>
                    <p>
                        <b>Custom CTA modal</b>
                    </p>
                    <Form.Item label={`Primary text`} required name={[env, 'am_settings', 'i18n', language, 'custom_cta', 'primary_text']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={`Secondary text`} required name={[env, 'am_settings', 'i18n', language, 'custom_cta', 'secondary_text']}>
                        <Input />
                    </Form.Item>
                </>
            }
        </>
    )
}

export default LanguageInput;
