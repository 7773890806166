import { PlusOutlined } from '@ant-design/icons';
import { Badge, message, Space } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { mutate } from 'swr';
import { SearchableEntityTable } from '../components';
import { PageContents } from '../layout';
import api from '../services/api';
import { BLOCKTHROUGH_ORG_ID, TIME_ZONE } from '../services/constants';
import { SaveEntityModal } from '../services/handlers';
import { compareAlphabetically, compareChronologically } from '../services/utils';
import { OrganizationForm } from './Organization';

const Organizations = () => {
  return (
    <PageContents>
      <SearchableEntityTable
        title="Organizations"
        swrKey="/OrgList"
        swrFetcher={() =>
          // Omit "All Organizations" org (internal Blockthrough org) from table
          api.listOrgs().then((orgs) => orgs.filter(({ id }) => id !== BLOCKTHROUGH_ORG_ID))
        }
        textSearchFieldNames={['name', 'id']}
        actions={
          <SaveEntityModal
            key="add-org"
            buttonProps={{ icon: <PlusOutlined /> }}
            modalTitle="Add Organization"
            // Trim any leading/trailing whitespace from org name
            transformBeforeSave={({ name, ...rest }) => ({ name: name.trim(), ...rest })}
            saveEntity={api.createOrg}
            onSuccess={({ org }) => {
              // Show success message and trigger orgs refresh to reflect new addition
              message.success(`${org.name} successfully created!`);
              mutate('/OrgList');
            }}
            formComponent={OrganizationForm}
            formComponentProps={{ isAddOrg: true }}
          />
        }
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (name, { id }) => <Link to={`/orgs/${id}`}>{name}</Link>,
            width: 260,
          },
          {
            title: 'ID',
            dataIndex: 'id',
            width: 220,
          },
          {
            // TODO: Extract this out to reduce repetition (allow specified dataIndex property (e.g. created_at vs creation_date))
            title: `Created (${TIME_ZONE})`,
            dataIndex: 'creation_date',
            sortDirections: ['descend', 'ascend', 'descend'],
            sorter: (a, b) => compareChronologically(a.creation_date, b.creation_date),
            render: (dateISOString) => {
              const date = moment(dateISOString);
              return (
                <Space>
                  <span style={{ whiteSpace: 'nowrap' }}>{date.format('YYYY-MM-DD')}</span>
                  <span>{date.format('(HH:mm)')}</span>
                </Space>
              );
            },
            width: 240,
          },
          {
            title: `Status`,
            dataIndex: 'disabled',
            sortDirections: ['descend', 'ascend', 'descend'],
            sorter: (a, b, currentSortDirection) => {
              const isAEnabled = !a.disabled;
              const isBEnabled = !b.disabled;
              if (isAEnabled === isBEnabled) {
                // Always sort orgs by name in ascending order when their enabled statuses are the same
                // (So when this column is in descending order we need to reverse the result of the localeCompare function.)
                return (
                  (currentSortDirection === 'descend' ? -1 : 1) *
                  compareAlphabetically(a.name, b.name)
                );
              } else {
                return isAEnabled && !isBEnabled ? -1 : 1; // Enabled orgs shown first when sorted in ascending order
              }
            },
            render: (isDisabled, { id }) => (
              <Badge
                key={id}
                status={!isDisabled ? 'success' : 'default'}
                text={!isDisabled ? 'Enabled' : 'Disabled'}
                style={{ minWidth: 88 }}
              />
            ),
            width: 220,
          },
        ]}
      />
    </PageContents>
  );
};
export default Organizations;
