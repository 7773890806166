import { SearchOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Divider, Empty, Input, message, Select, Space, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { DeleteEntityPopconfirm } from '../../services/handlers';
import { useAsync } from '../../services/hooks';
import styles from './EntityTable.module.less';
import { generateErrorMessage } from '../../services/utils';

/**
 *
 * @param {Object} params
 * @param {Object} [params.selectable]
 * @param {(selectedRowKeys: string[], clearFunction: Function, ...actionsProps) => void} [params.selectable.actions]
 * @param {string} [params.selectable.entityTerm]
 * @param {Object} [params.selectable.actionsProps]
 *
 * @returns
 */
const SearchableTableComponent = ({
  actions,
  columns,
  expandable,
  defaultExpandedRowKeys,
  selectable,
  handleSearch,
  isLoading = false,
  tableData,
  textSearchAutoFocus,
  textSearchPlaceholder,
  rowKey,
  title,
  afterTitle,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const selectedRows = tableData.filter((row) => selectedRowKeys.includes(row[rowKey])); // This will ignore selected row keys whose row is no longer in the table data (i.e. an entity was deleted between selection changes)
  const selectedRowKeysFiltered = selectedRows.map((row) => row[rowKey]);
  // Update selectedRowKeys to what they should be (e.g. if a selected row key's row was removed)
  useEffect(() => {
    if (selectedRowKeys.length > selectedRowKeysFiltered) {
      setSelectedRowKeys(selectedRowKeysFiltered);
    }
  }, [selectedRowKeys, setSelectedRowKeys, selectedRowKeysFiltered]);

  const selectAllRows = () => {
    // Selects all rows of the table (including ones hidden by pagination, if applicable)
    setSelectedRowKeys(tableData.map((row) => row[rowKey]));
  };
  const clearSelection = () => {
    setSelectedRowKeys([]);
  };

  const rowsAreSelected = selectedRows.length > 0;

  return (
    <Card className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.toolbarTitle}>
          <Space size="large">
            {title}
            {afterTitle}
          </Space>
        </div>
        <div className={styles.toolbarActions}>
          <Space>
            {rowsAreSelected
              ? selectable.actions({
                  selectedRows,
                  selectedRowKeys: selectedRowKeysFiltered,
                  clearSelection,
                  ...selectable.actionsProps,
                })
              : actions}
          </Space>
          <Divider type="vertical" />
          <Input
            key="input"
            autoFocus={textSearchAutoFocus}
            placeholder={textSearchPlaceholder}
            className={styles.search}
            onChange={handleSearch}
            suffix={<SearchOutlined />}
          />
        </div>
      </div>
      {rowsAreSelected ? (
        <Alert
          className={styles.selectionBar}
          message={
            <Space size="large">
              <>
                {`${selectedRows.length} ${selectable.entityTerm}${
                  selectedRows.length > 1 ? 's' : ''
                } selected`}
              </>
            </Space>
          }
          type="info"
          action={
            <Space className={styles.selectionActions}>
              {
                // Show "Select all" action unless all of the entities are already selected
                selectedRows.length < tableData.length ? (
                  <Button type="primary" ghost onClick={selectAllRows}>
                    Select all{' '}
                    {`${tableData.length} ${selectable.entityTerm}${
                      tableData.length > 1 ? 's' : ''
                    }`}
                  </Button>
                ) : null
              }
            </Space>
          }
          closable
          closeText="Clear Selection"
          onClose={clearSelection}
        />
      ) : null}
      <Table
        className={styles.table}
        scroll={{ x: true }}
        columns={columns}
        dataSource={tableData}
        loading={{
          spinning: isLoading,
          tip: 'Loading',
        }}
        locale={{
          emptyText: isLoading ? (
            <Empty description={null} image={null} />
          ) : (
            <Empty description={`No ${title} Found`} image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ),
        }}
        pagination={{
          defaultPageSize: 50,
          hideOnSinglePage: true,
          pageSizeOptions: [50, 100, 200],
        }}
        rowKey={rowKey}
        rowSelection={
          selectable
            ? {
                selectedRows,
                selectedRowKeys: selectedRowKeysFiltered,
                onChange: setSelectedRowKeys,
              }
            : null
        }
        expandable={expandable}
        defaultExpandedRowKeys={defaultExpandedRowKeys}
      />
    </Card>
  );
};

export const SearchableEntityTable = ({
  actions,
  columns,
  expandable,
  defaultExpandedRowKeys,
  swrKey,
  swrFetcher,
  rowKey = 'id',
  selectable,
  textSearchAutoFocus = true,
  textSearchFieldNames, // required
  textSearchPlaceholder,
  title,
  afterTitle,
}) => {
  const [searchText, setSearchText] = useState('');
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const { tableData, isLoading } = useFetchedTableData({
    swrKey,
    swrFetcher,
    textSearchFieldNames,
    searchText,
  });

  return (
    <SearchableTableComponent
      actions={actions}
      columns={columns}
      expandable={expandable}
      defaultExpandedRowKeys={defaultExpandedRowKeys}
      selectable={selectable}
      handleSearch={handleSearch}
      isLoading={isLoading}
      rowKey={rowKey}
      tableData={tableData}
      textSearchAutoFocus={textSearchAutoFocus}
      textSearchPlaceholder={textSearchPlaceholder}
      title={title}
      afterTitle={afterTitle}
    />
  );
};

export const SearchableEntityTableStatic = ({
  actions,
  columns,
  expandable,
  defaultExpandedRowKeys,
  isLoading,
  rowKey = 'id',
  selectable,
  staticTableData,
  textSearchAutoFocus = true,
  textSearchFieldNames, // required
  textSearchPlaceholder,
  title,
  afterTitle,
}) => {
  const [searchText, setSearchText] = useState('');
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const { tableData } = useStaticTableData({
    textSearchFieldNames,
    searchText,
    staticTableData,
  });

  return (
    <SearchableTableComponent
      actions={actions}
      columns={columns}
      expandable={expandable}
      defaultExpandedRowKeys={defaultExpandedRowKeys}
      selectable={selectable}
      handleSearch={handleSearch}
      isLoading={isLoading}
      rowKey={rowKey}
      tableData={tableData}
      textSearchAutoFocus={textSearchAutoFocus}
      textSearchPlaceholder={textSearchPlaceholder}
      title={title}
      afterTitle={afterTitle}
    />
  );
};

const AddRemoveEntityTableComponent = ({
  actions,
  columns,
  expandable,
  selectable,
  handleAdd,
  handleRemove,
  onUpdated,
  isLoading = false,
  addInputAutoFocus,
  addableOptions = [],
  addedEntities = [],
  addInputPlaceholder,
  rowKey,
  title,
  afterTitle,
  isViewOnly,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const selectedRows = addedEntities.filter((entityRow) =>
    selectedRowKeys.includes(entityRow[rowKey])
  ); // This will ignore selected row keys whose row is no longer in the addedEntities (i.e. an entity was deleted between selection changes)
  const selectedRowKeysFiltered = selectedRows.map((row) => row[rowKey]);
  // Update selectedRowKeys to what they should be (e.g. if a selected row key's row was removed)
  useEffect(() => {
    if (selectedRowKeys.length > selectedRowKeysFiltered) {
      setSelectedRowKeys(selectedRowKeysFiltered);
    }
  }, [selectedRowKeys, setSelectedRowKeys, selectedRowKeysFiltered]);

  const selectAllRows = () => {
    // Selects all rows of the table (including ones hidden by pagination, if applicable)
    setSelectedRowKeys(addedEntities.map((row) => row[rowKey]));
  };
  const clearSelection = () => {
    setSelectedRowKeys([]);
  };

  const rowsAreSelected = selectedRows.length > 0;

  return (
    <Card className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.toolbarTitle}>
          <Space size="large">
            {title}
            {afterTitle}
          </Space>
        </div>

        <div className={styles.toolbarActions}>
          <Space>
            {rowsAreSelected
              ? selectable.actions({
                  selectedRows,
                  selectedRowKeys: selectedRowKeysFiltered,
                  clearSelection,
                  ...selectable.actionsProps,
                })
              : actions}
          </Space>
          <Divider type="vertical" />
          {isViewOnly ? null : (
            <Select
              autoFocus={addInputAutoFocus}
              className={styles.search}
              disabled={addableOptions.length === 0}
              placeholder={addInputPlaceholder}
              showSearch
              optionFilterProp="label"
              onChange={(addedKey) =>
                handleAdd(addedKey)
                  .then(onUpdated)
                  .catch((errorMessage) => {
                    message.error(`Add failed. ${generateErrorMessage(errorMessage)}`);
                  })
              }
              value={null}
              options={addableOptions}
            />
          )}
        </div>
      </div>
      {rowsAreSelected ? (
        <Alert
          className={styles.selectionBar}
          message={
            <Space size="large">
              <>
                {`${selectedRows.length} ${selectable.entityTerm}${
                  selectedRows.length > 1 ? 's' : ''
                } selected`}
              </>
            </Space>
          }
          type="info"
          action={
            <Space className={styles.selectionActions}>
              {
                // Show "Select all" action unless all of the added entities are already selected
                selectedRows.length < addedEntities.length ? (
                  <Button type="primary" ghost onClick={selectAllRows}>
                    Select all{' '}
                    {`${addedEntities.length} ${selectable.entityTerm}${
                      addedEntities.length > 1 ? 's' : ''
                    }`}
                  </Button>
                ) : null
              }
            </Space>
          }
          closable
          closeText="Clear Selection"
          onClose={clearSelection}
        />
      ) : null}
      <Table
        className={styles.table}
        columns={[
          ...columns,
          {
            key: 'remove',
            hidden: isViewOnly,
            render: (_, entity) => (
              <DeleteEntityPopconfirm
                isDisabled={isLoading}
                prompt="Are you sure you want to remove this?"
                deleteEntity={() =>
                  handleRemove(entity.key)
                    .then(onUpdated)
                    .catch((errorMessage) => {
                      message.error(`Removal failed. ${generateErrorMessage(errorMessage)}`);
                    })
                }
              />
            ),
            align: 'right',
            width: 42,
          },
        ].filter((column) => !column.hidden)}
        dataSource={addedEntities}
        loading={{
          spinning: isLoading,
          tip: 'Loading',
        }}
        locale={{
          emptyText: isLoading ? (
            <Empty description={null} image={null} />
          ) : (
            <Empty description={`No ${title} Found`} image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ),
        }}
        pagination={{
          defaultPageSize: 50,
          hideOnSinglePage: true,
          pageSizeOptions: [50, 100, 200],
        }}
        rowKey={rowKey}
        rowSelection={
          selectable
            ? {
                selectedRows,
                selectedRowKeys: selectedRowKeysFiltered,
                onChange: setSelectedRowKeys,
              }
            : null
        }
        expandable={expandable}
      />
    </Card>
  );
};

export const AddRemoveEntityTable = ({
  actions,
  columns,
  expandable,
  selectable,
  rowKey = 'id',
  addInputAutoFocus = false,
  addEntity,
  removeEntity,
  onUpdated,
  addedEntities = [],
  addableEntities = [],
  addInputPlaceholder,
  title,
  afterTitle,
  isViewOnly,
}) => {
  const { execute: handleAdd, status: addStatus } = useAsync(addEntity);
  const { execute: handleRemove, status: removeStatus } = useAsync(removeEntity);
  const isLoading = addStatus === 'pending' || removeStatus === 'pending';

  const addableOptions = addableEntities.map(({ key, name }) => ({ value: key, label: name }));

  return (
    <AddRemoveEntityTableComponent
      actions={actions}
      columns={columns}
      expandable={expandable}
      selectable={selectable}
      handleAdd={handleAdd}
      handleRemove={handleRemove}
      onUpdated={onUpdated}
      isLoading={isLoading}
      rowKey={rowKey}
      addableOptions={addableOptions}
      addedEntities={addedEntities}
      addInputAutoFocus={addInputAutoFocus}
      addInputPlaceholder={addInputPlaceholder}
      title={title}
      afterTitle={afterTitle}
      isViewOnly={isViewOnly}
    />
  );
};

// From: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions under "Escaping"
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function useFetchedTableData({ swrKey, swrFetcher, textSearchFieldNames, searchText }) {
  const { data, error } = useSWR(swrKey, swrFetcher);
  const isLoading = !error && !data;

  const filteredData = useMemo(() => {
    if (data) {
      const searchTextRegex = RegExp(escapeRegExp(searchText), 'i'); // Escape searchText to avoid invalid RegEx expressions (e.g. if user types '[' as part of search query)
      return data.filter((item) =>
        textSearchFieldNames.some(
          // TODO: Add support for searchFieldName path (supplied in Array)
          (searchFieldName) => item[searchFieldName] && searchTextRegex.test(item[searchFieldName])
        )
      );
    } else {
      return [];
    }
  }, [data, textSearchFieldNames, searchText]);

  return {
    tableData: filteredData,
    isLoading,
    error,
  };
}

function useStaticTableData({ staticTableData, textSearchFieldNames, searchText }) {
  const filteredData = useMemo(() => {
    if (staticTableData) {
      const searchTextRegex = RegExp(escapeRegExp(searchText), 'i'); // Escape searchText to avoid invalid RegEx expressions (e.g. if user types '[' as part of search query)
      return staticTableData.filter((item) =>
        textSearchFieldNames.some(
          // TODO: Add support for searchFieldName path (supplied in Array)
          (searchFieldName) => item[searchFieldName] && searchTextRegex.test(item[searchFieldName])
        )
      );
    } else {
      return [];
    }
  }, [staticTableData, textSearchFieldNames, searchText]);

  return {
    tableData: filteredData,
  };
}
