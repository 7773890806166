import { DownloadOutlined, ExclamationCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import useSWR from 'swr';
import api from '../../../services/api';
import { TIME_ZONE } from '../../../services/constants';
import { compareAlphabetically, compareChronologically } from '../../../services/utils';
import {
  JOB_RUN_STATUSES,
  JOB_RUN_STATUS_ICONS,
  JOB_RUN_STATUS_TAG_COLOURS,
  JOB_RUN_TYPE_COLOURS,
  JOB_RUN_TYPE_LABELS,
  JOB_SCHEDULES,
  LINEITEM_ACTIONS,
  LINEITEM_TYPES,
  LINEITEM_TYPES_OPTIONS,
  STATUS_TAG_LABELS,
} from './lineItemJobConstants';

const renderValidDate = (timestamp) => {
  const date = moment(timestamp);
  return (
    <Space>
      {!date.isValid() || timestamp === '0001-01-01T00:00:00Z' ? (
        '-'
      ) : (
        <>
          <span style={{ whiteSpace: 'nowrap' }}>{date.format('YYYY-MM-DD')}</span>
          <span>{date.format('(HH:mm)')}</span>
        </>
      )}
    </Space>
  );
};

const LineItemJobRuns = ({ setShowJobRuns, job }) => {
  const lineItemJobRunSwrKey = job ? ['/LineItemJobRunListGet', job.id] : null;
  const { data: jobRuns, error } = useSWR(lineItemJobRunSwrKey, () =>
    api.listLineItemJobRuns({ job_id: job.id })
  );

  const isLoading = !error && !jobRuns;

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Alert message="Note: This page displays up to the last 48 jobs run." type="info" showIcon />
      <Card>
        <Space>
          <Button
            style={{ padding: '0' }}
            size="large"
            type="link"
            onClick={() => setShowJobRuns(false)}
          >
            Exclusions
          </Button>
          <RightOutlined style={{ marginRight: '8px' }} />
          <Typography.Text style={{ fontSize: '16px' }}>
            {`${job.name} ${
              job.schedule === JOB_SCHEDULES.JOB_SCHEDULE_OFF ? 'Manual' : 'Scheduled'
            } Line Item Jobs Run`}
          </Typography.Text>
        </Space>
        <Table
          rowKey="id"
          loading={{
            spinning: isLoading,
            tip: 'Loading',
          }}
          dataSource={jobRuns?.slice(0, 48)}
          columns={[
            {
              title: 'Job ID',
              dataIndex: 'id',
              render: (id) => {
                const shortenedID = id.split('.')[1]?.split('+')[0];
                return <Tooltip title={id}>{shortenedID}</Tooltip>;
              },
            },
            {
              title: 'Type',
              dataIndex: 'run_type',
              render: (run_type) => (
                <Tag color={JOB_RUN_TYPE_COLOURS[run_type] || 'default'}>
                  {JOB_RUN_TYPE_LABELS[run_type] || 'Unavailable'}
                </Tag>
              ),
              width: 70,
            },
            {
              title: 'Filter Criteria',
              dataIndex: 'line_item_actions',
              render: (line_item_actions) => {
                const { excluded, reverted } = LINEITEM_TYPES.reduce(
                  (excludedAndRevertedLineItemTypes, lineItem) => {
                    if (
                      line_item_actions[lineItem] === LINEITEM_ACTIONS.LINEITEM_ACTION_EXCLUSION
                    ) {
                      excludedAndRevertedLineItemTypes.excluded.push(
                        LINEITEM_TYPES_OPTIONS.find((type) => type.value === lineItem).label
                      );
                    } else if (
                      line_item_actions[lineItem] ===
                      LINEITEM_ACTIONS.LINEITEM_ACTION_REVERT_EXCLUSION
                    ) {
                      excludedAndRevertedLineItemTypes.reverted.push(
                        LINEITEM_TYPES_OPTIONS.find((type) => type.value === lineItem).label
                      );
                    }
                    return excludedAndRevertedLineItemTypes;
                  },
                  { excluded: [], reverted: [] }
                );
                return (
                  <Space direction="vertical">
                    {excluded.length ? (
                      <span>
                        <Typography.Text strong>Excluded:</Typography.Text> {excluded.join(', ')}
                      </span>
                    ) : null}
                    {reverted.length ? (
                      <span>
                        <Typography.Text strong>Reverted:</Typography.Text> {reverted.join(', ')}
                      </span>
                    ) : null}
                  </Space>
                );
              },
            },
            {
              title: 'Summary',
              dataIndex: 'summary',
              render: (summary, { run_status }) => {
                const { excluded, reverted, skipped, errored } = summary;
                const successfulItems = `${excluded + reverted + skipped} successful`;
                const erroredItems = `, ${errored} errorred`;
                const summaryMessage =
                  run_status === JOB_RUN_STATUSES.JOB_RUN_STATUS_FAIL
                    ? 'An error has occurred with this job run.'
                    : `${successfulItems} ${errored ? erroredItems : ''} line items`;

                const detailedSummary = Object.keys(summary).map((key) => (
                  <div key={key}>{`${capitalize(key)}: ${summary[key]}`}</div>
                ));

                return (
                  <Space>
                    {run_status === JOB_RUN_STATUSES.JOB_RUN_STATUS_RUNNING ? (
                      '-'
                    ) : (
                      <Tooltip title={detailedSummary}>{summaryMessage}</Tooltip>
                    )}
                  </Space>
                );
              },
            },

            {
              title: 'Status',
              dataIndex: 'run_status',
              sortDirections: ['ascend', 'descend', 'ascend'],
              sorter: (a, b) => compareAlphabetically(a.run_status, b.run_status),
              render: (status) => (
                <div style={{ textAlign: 'center', color: JOB_RUN_STATUS_TAG_COLOURS[status] }}>
                  <Tooltip title={STATUS_TAG_LABELS[status]}>
                    {JOB_RUN_STATUS_ICONS[status]}
                  </Tooltip>
                </div>
              ),
            },
            {
              title: `Executed At (${TIME_ZONE})`,
              dataIndex: 'executed_at',
              defaultSortOrder: 'descend',
              sortDirections: ['ascend', 'descend', 'ascend'],
              sorter: (a, b) => compareChronologically(a.executed_at, b.executed_at),
              render: (executed_at) => renderValidDate(executed_at),
            },
            {
              title: `Finished At (${TIME_ZONE})`,
              dataIndex: 'finished_at',
              sortDirections: ['ascend', 'descend', 'ascend'],
              sorter: (a, b) => compareChronologically(a.finished_at, b.finished_at),
              render: (finished_at) => renderValidDate(finished_at),
            },
            {
              title: 'Action',
              dataIndex: 'report_url',
              render: (report_url, { run_status }) => {
                const jobIsRunning = run_status === JOB_RUN_STATUSES.JOB_RUN_STATUS_RUNNING;
                const tooltipTitle = jobIsRunning
                  ? 'Generating report...'
                  : 'There was an error generating this report';

                return report_url ? (
                  <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    href={`${process.env.REACT_APP_DASH_API_URL}/${report_url}`}
                  />
                ) : (
                  <Tooltip title={tooltipTitle}>
                    {!jobIsRunning ? <ExclamationCircleOutlined style={{ color: 'red' }} /> : '-'}
                  </Tooltip>
                );
              },
              align: 'center',
            },
          ]}
        />
      </Card>
    </Space>
  );
};

export default LineItemJobRuns;
