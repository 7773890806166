import { Form } from 'antd';
import React from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-clike'; // required to load prism-javascript
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
import './CodeEditor.css';
/**
 * NOTE: Must accept params `value` and `onChange` to work as an Ant Design form input
 * (`language` can be either: 'json', 'js', or 'css')
 */
const CodeEditor = ({
  value = '',
  onChange,
  language = 'json',
  height = 600,
  overflowY = 'auto',
  ...editorProps
}) => {
  const { status } = Form.Item.useStatus();

  const highlightWithLineNumbers = (value, language) =>
    highlight(value, language)
      .split('\n')
      .map((line, i) => `<span class="CodeEditorLineNumber">${i + 1}</span>${line}`)
      .join('\n');

  return (
    <div style={{ height, overflowY }}>
      <Editor
        value={value}
        onValueChange={onChange}
        highlight={(value) => highlightWithLineNumbers(value, languages[language])}
        padding={10}
        textareaClassName="CodeEditor-textarea"
        className={`CodeEditor code-editor-status-${status}`}
        style={{
          backgroundColor: '#f0f0f0',
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
          minHeight: height,
          border: '1px solid #d9d9d9',
        }}
        {...editorProps}
      />
    </div>
  );
};

export default CodeEditor;
