import { PlusOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Form,
  Input,
  message,
  PageHeader,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import {
  OnceLoaded,
  ScopePageByOrg,
  ScopePageForm,
  SearchableEntityTable,
  ShortenedItemsWithTooltip,
  useScopePageByOrg,
} from '../components';
import { PageContents } from '../layout';
import api from '../services/api';
import { BLOCKTHROUGH_ORG_ID, TIME_ZONE } from '../services/constants';
import { confirmDelete, SaveEntityModal } from '../services/handlers';
import { useAsync, useSessionUser } from '../services/hooks';
import {
  compareAlphabetically,
  compareChronologically,
  sortAlphabetically,
  sortAlphabeticallyWithBTOrgFirst,
} from '../services/utils';

const ROLE_NAME_STRINGS = {
  none: 'None',
  sysadmin: 'System Admin',
  admin: 'Admin',
  orgadmin: 'Org Admin',
  orguser: 'Org Viewer',
  websiteuser: 'Website Viewer',
};

const ROLE_TAG_COLOURS = {
  sysadmin: 'green',
  admin: 'cyan',
  orgadmin: 'blue',
  orguser: 'orange',
  none: 'default',
};

const UserRoleTag = ({ role, tooltip }) => {
  const tagElement = (
    <Tag color={ROLE_TAG_COLOURS[role] || 'default'}>{ROLE_NAME_STRINGS[role] || role}</Tag>
  );

  return tooltip ? (
    <Tooltip title={tooltip} overlayStyle={{ maxWidth: 265 }}>
      {tagElement}
    </Tooltip>
  ) : (
    tagElement
  );
};

// Used for sorting users by role priority
const compareByUserRole = (userA, userB, orgId) => {
  const rolesByPriority = ['sysadmin', 'admin', 'orgadmin', 'orguser', 'websiteuser'];

  const getUserPermissionForOrgId = (user, orgId) =>
    user.permissions.find(({ id: orgIds }) => orgIds.includes(orgId));

  const roleA = getUserPermissionForOrgId(userA, orgId)?.role_name;
  const roleB = getUserPermissionForOrgId(userB, orgId)?.role_name;

  if (rolesByPriority.includes(roleA) && !rolesByPriority.includes(roleB)) {
    return 1;
  } else if (rolesByPriority.includes(roleB) && !rolesByPriority.includes(roleA)) {
    return -1;
  } else if (rolesByPriority.indexOf(roleA) < rolesByPriority.indexOf(roleB)) {
    return 1;
  } else if (rolesByPriority.indexOf(roleB) < rolesByPriority.indexOf(roleA)) {
    return -1;
  } else {
    return 0;
  }
};

// Returns text which outlines which orgs a particular user is assigned to/has permissions for (based on that user's role)
function getUserOrgsPermissionText({ userRole = '', userOrgIds = [], orgNamesById }) {
  // Return null if `orgNamesById` is not provided or if user has no org associations with `userRole`
  if (!orgNamesById || userOrgIds.length === 0) {
    return null;
  }

  if (userOrgIds.includes(BLOCKTHROUGH_ORG_ID)) {
    // Internal users
    switch (userRole) {
      case 'sysadmin':
        return 'A Blockthrough employee who has view & edit access to all orgs';
      case 'admin':
        return 'An Eyeo/AAX employee who has view access to all orgs';
      default:
        return '';
    }
  } else {
    // TODO: Add links to each org's Org Details page?
    return `Has ${ROLE_NAME_STRINGS[userRole] || userRole} access to these orgs: ${userOrgIds
      .map((orgId) => orgNamesById?.[orgId] || orgId)
      .sort(compareAlphabetically)
      .join(', ')}`;
  }
}

const getRoleOptions = ({ limitRolesTo, labelProp = 'label' } = {}) => {
  const internalRoles = [
    { value: 'sysadmin', [labelProp]: ROLE_NAME_STRINGS.sysadmin },
    { value: 'admin', [labelProp]: ROLE_NAME_STRINGS.admin },
  ];
  const externalRoles = [
    { value: 'orgadmin', [labelProp]: ROLE_NAME_STRINGS.orgadmin },
    { value: 'orguser', [labelProp]: ROLE_NAME_STRINGS.orguser },
    // { value: 'websiteuser', [labelProp]: ROLE_NAME_STRINGS.websiteuser }
  ];

  if (limitRolesTo === 'internal') {
    return internalRoles;
  } else if (limitRolesTo === 'external') {
    return externalRoles;
  } else {
    return [...internalRoles, ...externalRoles];
  }
};

const getOrgIdsForUser = (permissions = []) => {
  let orgIds = [];
  for (const permission of permissions) {
    orgIds = [...orgIds, ...permission.id];
  }
  return orgIds;
};

export const isUserInternal = (user) => {
  const orgIds = getOrgIdsForUser(user.permissions);
  return orgIds.includes(BLOCKTHROUGH_ORG_ID);
};

// Given a set of orgs, produces a sorted list of options ready to be plugged into a <Select> component's `options` prop
const getOrgSelectOptions = (orgs = []) => {
  const sortedOrgs = sortAlphabetically(orgs, 'name');
  return sortedOrgs?.map((org) => ({ label: org.name, value: org.id }));
};

const AddUserForm = ({ formInstance, saveError, loggedInUserIsSystemAdmin, orgSelectOptions }) => {
  // Only System Admins can create/invite internal users
  const roleOptions = getRoleOptions({
    limitRolesTo: !loggedInUserIsSystemAdmin ? 'external' : null,
  });

  // Remove "All Organizations" org (the internal Blockthrough org) as an option to assign to a new non-admin user.
  const orgSelectOptionsForNewExternalUser = useMemo(
    () => orgSelectOptions.filter(({ value: orgId }) => orgId !== BLOCKTHROUGH_ORG_ID),
    [orgSelectOptions]
  );

  // const websiteOptions = []; // TODO: Support assigned websites once the back end supports it

  return (
    <Form
      name="add-user"
      form={formInstance}
      preserve={false}
      requiredMark={false}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Form.Item
        name="email"
        label="User Email"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: 'Please input an email address!' },
          { type: 'email', message: 'Please input a valid email address!' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="role"
        label="Role"
        rules={[
          {
            required: true,
            message: 'Please select a Role!',
          },
        ]}
      >
        <Select options={roleOptions} />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) => prevValues.role !== curValues.role} // NOTE: This needs to be removed in favour of `Form.useWatch` if this form is ever used to edit existing users!
      >
        {({ getFieldValue }) => {
          const role = getFieldValue('role');

          if (role === 'sysadmin' || role === 'admin') {
            return (
              <Form.Item label="Organization">
                <Typography.Text type="secondary">All Organizations</Typography.Text>
              </Form.Item>
            );
          } else if (role) {
            return (
              <Form.Item
                name="orgId"
                label="Organization"
                rules={[
                  {
                    required: true,
                    message: 'Please select an Organization!',
                  },
                ]}
              >
                <Select
                  options={orgSelectOptionsForNewExternalUser}
                  showSearch
                  filterOption={(input, option) =>
                    // Allow organizations to be searched by org name or org id
                    option.label.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
                    option.value.indexOf(input) > -1
                  }
                  loading={orgSelectOptionsForNewExternalUser.length === 0}
                />
              </Form.Item>
            );
          } else {
            return null;
          }
        }}
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues.role !== curValues.role || prevValues.orgId !== curValues.orgId
        }
      >
        {({ getFieldValue }) => {
          const role = getFieldValue('role');
          const orgId = getFieldValue('orgId');

          if (role && orgId) {
            return (
              <Form.Item label="Website">
                <span>All Websites</span>
              </Form.Item>
            );
          } else {
            return null;
          }
        }}
      </Form.Item>

      {saveError && <Alert message={saveError.message} type="error" showIcon />}
    </Form>
  );
};

const EditUserForm = ({ formInstance, saveError, user, userRolesByOrgId, orgSelectOptions }) => {
  const selectedOrgId = Form.useWatch('orgId', formInstance);

  const currentRoleForSelectedOrg = userRolesByOrgId[selectedOrgId];

  return (
    <Form
      name="edit-user"
      form={formInstance}
      preserve={false}
      requiredMark={false}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Form.Item label="User Email">
        <span>{user.email}</span>
      </Form.Item>
      <Form.Item name="orgId" label="Organization">
        <Select
          options={orgSelectOptions}
          onChange={() =>
            // Clear previously selected "New Role" (if applicable) when a new organization is selected
            formInstance.setFieldsValue({ newRole: null })
          }
        />
      </Form.Item>
      <Form.Item label="Current Role">
        <UserRoleTag role={currentRoleForSelectedOrg} />
      </Form.Item>
      <Form.Item
        name="newRole"
        label="New Role"
        rules={[
          {
            required: true,
            message: 'Please select a New Role to assign to the user!',
          },
        ]}
      >
        <Select
          options={getRoleOptions({
            limitRolesTo: selectedOrgId === BLOCKTHROUGH_ORG_ID ? 'internal' : 'external',
          }).filter(({ value: role }) => role !== currentRoleForSelectedOrg)}
        />
      </Form.Item>
      {saveError && <Alert message={saveError.message} type="error" showIcon />}
    </Form>
  );
};

const EditUser = ({ user, orgNamesById, refetchUsers }) => {
  const userRolesByOrgId = user.permissions.reduce((obj, permission) => {
    permission.id.forEach((orgId) => (obj[orgId] = permission.role_name));
    return obj;
  }, {});

  const orgsUserHasAccessTo = Object.keys(userRolesByOrgId).map((orgId) => ({
    id: orgId,
    name: orgNamesById?.[orgId],
  }));

  const orgSelectOptions = getOrgSelectOptions(orgsUserHasAccessTo); // only id & name are required for `getOrgSelectOptions` to do its job

  const editUserRole = ({ orgId, newRole }) => {
    const currentRole = userRolesByOrgId[orgId];
    return api
      .addUserToOrg({ org_id: orgId, role: newRole, user_id: user.id })
      .then(() => api.removeUserFromOrg({ org_id: orgId, role: currentRole, user_id: user.id }));
  };

  return (
    <div>
      <SaveEntityModal
        key="edit-user"
        triggerRender={({ openModal }) => (
          <Button type="link" onClick={openModal}>
            Edit
          </Button>
        )}
        modalTitle="Edit User Role"
        saveEntity={editUserRole}
        onSuccess={() => {
          message.success('User Role successfully updated!');
          refetchUsers(); // Trigger users refresh to reflect edited user's role in table
        }}
        formComponent={EditUserForm}
        formComponentProps={{ user, userRolesByOrgId, orgSelectOptions }}
        formInitialValues={{
          // setting initial value of organization to the first org the user has access to
          orgId: orgSelectOptions[0]?.value,
        }}
      />
    </div>
  );
};

// Component used to "hard delete" users (if the logged-in user is a System Admin)
const DeleteUser = ({ user, refetchUsers }) => {
  // TODO: Consider making this generic and adding it to `services/handlers.js` as `DeleteEntityModal`
  const { execute: deleteUser } = useAsync(() => api.deleteUser(user.id));

  return (
    <Button
      onClick={() => {
        confirmDelete({
          deleteEntity: deleteUser,
          onDeleted: () => {
            message.success(`${user.email} successfully deleted!`);
            refetchUsers(); // Trigger users refresh to reflect deletion
          },
          prompt: 'Are you sure you want to delete this user?',
        });
      }}
      danger
      type="link"
    >
      Delete
    </Button>
  );
};

// Component used to remove users from an organization (used if the logged-in user is NOT a System Admin)
const RemoveUserFromOrg = ({ user, orgId, refetchUsers }) => {
  // Assume that a user only has 1 role assigned to them for a particular org
  const userRole = user.permissions?.find(({ id: orgIds }) => orgIds.includes(orgId))?.role_name;

  // NOTE: This API call will fail if the `userRole` could not be parsed!
  const { execute: removeUser } = useAsync(() =>
    api.removeUserFromOrg({ user_id: user.id, org_id: orgId, role: userRole })
  );

  return (
    <Button
      onClick={() => {
        confirmDelete({
          deleteEntity: removeUser,
          onDeleted: () => {
            message.success(`Successfully removed ${user.email} from the organization!`);
            refetchUsers(); // Trigger users refresh to reflect deletion
          },
          prompt: `Are you sure you want to remove ${user.email} from the organization?`,
        });
      }}
      danger
      type="link"
    >
      Remove
    </Button>
  );
};

const UsersExternal = () => {
  const { id: loggedInUserId } = useSessionUser();

  const { scopedOrg, orgSelectProps, orgsError } = useScopePageByOrg({
    limitToOrgAdminAccess: true,
  });

  // External users can only create other non-admin users by inviting them to an org
  const createUser = ({ email, role, orgId /*, websites */ }) =>
    api.inviteNewUserToOrg({
      email,
      role_name: role,
      org_id: orgId /*, websites */,
    });

  // External users can only fetch the users which are associated with a single org (whichever of the orgs the user has access to and which the page is scoped to)
  const fetchUsersProps = {
    swrKey: scopedOrg ? ['/OrgListUsers', scopedOrg.id] : null,
    swrFetcher: () => api.listOrgUsers({ org_id: parseInt(scopedOrg.id) }),
  };

  const refetchUsers = () => mutate(fetchUsersProps.swrKey);

  return (
    <>
      <PageHeader
        key="header"
        ghost={false}
        title="Users"
        extra={
          <ScopePageForm>
            <ScopePageByOrg scopedOrg={scopedOrg} orgSelectProps={orgSelectProps} />
          </ScopePageForm>
        }
      />
      <PageContents>
        <OnceLoaded
          error={orgsError}
          isLoading={!scopedOrg} // We always need an org to scope the External Users page to
          render={() => (
            <SearchableEntityTable
              title={`${scopedOrg.name} Users`}
              {...fetchUsersProps}
              textSearchFieldNames={['email']}
              textSearchPlaceholder="Search users by email..."
              actions={
                <SaveEntityModal
                  key="add-user"
                  modalTitle="Add User"
                  buttonText="Add New User"
                  buttonProps={{ icon: <PlusOutlined /> }}
                  saveEntity={createUser}
                  onSuccess={({ invite }) => {
                    // Show success message & trigger user refresh to reflect new addition
                    // (E-mail invite is sent to new external user added to org)
                    message.success(`An e-mail invite has been sent to ${invite.user_email}!`);
                    refetchUsers();
                  }}
                  formComponent={AddUserForm}
                  formComponentProps={{ orgSelectOptions: orgSelectProps.options }}
                  formInitialValues={{ orgId: scopedOrg.id }} // set initial value of org dropdown to the org the page is scoped to
                />
              }
              columns={[
                {
                  title: 'Email',
                  dataIndex: 'email',
                  defaultSortOrder: 'ascend',
                  sortDirections: ['ascend', 'descend', 'ascend'],
                  sorter: (a, b) => a.email.localeCompare(b.email),
                  width: 280,
                },
                {
                  title: `Created (${TIME_ZONE})`,
                  dataIndex: 'creation_date',
                  sortDirections: ['descend', 'ascend', 'descend'],
                  sorter: (a, b) => compareChronologically(a.creation_date, b.creation_date),
                  render: (dateISOString) => {
                    const date = moment(dateISOString);
                    return (
                      <Space>
                        <span style={{ whiteSpace: 'nowrap' }}>{date.format('YYYY-MM-DD')}</span>
                        <span>{date.format('(HH:mm)')}</span>
                      </Space>
                    );
                  },
                  width: 240,
                },
                {
                  title: 'Role',
                  sortDirections: ['descend', 'ascend', 'descend'],
                  sorter: (a, b) => compareByUserRole(a, b, scopedOrg.id),
                  filters: getRoleOptions({ limitRolesTo: 'external', labelProp: 'text' }),
                  onFilter: (value, user) =>
                    user.permissions.some(({ role_name }) => role_name === value),
                  render: ({ permissions }) => {
                    // Determine the user's role for the org the page is scoped to
                    const { role_name: userRole } = permissions.find(({ id: userOrgIds }) =>
                      userOrgIds.includes(scopedOrg.id)
                    );

                    return (
                      <Space direction="vertical">
                        <UserRoleTag role={userRole} />
                      </Space>
                    );
                  },
                  width: 92,
                },
                {
                  key: 'actions',
                  render: (_, user) =>
                    // Only show the "Delete"/"Remove" button for user rows which don't correspond to the logged-in user
                    user.id !== loggedInUserId ? (
                      // Display "Remove" button (which allows one to remove a user from an organization) for non-admin (i.e. external) users (namely orgadmin users)
                      <RemoveUserFromOrg
                        user={user}
                        orgId={scopedOrg.id}
                        refetchUsers={refetchUsers}
                      />
                    ) : null,
                  align: 'right',
                  width: 92,
                },
              ]}
            />
          )}
        />
      </PageContents>
    </>
  );
};

const UsersInternal = () => {
  // NOTE: Only internal (i.e Admin & System Admin) users can view this version of the page, and only System Admin users have edit permissions
  const { id: loggedInUserId, isSystemAdmin } = useSessionUser();
  const isViewOnly = !isSystemAdmin;

  // Fetch orgs data
  const { data: orgs, error: orgsError } = useSWR('/OrgList'); // TODO: Handle `orgsError`
  const orgNamesById = useMemo(
    () =>
      orgs?.reduce(
        (orgNamesById, org) => ({
          ...orgNamesById,
          [org.id]: org.name,
        }),
        {}
      ),
    [orgs]
  );
  const orgIdsByName = useMemo(
    () =>
      orgs?.reduce(
        (orgIdsByName, org) => ({
          ...orgIdsByName,
          [org.name]: org.id,
        }),
        {}
      ),
    [orgs]
  );
  const orgSelectOptions = useMemo(() => getOrgSelectOptions(orgs), [orgs]); // (For use in the Add User form)

  // Fetch list of orgs to include in Organization column filter
  const { data: users } = useSWR('/UserList');

  const orgNamesAssignedToUsers = [
    ...new Set(
      users
        ?.flatMap((user) => getOrgIdsForUser(user.permissions))
        .map((orgId) => orgNamesById[orgId] || orgId)
    ),
  ];
  const orgFilters = sortAlphabeticallyWithBTOrgFirst(
    orgNamesAssignedToUsers.map((orgName) => ({ id: orgIdsByName[orgName], name: orgName }))
  ).map(({ id, name }) => ({
    text: name,
    value: id,
  }));

  const createUser = ({ email, role, orgId /*, websites */ }) => {
    return api.inviteNewUserToOrg({
      email,
      role_name: role,
      org_id: role === 'sysadmin' || role === 'admin' ? BLOCKTHROUGH_ORG_ID : orgId /*, websites */,
    });
  };

  const refetchUsers = () => mutate('/UserList');

  return (
    <PageContents>
      <SearchableEntityTable
        title="Users"
        swrKey={'/UserList'}
        textSearchFieldNames={['email']}
        textSearchPlaceholder="Search users by email..."
        actions={
          isViewOnly ? null : (
            <SaveEntityModal
              key="add-user"
              modalTitle="Add User"
              buttonText="Add New User"
              buttonProps={{ icon: <PlusOutlined /> }}
              saveEntity={createUser}
              onSuccess={({ user, invite }) => {
                // Show success message & trigger user refresh to reflect new addition
                if (user) {
                  // Created new "Super Admin" Blockthrough user
                  message.success(`${user.email} successfully created!`);
                } else if (invite) {
                  // Sent e-mail invite for external user newly added to org
                  message.success(`An e-mail invite has been sent to ${invite.user_email}!`);
                }
                refetchUsers();
              }}
              formComponent={AddUserForm}
              formComponentProps={{
                loggedInUserIsSystemAdmin: true,
                orgSelectOptions,
              }}
            />
          )
        }
        columns={[
          {
            title: 'Email',
            dataIndex: 'email',
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.email.localeCompare(b.email),
            width: 280,
          },
          {
            title: 'Organization',
            filters: orgFilters,
            filterSearch: true,
            onFilter: (value, user) => {
              const userOrgs = getOrgIdsForUser(user.permissions);
              const selectedOrgId = orgIdsByName[value];
              return userOrgs.includes(selectedOrgId);
            },
            render: (_, user) => {
              const userOrgIds = getOrgIdsForUser(user.permissions);

              return isUserInternal(user) ? (
                <Typography.Text type="secondary">All Organizations</Typography.Text>
              ) : orgNamesById ? (
                <ShortenedItemsWithTooltip
                  items={userOrgIds
                    ?.map((orgId) => orgNamesById[orgId] || orgId)
                    .sort(compareAlphabetically)}
                />
              ) : null;
            },
          },
          {
            title: `Created (${TIME_ZONE})`,
            dataIndex: 'creation_date',
            sortDirections: ['descend', 'ascend', 'descend'],
            sorter: (a, b) => compareChronologically(a.creation_date, b.creation_date),
            render: (dateISOString) => {
              const date = moment(dateISOString);
              return (
                <Space>
                  <span style={{ whiteSpace: 'nowrap' }}>{date.format('YYYY-MM-DD')}</span>
                  <span>{date.format('(HH:mm)')}</span>
                </Space>
              );
            },
            width: 240,
          },
          {
            title: 'Role',
            sortDirections: ['descend', 'ascend', 'descend'],
            filters: getRoleOptions({ labelProp: 'text' }),
            onFilter: (value, user) => {
              const userRoles = user.permissions.map(({ role_name }) => role_name);
              return userRoles.includes(value);
            },
            render: ({ permissions }) => {
              if (permissions.length === 0) {
                return <UserRoleTag role="none" tooltip="Has access to none of the orgs" />;
              } else {
                const permissionTags = permissions.map((permission, i) => {
                  const { role_name: userRole, id: userOrgIds } = permission;
                  return (
                    <UserRoleTag
                      key={`permission-tag-${i}-${userRole}`}
                      role={userRole}
                      tooltip={getUserOrgsPermissionText({ userRole, userOrgIds, orgNamesById })}
                    />
                  );
                });

                return <Space direction="vertical">{permissionTags}</Space>;
              }
            },
            width: 92,
          },
          {
            key: 'actions',
            hidden: isViewOnly,
            render: (_, user) =>
              // Hide the actions for the logged-in user's own user row)
              user.id !== loggedInUserId ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {/* Only users associated with an org can be edited */}
                  {user.permissions.length > 0 ? (
                    <EditUser user={user} orgNamesById={orgNamesById} refetchUsers={refetchUsers} />
                  ) : null}
                  <DeleteUser user={user} refetchUsers={refetchUsers} />
                </div>
              ) : null,
            align: 'right',
            width: 92,
          },
        ].filter((column) => !column.hidden)}
      />
    </PageContents>
  );
};

const Users = () => {
  const { isInternalUser: loggedInUserIsInternalUser } = useSessionUser();

  return loggedInUserIsInternalUser ? <UsersInternal /> : <UsersExternal />;
};

export default Users;
