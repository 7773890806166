import React from 'react';

export const PageContents = ({ children, className, fillHeight }) => (
  <div
    className={className}
    style={{ padding: 24, height: fillHeight ? '100%' : 'auto', position: 'relative' }}
  >
    {children}
  </div>
);

export { default as Container } from './Container';

export { default as LoggedOutContainer } from './LoggedOutContainer';
