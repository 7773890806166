import { Form, Switch } from 'antd';

const ModeControlFormItem = ({ env, form, disabled }) => {
  const hardWallModeFieldName = [env, 'am_settings', 'hard_message_wall_mode_enabled'];
  const viewModeFieldName = [env, 'am_settings', 'view'];
  const adaptiveHardWallModeFieldName = [env, 'am_settings', 'adaptive_hard_message_wall_mode_enabled'];

  const isHardWallEnabled = Form.useWatch(hardWallModeFieldName, form);
  const viewMode = Form.useWatch(viewModeFieldName, form);

  const shouldDisableHardWallToggle = !isHardWallEnabled && viewMode !== 'modal';

  return (
    <>
    <Form.Item
        label="Hard Mode"
        name={hardWallModeFieldName}
        valuePropName="checked"
        tooltip={
            "Can only be enabled with style set to Modal. When enabled, modal cannot be dismissed and will block the page's content until the user follows the instructions."
        }
    >
        <Switch disabled={disabled || shouldDisableHardWallToggle} />
    </Form.Item>
    <Form.Item
        label="Adaptive Hard Mode"
        name={adaptiveHardWallModeFieldName}
        valuePropName="checked"
        tooltip={
            "When enabled, the hard wall will be shown to all users if their adblocker does not support Acceptable Ads."
        }
    >
        <Switch disabled={disabled || shouldDisableHardWallToggle} />
    </Form.Item>
    </>
  );
};

export default ModeControlFormItem;
