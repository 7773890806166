import pageViews from './pageViews.json';
import aaPageViews from './aaPageViews.json';
import auctions from './auctions.json';
import impressions from './impressions.json';
import grossRevenue from './grossRevenue.json';
import cpm from './cpm.json';
import topSites from './topSites.json';

const metrics = {
  pageViews,
  aaPageViews,
  auctions,
  impressions,
  grossRevenue,
  cpm,
  topSites,
};

export default metrics;
