import React from 'react';
import LoggedOutFooter from './LoggedOutFooter';

const LoggedOutContainer = ({ children }) => (
  <div style={{ minHeight: '100vh', display: 'grid' }}>
    {children}
    <LoggedOutFooter />
  </div>
);

export default LoggedOutContainer;
