import { Alert, Form, Input } from 'antd';
import React from 'react';
import { CodeEditor } from '../..';
import { validateJson } from '../../../services/utils';

const aaxDemandConfigPlaceholder = `{
  "pubmatic": {
    "publisherId": "935800"
  },
  "ix": {
    "siteId": "776528"
  },
  "criteo": [
    {
      "bottom_leaderboard": {
        "zoneId": "1409112"
      }
    }
  ]
}`;

const AddAAXDemandForm = ({ formInstance, isViewOnly, saveError }) => (
  <Form
    name="settings-add-aax-demand"
    form={formInstance}
    disabled={isViewOnly}
    preserve={false}
    requiredMark={false}
  >
    <Form.Item
      name={['value', 'sid']}
      label="Seller ID (sid)"
      tooltip="Set to the corresponding `seller_id` found in `sellers.json`."
      rules={[
        {
          required: true,
          message: 'Please enter a valid Seller ID!',
        },
      ]}
    >
      <Input placeholder="AAX3221EY" />
    </Form.Item>
    <Form.Item
      name={['value', 'asi']}
      label="Seller domain (asi)"
      tooltip="Should typically be set to 'aax.media'; otherwise, set it to the domain of the `sellers.json` file."
      rules={[
        {
          required: true,
          message: 'Please enter a valid Seller domain (asi)!',
        },
      ]}
    >
      <Input placeholder="aax.media" />
    </Form.Item>
    <Form.Item
      name={['value', 'aaxDemandConfig']}
      label="AAX Demand Config"
      tooltip="AAX Demand Config JSON object - If specifying a bidder's params by placement, provide them in an array where each entry includes the corresponding Space name."
      labelCol={{ span: 24 }}
      rules={[
        {
          required: true,
          validator: (rule, value) => validateJson(value),
          message: 'Please enter a valid AAX Demand Config JSON object!',
        },
      ]}
    >
      <CodeEditor
        placeholder={aaxDemandConfigPlaceholder}
        height={360}
        disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
      />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const settingConfig = {
  name: 'Enable Unified Auction (AAX Demand)',
  description:
    "Enables and configures AAX Demand bidders within a publisher's demand stack in a unified auction",
  Form: AddAAXDemandForm,
  // Transform AAX demand config to and from JS Object <-> JSON string because form must use string object but we want to store the value as an Object
  transformBeforeInit: ({ aaxDemandConfig, ...rest }) => ({
    aaxDemandConfig: JSON.stringify(aaxDemandConfig, null, 2),
    ...rest,
  }),
  transformBeforeSave: ({ aaxDemandConfig, ...rest }) => ({
    aaxDemandConfig: JSON.parse(aaxDemandConfig),
    ...rest,
  }),
  initialSettingValue: {},
};

export default settingConfig;
