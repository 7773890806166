import { BarChartOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Area } from '@ant-design/plots';
import {
  Alert,
  Card,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  PageHeader,
  Row,
  Space,
  Statistic,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { floor, isBoolean, isNil, minBy, round, sortBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import {
  DownloadCSV,
  OnceLoaded,
  ScopePageByOrg,
  ScopePageForm,
  useScopePageByOrg,
} from '../../components';
import { PageContents } from '../../layout';
import api from '../../services/api';
import { SEARCH_PARAM_KEYS } from '../../services/constants';
import { formatWithThousandCommas } from '../../services/utils';
import MetricsPie from './MetricsPie';

import styles from './Metrics.module.less';

const formatWithLetters = (num, isCurrency) => {
  const oneBillion = Math.pow(10, 9);
  const oneMillion = Math.pow(10, 6);
  const oneThousand = Math.pow(10, 3);

  const parsedNum = parseFloat(num); // for cases where `num` is passed in as a string
  let formattedNum;
  if (num >= oneBillion) {
    formattedNum = `${round(parsedNum / oneBillion, 4)} B`;
  } else if (num >= oneMillion) {
    formattedNum = `${round(parsedNum / oneMillion, 4)} M`;
  } else if (num >= oneThousand) {
    formattedNum = `${round(parsedNum / oneThousand, 4)} K`;
  } else if (isCurrency) {
    // When currency numbers < 1000 are floats, show the cents with 2 decimals
    formattedNum = Number.isInteger(parsedNum) ? `${parsedNum}` : parsedNum.toFixed(2);
  } else {
    formattedNum = `${parsedNum}`;
  }

  return isCurrency ? `$${formattedNum}` : formattedNum;
};

const BackfillTooltip = () => (
  <Tooltip
    title="This data is affected by the inclusion of backfill revenue."
    overlayStyle={{ maxWidth: 260 }}
  >
    <BarChartOutlined />
  </Tooltip>
);

const HelpTooltip = ({ text }) => (
  <Tooltip title={text} className={styles.metricsCardTooltip} overlayStyle={{ maxWidth: 260 }}>
    <QuestionCircleOutlined />
  </Tooltip>
);

const Tooltips = ({ help, downloadCSV, isBackfillIncluded }) => (
  <Space className={styles.metricsCardTooltips}>
    {isBackfillIncluded ? <BackfillTooltip /> : null}
    {downloadCSV ? <DownloadCSV {...downloadCSV} /> : null}
    {help ? <HelpTooltip text={help} /> : null}
  </Space>
);

const MetricsCard = ({ title, value, valueProps, error, isBackfillIncluded, help }) => (
  <Card className={styles.metricsCard}>
    <Typography.Title level={5}>{title}</Typography.Title>
    <Tooltips isBackfillIncluded={isBackfillIncluded} help={help} />
    {/* TODO: Handle error */}
    <Statistic className={styles.statistic} loading={isNil(value)} value={value} {...valueProps} />
  </Card>
);

const getMetricsGraphProps = ({ title, data, color, fill, isCurrencyData }) => ({
  xField: 'ts',
  yField: 'value',
  meta: {
    ts: {
      formatter: (timestamp) => moment.utc(timestamp).format('YYYY-MM-DD'),
    },
  },
  color,
  areaStyle: () => ({
    fill,
  }),
  yAxis: {
    alias: title,
    min: floor(minBy(data, 'value')?.value, isCurrencyData ? -2 : -3), // set `min` to the smallest value rounded down to the nearest thousand (or nearest hundred dollars for currency data)
    label: { formatter: (x) => formatWithLetters(x, isCurrencyData) },
  },
  xAxis: {
    label: { formatter: (d) => moment.utc(d).format('MMM DD') }, // TODO: Adjust as necessary once we handle monthly data (+ add default resolution handling in mock api)
    tickCount: 5,
  },
  tooltip: {
    formatter: ({ value }) => ({
      name: title,
      value: isCurrencyData
        ? `$${formatWithThousandCommas(value.toFixed(2))}`
        : formatWithThousandCommas(value),
    }),
  },
});

const MetricsGraph = React.memo(
  ({ title, data, error, color, fill, isCurrencyData = false, isBackfillIncluded }) => (
    <Card className={styles.metricsCard}>
      <Typography.Title level={5}>{title}</Typography.Title>
      <Tooltips isBackfillIncluded={isBackfillIncluded} />
      {/* TODO: Handle error */}
      {data?.length === 0 ? (
        <Empty description={<p>No {title} metrics were found for the selected date range.</p>} />
      ) : (
        <Area
          className={styles.graph}
          loading={!data}
          data={data || []} // NOTE: Passing in a nil value for `data` will cause errors!
          padding="auto"
          smooth
          height={225}
          {...getMetricsGraphProps({ title, data, color, fill, isCurrencyData })}
        />
      )}
    </Card>
  )
);

const topSitesByRevenueMetricsPieProps = {
  angleField: 'value',
  colorField: 'domain',
  contentFormatter: (_, topSitesByRevenue) => {
    let total = topSitesByRevenue.reduce((acc, { value }) => acc + value, 0);
    // If total is equal to or greater than 1 million, round to nearest dollar to save space - otherwise, show total with cents
    total = total >= Math.pow(10, 6) ? Math.round(total) : total.toFixed(2);
    return `$${formatWithThousandCommas(total)}`;
  },
  tooltipFormatter: ({ domain, value }) => ({
    name: domain,
    value: `$${formatWithThousandCommas((value || 0).toFixed(2))}`,
  }),
  customHtml: () => (
    <>
      <div>Gross Revenue</div>
      <div>Recovered</div>
    </>
  ),
};

const MetricsPageContents = ({ org, dateRange, isBackfillIncluded }) => {
  const { id: orgId, name: orgName } = org;
  const [dateStartString, dateEndString] = dateRange;

  const swrKeyProps = [orgId, dateStartString, dateEndString, isBackfillIncluded];

  // Different metrics API calls require slightly different params
  const resourceScopedParams = {
    resource_type: 'org', // currently we only support/use the 'org' resource type
    id: orgId,
    include_backfill: isBackfillIncluded, // API will ignore this param if the metric is not affected by the inclusion of backfill
    start_date: dateStartString,
    end_date: dateEndString,
  };
  const orgScopedParams = {
    org_id: orgId,
    include_backfill: isBackfillIncluded, // API will ignore this param if the metric is not affected by the inclusion of backfill
    start_date: dateStartString,
    end_date: dateEndString,
  };

  const { data: pageviews, error: pageviewsError } = useSWR(
    ['/PageViewMetrics', ...swrKeyProps],
    () => api.getPageviewMetrics(resourceScopedParams),
    { revalidateOnFocus: false }
  );
  const { data: aaPageviews, error: aaPageviewsError } = useSWR(
    ['/AAPageViewMetrics', ...swrKeyProps],
    () => api.getAAPageviewMetrics(resourceScopedParams),
    { revalidateOnFocus: false }
  );
  const { data: auctions, error: auctionsError } = useSWR(
    ['/AuctionMetrics', ...swrKeyProps],
    () => api.getAuctionMetrics(resourceScopedParams),
    { revalidateOnFocus: false }
  );
  const { data: impressions, error: impressionsError } = useSWR(
    ['/ImpressionMetrics', ...swrKeyProps],
    () => api.getImpressionMetrics(resourceScopedParams),
    { revalidateOnFocus: false }
  );
  const { data: grossRevenue, error: grossRevenueError } = useSWR(
    ['/GrossRevenueMetrics', ...swrKeyProps],
    () => api.getGrossRevenueMetrics(resourceScopedParams),
    { revalidateOnFocus: false }
  );
  const { data: cpm, error: cpmError } = useSWR(
    ['/CPMMetrics', ...swrKeyProps],
    () => api.getCPMMetrics(resourceScopedParams),
    { revalidateOnFocus: false }
  );
  const { data: rpm, error: rpmError } = useSWR(
    ['/RPMMetrics', ...swrKeyProps],
    () => api.getRPMMetrics(resourceScopedParams),
    { revalidateOnFocus: false }
  );
  const { data: topSitesByRevenue, error: topSitesByRevenueError } = useSWR(
    ['/TopSites', ...swrKeyProps],
    () =>
      api.getTopSitesMetrics({
        ...orgScopedParams,
        metric_type: 'revenue',
      }),
    { revalidateOnFocus: false }
  );
  const { data: mwrMetrics, error: mwrMetricsError } = useSWR(
    ['/MWRMetrics', ...swrKeyProps],
    () => api.getMessageWallRecoveryMetrics(orgScopedParams),
    { revalidateOnFocus: false }
  );
  const { data: adblockerMetrics, error: adBlockUsersError } = useSWR(
    ['/AdblockerBreakdown', ...swrKeyProps],
    () => api.getAdblockerBreakdownMetrics(orgScopedParams),
    { revalidateOnFocus: false }
  );

  const dailyBreakdownData = useMemo(() => {
    if (
      pageviews?.data &&
      aaPageviews?.data &&
      auctions?.data &&
      impressions?.data &&
      grossRevenue?.data &&
      cpm?.data &&
      rpm?.data
    ) {
      // Transform data from API into form we can use for `dataSource` in `<Table>` (array of `{ date, pageviews, aaPageviews, ... }` objects)
      // Ensure all metrics data in each entry aligns with the correct date (don't assume all metrics arrays are returned with the same dates & corresponding values in the same indices)
      const dataByDate = {};
      Object.entries({
        pageviews,
        aaPageviews,
        auctions,
        impressions,
        grossRevenue,
        cpm,
        rpm,
      }).forEach(([metricName, dataset]) => {
        dataset.data.forEach(({ ts, value }) => {
          const date = moment.utc(ts).format('YYYY-MM-DD'); // remove "T00:00:00Z" from end of `ts` timestamp
          dataByDate[date] = dataByDate[date] || {};
          dataByDate[date][metricName] = value;
        });
      });

      return Object.entries(dataByDate)
        .map(([date, dataByMetricName]) => ({ date, ...dataByMetricName }))
        .sort(({ date: dateA }, { date: dateB }) => new Date(dateB) - new Date(dateA)); // Show data in reverse chronological order
    }
  }, [pageviews, aaPageviews, auctions, impressions, grossRevenue, cpm, rpm]);

  // Sort from highest to lowest revenue (but with "others" always placed at the end)
  const sortedTopSitesByRevenue =
    topSitesByRevenue &&
    sortBy(topSitesByRevenue, [({ domain }) => domain !== 'others', 'value']).reverse();

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={12}>
        <MetricsCard
          title="Pageviews"
          value={pageviews?.total}
          error={pageviewsError}
          help="The total number of page views recorded throughout the date range specified."
        />
      </Col>
      <Col xs={24} sm={6}>
        <MetricsCard
          title="CPM"
          value={!isNil(cpm?.total) ? (cpm.total / cpm.data.length).toFixed(2) : null}
          valueProps={{ prefix: '$', precision: 2 }}
          error={cpmError}
          isBackfillIncluded={isBackfillIncluded}
          help="The effective Cost per Mille (the total advertising gross revenue generated, divided by the total number of ad impressions served; expressed in units of 1000) for the date range specified."
        />
      </Col>
      <Col xs={24} sm={6}>
        <MetricsCard
          title="RPM"
          value={!isNil(rpm?.total) ? (rpm.total / rpm.data.length).toFixed(2) : null}
          valueProps={{ prefix: '$', precision: 2 }}
          error={rpmError}
          isBackfillIncluded={isBackfillIncluded}
          help="The effective Revenue per Mille (the total advertising gross revenue generated, divided by the total number of AA page views recorded; expressed in units of 1000) for the date range specified."
        />
      </Col>
      <Col xs={24} sm={12} xl={6}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <MetricsCard
              title="AA Pageviews"
              value={aaPageviews?.total}
              error={aaPageviewsError}
              help="The total number of AdBlock (with Acceptable Ads enabled) page views for recorded throughout the date range specified."
            />
          </Col>
          <Col span={24}>
            <MetricsGraph
              title="AA Pageviews"
              data={aaPageviews?.data}
              error={aaPageviewsError}
              color="rgb(24,144,255)"
              fill="l(270) 0:rgba(24,144,255,0.2) 0.5:rgb(24,144,255)"
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={12} xl={6}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <MetricsCard
              title="Auctions"
              value={auctions?.total}
              error={auctionsError}
              help="The total number of auctions performed to fill available AdBlock (Acceptable Ads) impressions throughout the date range specified."
            />
          </Col>
          <Col span={24}>
            <MetricsGraph
              title="Auctions"
              data={auctions?.data}
              error={auctions}
              color="rgb(139,42,198)"
              fill="l(270) 0:rgba(139,42,198,0.2) 0.5:rgb(139,42,198)"
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={12} xl={6}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <MetricsCard
              title="Impressions"
              value={impressions?.total}
              error={impressionsError}
              isBackfillIncluded={isBackfillIncluded}
              help="The total number of monetized AdBlock (Acceptable Ads) impressions served throughout the date range specified."
            />
          </Col>
          <Col span={24}>
            <MetricsGraph
              title="Impressions"
              data={impressions?.data}
              error={impressionsError}
              color="rgb(255,111,12)"
              isBackfillIncluded={isBackfillIncluded}
              fill="l(270) 0:rgba(255,111,12,0.2) 0.5:rgb(255,111,12)"
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={12} xl={6}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <MetricsCard
              title="Gross Revenue"
              value={grossRevenue?.total}
              valueProps={{ prefix: '$', precision: 2 }}
              error={grossRevenueError}
              isBackfillIncluded={isBackfillIncluded}
              help="The total revenue generated from monetizing AdBlock (Acceptable Ads) users throughout the date range specified."
            />
          </Col>
          <Col span={24}>
            <MetricsGraph
              title="Gross Revenue"
              data={grossRevenue?.data}
              error={grossRevenueError}
              color="rgb(20,203,64)"
              fill="l(270) 0:rgba(20,203,64,0.2) 0.5:rgb(20,203,64)"
              isCurrencyData
              isBackfillIncluded={isBackfillIncluded}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} xl={12}>
        <Card className={styles.metricsCard}>
          <Typography.Title level={5}>Top Sites by Revenue</Typography.Title>
          <Tooltips isBackfillIncluded={isBackfillIncluded} />
          <MetricsPie
            data={sortedTopSitesByRevenue}
            error={topSitesByRevenueError}
            {...topSitesByRevenueMetricsPieProps}
          />
        </Card>
      </Col>
      <Col xs={24} xl={12}>
        <Card className={[styles.metricsCard, styles.dailyBreakdownCard]}>
          <Typography.Title level={5}>Daily Breakdown</Typography.Title>
          <Tooltips
            isBackfillIncluded={isBackfillIncluded}
            downloadCSV={{
              filename: `${orgName} - Daily Breakdown (${moment
                .utc(dateStartString)
                .format('YYYY-MM-DD')} - ${moment.utc(dateEndString).format('YYYY-MM-DD')}).csv`, // removes "T00:00:00Z" from end of date string timestsamps
              data: dailyBreakdownData,
            }}
          />
          {/* TODO: Handle errors from fetching breakdown metrics data */}
          <Table
            key={`${orgId}-${dateStartString}-${dateEndString}`} // Ensures the pagination is reset when the table data changes
            className={styles.table}
            size="small"
            scroll={{ x: 'max-content' }}
            loading={!dailyBreakdownData}
            rowKey="date"
            dataSource={dailyBreakdownData}
            columns={[
              {
                title: 'Date',
                dataIndex: 'date',
                fixed: 'left',
              },
              {
                title: 'Pageviews',
                dataIndex: 'pageviews',
                render: formatWithThousandCommas,
              },
              {
                title: 'AAPV',
                dataIndex: 'aaPageviews',
                render: formatWithThousandCommas,
              },
              {
                title: 'Auctions',
                dataIndex: 'auctions',
                render: formatWithThousandCommas,
              },
              {
                title: 'Impressions',
                dataIndex: 'impressions',
                render: formatWithThousandCommas,
              },
              {
                title: 'Gross Rev',
                dataIndex: 'grossRevenue',
                render: (grossRevenue) =>
                  `$${formatWithThousandCommas((grossRevenue || 0).toFixed(2))}`,
              },
              {
                title: 'CPM',
                dataIndex: 'cpm',
                render: (cpm) => `$${(cpm || 0).toFixed(2)}`,
              },
              {
                title: 'RPM',
                dataIndex: 'rpm',
                render: (rpm) => `$${(rpm || 0).toFixed(2)}`,
              },
            ]}
          />
        </Card>
      </Col>
      {mwrMetrics ? (
        <>
          <Divider orientation="left">Readership Link</Divider>
          <Col xs={24} xl={12}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <MetricsCard
                      title="Recovered Revenue"
                      value={mwrMetrics?.recovered_revenue}
                      valueProps={{ prefix: '$', precision: 2 }}
                      error={mwrMetricsError}
                      help="The total Readership Link recovered revenue generated from monetizing AdBlock (Acceptable Ads) and non-AdBlock users throughout the date range specified."
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12} xl={12}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <MetricsCard
                      title="Renders"
                      value={mwrMetrics?.renders}
                      error={mwrMetricsError}
                      help="How many times Readership Link was rendered on a user session throughout the date range specified."
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <MetricsCard
                      title="Users Recovered"
                      value={mwrMetrics?.recoveries}
                      error={mwrMetricsError}
                      help="How many users enabled AA or disabled Ad Blocker after seeing the Readership Link message throughout the date range specified."
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <MetricsCard
                      title="Recovery Rate"
                      value={mwrMetrics?.recovery_rate && mwrMetrics?.recovery_rate * 100}
                      valueProps={{ suffix: '%', precision: 1 }}
                      error={mwrMetricsError}
                      help="Percentage of the renders led to users enabled AA or disabled Ad Blocker throughout the date range specified."
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <MetricsCard
                      title="Subscription Redirect Rate"
                      value={
                        mwrMetrics?.primary_cta_redirect_rate &&
                        mwrMetrics?.primary_cta_redirect_rate * 100
                      }
                      valueProps={{ suffix: '%', precision: 1 }}
                      error={mwrMetricsError}
                      help="Percentage of the renders that led to users clicks on subscription redirect button throughout the date range specified.
                      The rate value may be zero if Readership Link is not set up to custom CTA mode."
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <MetricsCard
                      title="Dismiss Rate"
                      value={mwrMetrics?.dismiss_rate && mwrMetrics?.dismiss_rate * 100}
                      valueProps={{ suffix: '%', precision: 1 }}
                      error={mwrMetricsError}
                      help="Percentage of the renders that led the user to dismiss the message throughout the date range specified."
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24} xl={12}>
            <Card className={styles.metricsCard}>
              <Typography.Title level={5}>Traffic Breakdown by AdBlocker Type</Typography.Title>
              <Tooltip
                title="Number of times Readership Link rendered to Ad Blocker traffic, broken down by renders to users that has Acceptable Ads supported on the extension and those that did not."
                className={styles.metricsCardTooltip}
                overlayStyle={{ maxWidth: 260 }}
              >
                <QuestionCircleOutlined />
              </Tooltip>
              <MetricsPie
                data={adblockerMetrics}
                error={adBlockUsersError}
                angleField="count"
                colorField="category"
              />
            </Card>
          </Col>
        </>
      ) : null}
      <Alert
        message="Please note that dashboard figures are estimated and may differ slightly from actualized totals."
        type="info"
        style={{ width: '100%' }}
        showIcon
      />
    </Row>
  );
};

const useScopePageByDateRange = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dateStartParam = searchParams.get(SEARCH_PARAM_KEYS.DATE_START);
  const dateEndParam = searchParams.get(SEARCH_PARAM_KEYS.DATE_END);

  // Ensure start & end dates from params are valid and are not set for dates any later than "Yesterday"
  // (The default/fallback date range is 'Last 30 days' (excluding "Today"))
  const YESTERDAY = moment().subtract(1, 'day');
  let dateStart = moment(dateStartParam, 'YYYY-MM-DD');
  if (!dateStart.isValid() || dateStart.isAfter(YESTERDAY, 'day')) {
    dateStart = moment().subtract(30, 'days');
  }
  let dateEnd = moment(dateEndParam, 'YYYY-MM-DD');
  if (!dateEnd.isValid() || dateEnd.isAfter(YESTERDAY, 'day') || dateEnd.isBefore(dateStart)) {
    // (An end date set before the start date is invalid)
    dateEnd = YESTERDAY;
  }
  // Ensure date range doesn't span more than 1 year
  if (dateEnd.diff(dateStart, 'years', true) > 1) {
    // Set start date to 1 year before the end date
    dateStart = moment(dateEnd).subtract(1, 'year');
  }

  const dateStartString = moment.utc(dateStart.format('YYYY-MM-DD')).format();
  const dateEndString = moment.utc(dateEnd.format('YYYY-MM-DD')).endOf('day').format();

  const scopedDateRange = [dateStartString, dateEndString];

  const dateRangePickerProps = {
    value: [dateStart, dateEnd],
    onChange: useCallback(
      (selectedDateRange) => {
        const [dateStart, dateEnd] = selectedDateRange || [];

        const newSearchParams = new URLSearchParams(searchParams);
        if (dateStart && dateEnd) {
          newSearchParams.set(SEARCH_PARAM_KEYS.DATE_START, dateStart.format('YYYY-MM-DD'));
          newSearchParams.set(SEARCH_PARAM_KEYS.DATE_END, dateEnd.format('YYYY-MM-DD'));
        }
        setSearchParams(newSearchParams);
      },
      [searchParams, setSearchParams]
    ),
  };

  return { scopedDateRange, dateRangePickerProps };
};

const ScopePageByDateRange = ({ dateRangePickerProps: { value, onChange } }) => (
  <Form.Item name="dateRange" label="Date Range">
    <DatePicker.RangePicker
      defaultValue={value} // need to use `defaultValue` instead of `value` prop for this antd component for some reason
      onCalendarChange={onChange}
      disabledDate={(current) => {
        // Disable dates after "Yesterday"
        const dateIsAfterYesterday = current && current.isAfter(moment().subtract(1, 'day'), 'day');
        if (dateIsAfterYesterday) {
          return true;
        }

        // Disable date ranges which span more than 1 year
        if (value) {
          const [selectedStartDate, selectedEndDate] = value;
          const rangeIsTooLarge =
            (selectedStartDate && current.diff(selectedStartDate, 'years', true) > 1) ||
            (selectedEndDate && selectedEndDate.diff(current, 'years', true) > 1);
          return rangeIsTooLarge;
        }
      }}
      ranges={{
        // Omit data from "Today" by Product team's request to not alarm users to drop in metrics
        Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        'Last 7 days': [moment().subtract(7, 'days'), moment().subtract(1, 'day')],
        'Last 30 days': [moment().subtract(30, 'days'), moment().subtract(1, 'day')],
        'Last month': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
        'Month to date': [moment().startOf('month'), moment().subtract(1, 'day')],
        'Year to date': [moment().startOf('year'), moment().subtract(1, 'day')],
      }}
    />
  </Form.Item>
);

const useScopePageByBackfillInclusion = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isBackfillIncluded = searchParams.get(SEARCH_PARAM_KEYS.INCLUDE_BACKFILL) === 'true';

  const switchProps = {
    checked: isBackfillIncluded,
    onChange: useCallback(
      (isBackfillIncluded) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (isBackfillIncluded) {
          newSearchParams.set(SEARCH_PARAM_KEYS.INCLUDE_BACKFILL, 'true');
        } else {
          newSearchParams.delete(SEARCH_PARAM_KEYS.INCLUDE_BACKFILL);
        }
        setSearchParams(newSearchParams);
      },
      [searchParams, setSearchParams]
    ),
  };

  return { scopedBackfillInclusion: isBackfillIncluded, switchProps };
};

const ScopePageByBackfillInclusion = ({ switchProps }) => (
  <Form.Item
    name="includeBackfill"
    label={
      <Space size="small">
        <BarChartOutlined />
        Backfill
      </Space>
    }
  >
    <Switch {...switchProps} checkedChildren="Included" unCheckedChildren="Excluded" />
  </Form.Item>
);

const Metrics = () => {
  // Metrics displayed are scoped to a selected org & date range which are stored in their corresponding URL search params
  // (Updates to the selected org or date range will trigger new API calls to fetch updated metrics)
  const { scopedOrg, orgSelectProps, orgsError } = useScopePageByOrg();
  const { scopedDateRange, dateRangePickerProps } = useScopePageByDateRange();
  const { scopedBackfillInclusion, switchProps } = useScopePageByBackfillInclusion();

  return (
    <>
      <PageHeader
        ghost={false}
        className={styles.pageHeader}
        title="Dashboard"
        extra={
          <ScopePageForm>
            <ScopePageByOrg scopedOrg={scopedOrg} orgSelectProps={orgSelectProps} />
            <ScopePageByDateRange dateRangePickerProps={dateRangePickerProps} />
            <ScopePageByBackfillInclusion switchProps={switchProps} />
          </ScopePageForm>
        }
      />
      <PageContents>
        <Alert
          message={
            <span>
              We are currently encountering technical challenges with some functions of our
              dashboard reporting.
              <br />
              For assistance, kindly connect with your account manager or reach out to us via email
              at pubsupport@blockthrough.com. We apologize for any inconvenience caused.
            </span>
          }
          style={{ marginBottom: 16 }}
        />
        <OnceLoaded
          error={orgsError}
          isLoading={!(scopedOrg && scopedDateRange && isBoolean(scopedBackfillInclusion))}
          render={() => (
            <MetricsPageContents
              org={scopedOrg}
              dateRange={scopedDateRange}
              isBackfillIncluded={scopedBackfillInclusion}
            />
          )}
        />
      </PageContents>
    </>
  );
};
export default Metrics;
