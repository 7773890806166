import de from './de.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import ja from './ja.json';
import nl from './nl.json';
import pt from './pt.json';
import ru from './ru.json';
import sv from './sv.json';
import zh from './zh.json';
import zh_tw from './zh_tw.json';

const translations = {
    en, de, es, fr, it, ja, nl, pt, ru, sv, zh, zh_tw
}

export default translations;
