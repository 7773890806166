import { Alert, Checkbox, Collapse, Form, Space } from 'antd';
import React from 'react';
import { FormItemList, HelpTooltip, TagsSelector } from '../../../components';
import { validateSize } from '../../../services/utils';
import { WhenDevice, WhenStringProp } from './formComponents';

const SizesToRemove = ({ field, disabled }) => (
  <Form.Item
    {...field}
    key={`${field.key}-sizes`}
    name={[field.name, 'sizes']}
    label="Remove"
    colon={false}
    rules={[
      {
        required: true,
        message: 'Please input at least 1 size!',
      },
    ]}
  >
    <TagsSelector addPrompt="Size" validateTag={validateSize} disabled={disabled} />
  </Form.Item>
);

const IsStrict = ({ field, disabled }) => (
  <Form.Item
    {...field}
    key={`${field.key}-isStrict`}
    name={[field.name, 'isStrict']}
    colon={false}
    label="Strict"
    valuePropName="checked"
    tooltip="If checked, sizes will be removed in strict mode (if GAM returns this size, the bid will marked with the SIZE_INCOMPLIANT status and filtered out)."
  >
    <Checkbox disabled={disabled} />
  </Form.Item>
);

const RemoveSizesForm = ({ formInstance, isViewOnly, saveError }) => (
  <Form
    name="settings-remove-sizes"
    form={formInstance}
    disabled={isViewOnly}
    preserve={false}
    requiredMark={false}
  >
    <Collapse defaultActiveKey={['forAllSpaces', 'forSpaces', 'forGPTSlots']}>
      <Collapse.Panel header="From All Spaces" key="forAllSpaces">
        <FormItemList
          name={['value', 'forAllSpaces']}
          disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
          addPrompt="Add sizes to remove"
          addInitialValue={{ whenDevice: 'any' }}
          renderFormItem={({ field, disabled }) => (
            <>
              <WhenDevice field={field} />
              <SizesToRemove field={field} disabled={disabled} />
              <IsStrict field={field} disabled={disabled} />
            </>
          )}
        />
      </Collapse.Panel>
      <Collapse.Panel header="From Spaces (with matching Space name)" key="forSpaces">
        <FormItemList
          name={['value', 'forSpaces']}
          disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
          addPrompt="Add sizes to remove"
          addInitialValue={{ whenName: 'equalsOneOf', whenDevice: 'any' }}
          renderFormItem={({ field, formItemListName, disabled }) => (
            <>
              <WhenStringProp
                propKey="name"
                propDisplayName="Space Name"
                conditionPropKey="whenName"
                field={field}
                disabled={disabled}
                formInstance={formInstance}
                parentFieldNamePath={formItemListName}
              />
              <WhenDevice field={field} />
              <SizesToRemove field={field} disabled={disabled} />
              <IsStrict field={field} disabled={disabled} />
            </>
          )}
        />
      </Collapse.Panel>
      <Collapse.Panel
        header={
          <Space>
            <span>
              From Spaces (with matching GPT slot path)
              <HelpTooltip title="Removes sizes from Bid Requests (if corresponding GPT slot path can be found at time of auction). Initial/modified and actually requested sizes are saved in Space entity under sizeData property." />
            </span>
          </Space>
        }
        key="forGPTSlots"
      >
        <FormItemList
          name={['value', 'forGPTSlots']}
          disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
          addPrompt="Add sizes to remove"
          addInitialValue={{ whenPath: 'equalsOneOf', whenDevice: 'any' }}
          renderFormItem={({ field, formItemListName, disabled }) => (
            <>
              <WhenStringProp
                propKey="path"
                propDisplayName="Slot Path"
                conditionPropKey="whenPath"
                field={field}
                disabled={disabled}
                formInstance={formInstance}
                parentFieldNamePath={formItemListName}
                tagsSelectorInputWidth="100%"
              />
              <WhenDevice field={field} />
              <SizesToRemove field={field} disabled={disabled} />
              <IsStrict field={field} disabled={disabled} />
            </>
          )}
        />
      </Collapse.Panel>
    </Collapse>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const settingConfig = {
  name: 'Remove Sizes',
  description: 'The ad unit sizes to remove/"disable" for all or specific BT Spaces.',
  Form: RemoveSizesForm,
  initialSettingValue: {},
};

export default settingConfig;
