import { DownOutlined, ImportOutlined } from '@ant-design/icons';
import { Alert, Button, Dropdown, Menu, message, Modal, Space, Tooltip, Upload } from 'antd';
import { parse } from 'papaparse';
import React, { useState } from 'react';
import { mutate } from 'swr';
import api from '../../services/api';
import { useAsync } from '../../services/hooks';
import {
  compareAlphabetically,
  createPrebidAdUnit,
  formatWebsiteDomain,
  generateErrorMessage,
  validateWebsiteDomain,
} from '../../services/utils';

function parseParamValue(paramType, paramValueRaw) {
  const paramValue = paramValueRaw.trim(); // mostly for multi-bidder params, since papa-parse's `transform` will trim the cell value

  switch (paramType) {
    case 'integer':
      return parseInt(paramValue, 10);
    case 'float':
    case 'Array<integer>':
      return JSON.parse(paramValue);
    default:
      return paramValue;
  }
}

/**
 * @typedef {Object<string, any>} BidObject
 * @property {string} bidder The bidders's name.
 * @property {{ networkId: number, zoneId: number, delDomain: string, unit: string }} params
 */

/**
 * @property {Function} generatePrebidConfigByDomain Transforms a parsed csv file's data into a set of Prebid ad unit configurations (by website domain)
 * @param {Array<Array<string>>} parsedCsv
 * @returns {{[key: string]: { code: string, selector: { value: string }, bids: Array<BidObject>, sizes: Array<[number, number]> }}}
 */
export const generatePrebidConfigByDomain = (parsedCsv) => {
  const NUM_SET_COLUMNS = 5;
  const bidderNames = parsedCsv[0].slice(NUM_SET_COLUMNS);
  const bidderParamKeys = parsedCsv[1].slice(NUM_SET_COLUMNS);
  const bidderParamTypes = parsedCsv[2].slice(NUM_SET_COLUMNS);
  const adUnitRows = parsedCsv.slice(3);

  return adUnitRows.reduce(
    (
      configByDomain,
      [domainString, deviceTypeString, adUnitCode, selector, adUnitSizes, ...adUnitBidderData]
    ) => {
      if (domainString) {
        const domain = formatWebsiteDomain(domainString);
        configByDomain[domain] = configByDomain[domain] || [];
        const adUnitBidders = [];
        let lastBidderName;
        let bidderParamsList = [];

        let deviceType = deviceTypeString.toLowerCase();
        // If the device type string doesn't correspond to any of the supported device type values (or is omitted), set it to the default 'all'
        if (!['all', 'desktop', 'mobile'].includes(deviceType)) {
          deviceType = 'all';
        }

        adUnitBidderData.forEach((paramValue, index) => {
          const bidderName = bidderNames[index] || lastBidderName; // Since the bidder name is a merged cell, if it has more than 1 param, the subsequent bidder name columns are blank

          const isNewBidder = lastBidderName !== bidderName;
          if (isNewBidder) {
            // Save the last bidder's `bidderParamsList` data as bidder objects into `adUnitBidders`
            // If the last bidder was a "multi-bidder", multiple bidder objects with that bidder name are added into `adUnitBidders`
            bidderParamsList.forEach((bidderParams) => {
              if (Object.keys(bidderParams).length > 0) {
                // If `bidderParams` is empty, don't add the bidder
                adUnitBidders.push({
                  data: {
                    bidder: lastBidderName,
                    params: bidderParams,
                  },
                });
              }
            });
            bidderParamsList = [{}]; // (re-)initialize for new bidder
          }

          /**
           * NOTE: `paramValue` (value in the spreadsheet cell) can be delimited by \n to add multiple bidder objects to the Prebid
           * ad unit for that same bidder (e.g. multiple ix bidders in the same ad unit) - these will hereby be referred to as "multi-bidders"
           * `bidderParamsList` is therefore an array with length > 1 for a "multi-bidder"
           */
          if (paramValue) {
            const paramKey = bidderParamKeys[index];
            const paramType = bidderParamTypes[index];

            paramValue.split('\n').forEach((value, multiBidderIndex) => {
              // Ignore empty string values (e.g. trailing new line)
              if (!value) {
                return;
              }

              if (!bidderParamsList[multiBidderIndex]) {
                // If there are multiple `value`s in `paramValue` (seen in "multi-bidders") and we need to add a new bidder params object to `bidderParamsList`,
                // initialize it by copying over param values that either apply to each bidder params object, or that will be immediately overwritten below
                bidderParamsList.push({ ...bidderParamsList[0] });
              }

              // Convert CSV string value to integer, Array, etc.
              bidderParamsList[multiBidderIndex][paramKey] = parseParamValue(paramType, value);
            });
          }

          lastBidderName = bidderName;
        });
        // Save the final bidder's `bidderParamsList` data as bidder objects into `bidders` if needed
        bidderParamsList.forEach((bidderParams) => {
          if (Object.keys(bidderParams).length > 0) {
            // If `bidderParams` is empty, don't add the bidder
            adUnitBidders.push({ data: { bidder: lastBidderName, params: bidderParams } });
          }
        });

        // Add the new ad unit to the config for its respective website
        const space = {
          name: adUnitCode,
          device_type: deviceType,
          sizes: adUnitSizes.split(','),
          bidders: adUnitBidders,
        };
        if (selector) space.selector = { value: selector };
        configByDomain[domain].push(createPrebidAdUnit(space));
      }
      return configByDomain;
    },
    {}
  );
};

const ImportAdUnitCSV = ({ org, orgWebsites }) => {
  const [importEnv, setImportEnv] = useState('production'); // Should only be set to either 'production' or 'staging'

  const { execute: createSitesAndUpload, status } = useAsync((spacesByDomain, staging) =>
    Promise.allSettled(
      Object.entries(spacesByDomain).map(async ([domain, spaces]) => {
        let websiteId;
        const websiteInOrg = orgWebsites.find((website) => website.domain === domain);
        if (!websiteInOrg) {
          try {
            if (validateWebsiteDomain(domain)) {
              const { website } = await api.createWebsite({ domain, org_id: org.id });
              websiteId = website.id;
            } else {
              throw new Error(`"${domain}" is not a valid domain name!`);
            }
          } catch (error) {
            return Promise.reject(`Could not create ${domain}. ${generateErrorMessage(error)}`);
          }
        } else {
          websiteId = websiteInOrg.id;
        }
        return api
          .uploadSpaces(websiteId, spaces, staging)
          .then(() => domain) // return `domain` as the resolved Promise value if upload succeeded
          .catch((error) => {
            return Promise.reject(
              `Could not import ad units for ${domain}. ${generateErrorMessage(error)}`
            );
          });
      })
    )
  );
  const isCreatingAndUploading = status === 'pending';

  const dropdownMenu = (
    <Menu
      onClick={({ key }) => {
        setImportEnv(key);
      }}
      items={[
        { key: 'production', label: 'Import Spaces to Production' },
        { key: 'staging', label: 'Import Spaces to Staging' },
      ]}
    />
  );

  return (
    <Upload
      disabled={isCreatingAndUploading}
      accept=".csv"
      showUploadList={false}
      beforeUpload={(csvFile) => {
        parse(csvFile, {
          skipEmptyLines: true,
          transform: (cellValue) => cellValue.trim(), // assumes all cell values are parsed as strings!
          error: () => {
            message.error('Error trying to import CSV file :(');
          },
          complete: (results) => {
            try {
              if (results.errors.length > 0) {
                console.error(results.errors);
                throw new Error('@ papaparse - check console logs');
              } else {
                const prebidSpacesByDomain = generatePrebidConfigByDomain(results.data);
                if (!prebidSpacesByDomain) {
                  throw new Error('@ generatePrebidConfigByDomain');
                }

                // Uploads Spaces to staging when `importEnv` is set to 'staging'
                createSitesAndUpload(prebidSpacesByDomain, importEnv === 'staging').then(
                  (promiseResults) => {
                    const errorMessages = promiseResults
                      .filter(({ status }) => status === 'rejected')
                      .map(({ reason }) => reason);

                    if (errorMessages.length > 0) {
                      const successDomains = promiseResults
                        .filter(({ status }) => status === 'fulfilled')
                        .map(({ value }) => value);

                      Modal.error({
                        title: 'Errors occurred while importing the CSV file',
                        content: (
                          <Space
                            direction="vertical"
                            size={8}
                            style={{ marginTop: 8, width: '100%' }}
                          >
                            {errorMessages.map((errorMessage, index) => (
                              <Alert key={index} message={errorMessage} type="error" />
                            ))}
                            {successDomains.length > 0 ? (
                              <>
                                <div>
                                  Nevertheless, Spaces were successfully uploaded for the following
                                  (new or existing) websites:
                                </div>
                                <div>
                                  {successDomains
                                    .sort(compareAlphabetically)
                                    .map((domain, index) => (
                                      <div key={index}>{domain}</div>
                                    ))}
                                </div>
                              </>
                            ) : null}
                          </Space>
                        ),
                        width: 500,
                      });
                    } else {
                      message.success(
                        `Spaces successfully uploaded (to new or existing websites in CSV) in ${importEnv}!`
                      );
                    }
                    // Trigger org websites refresh to reflect any updates
                    mutate(['/WebsiteList', org.id]);
                  }
                );
              }
            } catch (error) {
              message.error(`Error trying to import CSV file. ${generateErrorMessage(error)}`);
            }
          },
        });

        // Prevent upload (we don't need to upload the file to a server, we just want to parse it)
        return false;
      }}
    >
      <Tooltip
        title="Parses a CSV file to upload Static BT Spaces and create their corresponding Websites (unless they already exist)"
        overlayStyle={{ maxWidth: 350, width: 350 }}
      >
        <Dropdown key="import-spaces" overlay={dropdownMenu}>
          <Button
            icon={<ImportOutlined />}
            loading={isCreatingAndUploading}
            onClick={(event) => {
              event.stopPropagation(); // Ensure <Upload> is only triggered on dropdown menu item click
            }}
          >
            Import Spaces <DownOutlined />
          </Button>
        </Dropdown>
      </Tooltip>
    </Upload>
  );
};

export default ImportAdUnitCSV;
