import { round } from 'lodash';

/**
 * @property {Function} generateAmazonCPMChart Converts a parsed csv file's data into an Amazon CPM Chart Object
 * @param {Array<Array<string>>} parsedCsv The parsed CSV returned from running papaparse's `parse` function on the imported CSV file
 * @returns {{[amznbidKey: string]: number}} The CPM Chart Object (where the key is the "amznbid" key, and the value is the CPM dollar amount)
 */
export default function generateAmazonCPMChart(parsedCsv) {
  // Find the start of the Amazon line item rows
  // NOTE: We assume all cell values have been converted to lowercase!
  const headerRowIndex = parsedCsv.findIndex(
    (row) => row.includes('rate') && row.includes('custom targeting')
  );
  if (headerRowIndex === -1) {
    throw new Error('Could not find "Rate" and "Custom Targeting" columns in the CSV');
  }

  const headerRow = parsedCsv[headerRowIndex];
  const costTypeColumnIndex = headerRow.indexOf('cost type'); // optional, if included, only includes line item rows whose cost type is "CPM"
  const rateColumnIndex = headerRow.indexOf('rate');
  const customTargetingColumnIndex = headerRow.indexOf('custom targeting');

  const lineItemRows = parsedCsv.slice(headerRowIndex + 1);

  return lineItemRows.reduce((amazonCPMChartObject, lineItemRow) => {
    // Only parse "amznbid" key and corresponding CPM value if the line item row cost type indicated is "CPM" (or just assume it's CPM if the "Cost Type" column doesn't exist)
    const hasCostType = costTypeColumnIndex > -1;
    const shouldParseRow = hasCostType ? lineItemRow[costTypeColumnIndex] === 'cpm' : true;
    if (shouldParseRow) {
      const amznbidKeyCell = lineItemRow[customTargetingColumnIndex];
      const amznbidKey = amznbidKeyCell.match(/amznbid\s?=\s?"(\w+)"/)?.[1]; // [1] is the captured group

      const cpmValueCell = lineItemRow[rateColumnIndex];
      const cpmValueString = cpmValueCell.match(/(?:\d*\.)?\d+/)?.[0]; // parse out the number value; [0] is the whole match (minus any currency symbols, etc.)
      const cpmValue = round(parseFloat(cpmValueString), 2); // round parsed CPM value to 2 decimal places (will return NaN if no number value could be matched in `cpmValueCell`)

      if (amznbidKey && cpmValue) {
        if (amazonCPMChartObject[amznbidKey] && amazonCPMChartObject[amznbidKey] !== cpmValue) {
          // All line items which target a certain "amznbid" key should have the same CPM value
          throw new Error(`Conflicting CPM values found for "amznbid" key: "${amznbidKey}"`);
        } else {
          amazonCPMChartObject[amznbidKey] = cpmValue;
        }
      }
    }
    return amazonCPMChartObject;
  }, {});
}
