import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, List, Space } from 'antd';
import React from 'react';
import styles from './FormItemList.module.less';

const FormItemList = ({
  name,
  disabled,
  addPrompt = 'Add',
  renderAddItem, // optional - used to render a custom 'Add' component (`addPrompt` is ignored when this prop is provided)
  addInitialValue,
  renderFormItem,
}) => (
  <Form.List name={name}>
    {(fields, { add, remove }, { errors }) => {
      const addItem = disabled ? null : renderAddItem ? (
        renderAddItem({ add })
      ) : (
        <Button type="dashed" block icon={<PlusOutlined />} onClick={() => add(addInitialValue)}>
          {addPrompt}
        </Button>
      );

      return (
        <>
          <Space direction="vertical" size={12} style={{ width: '100%' }}>
            {fields.length > 0 ? (
              <List
                dataSource={fields}
                renderItem={(field) => (
                  <List.Item
                    extra={
                      !disabled ? (
                        <CloseCircleOutlined
                          className={styles.deleteField}
                          onClick={() => remove(field.name)}
                        />
                      ) : null
                    }
                  >
                    <Form.Item key={field.key} style={{ marginBottom: 0, flexGrow: 1 }}>
                      {renderFormItem({
                        field: {
                          ...field,
                          style: { marginBottom: 0 },
                        },
                        formItemListName: name,
                        disabled,
                      })}
                    </Form.Item>
                  </List.Item>
                )}
              />
            ) : null}
            {addItem}
          </Space>
          <Form.ErrorList errors={errors} />
        </>
      );
    }}
  </Form.List>
);

export default FormItemList;
