import { Alert, Form } from 'antd';
import React from 'react';
import { CodeEditor } from '../../../components';
import { validateJson } from '../../../services/utils';

const priceFloorPlaceholder = `{
  countries: { CA: 0.5, UK: 0.5, US: 0.5 },
  currency: 'USD',
}`;

const PriceFloorForm = ({ formInstance, isViewOnly, saveError }) => (
  <Form
    name="settings-pricefloor-config"
    form={formInstance}
    disabled={isViewOnly}
    preserve={false}
    requiredMark={false}
  >
    <Form.Item
      name={['value', 'priceFloorConfig']}
      rules={[
        {
          required: true,
          validator: (rule, value) => validateJson(value),
          message: 'Please enter a valid Price floor config JSON!',
        },
      ]}
    >
      <CodeEditor
        placeholder={priceFloorPlaceholder}
        height={250}
        disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
      />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const settingConfig = {
  name: 'Price Floor Config',
  description: 'A config JSON object used to set a custom price floor.',
  Form: PriceFloorForm,
  // Transform to and from JS Object <-> JSON string because form must use string object but we want to store the value as an Object
  transformBeforeInit: ({ priceFloorConfig, ...rest }) => ({
    priceFloorConfig: JSON.stringify(priceFloorConfig, null, 2),
    ...rest,
  }),
  transformBeforeSave: ({ priceFloorConfig, ...rest }) => ({
    priceFloorConfig: JSON.parse(priceFloorConfig),
    ...rest,
  }),
  initialSettingValue: {},
};

export default settingConfig;
