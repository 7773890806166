import { Alert, Form, Select } from 'antd';

const displayName = 'Refresh with BT Engage';

const unitVisibilityOptions = [
  { value: 'VISIBLE', label: 'Only visible ad units' },
  { value: 'HIDDEN', label: 'Only hidden ad units' },
  { value: 'ANY', label: 'All ad units' },
  { value: null, label: 'None' },
];

const refreshOnReturnToPageForm = ({ formInstance, isViewOnly, saveError }) => (
  <Form
    name="settings-refresh-on-rtp"
    form={formInstance}
    disabled={isViewOnly}
    preserve={false}
    requiredMark={false}
  >
    <Form.Item
      name={['value', 'unitVisibility']}
      label="Ad Units to Refresh"
      tooltip={`An ad unit is considered "visible" if at least 50% of it is within the user's viewport the moment they return to the page (otherwise, it's considered "hidden").`}
    >
      <Select options={unitVisibilityOptions} />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const settingConfig = {
  name: displayName,
  description: `Refreshes ad units when the user returns to the page (e.g. when switching back to the page's browser tab).`,
  Form: refreshOnReturnToPageForm,
  initialSettingValue: { unitVisibility: 'VISIBLE' },
};

export default settingConfig;
