import { CopyOutlined, DeleteOutlined, EyeOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Divider, message, Modal, Space, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import { SearchableEntityTable, ShortenedItemsWithTooltip } from '../../../components';
import api from '../../../services/api';
import { TIME_ZONE } from '../../../services/constants';
import { confirmAction, confirmDelete, DeleteEntityPopconfirm } from '../../../services/handlers';
import { useAsync } from '../../../services/hooks';
import { capitalize, compareChronologically } from '../../../services/utils';

const DeleteReport = ({ reportId, refetchReports }) => {
  const { execute: deleteReport } = useAsync(() => api.deleteReport(reportId));

  return (
    <DeleteEntityPopconfirm
      prompt="Are you sure you want to delete this report?"
      deleteEntity={deleteReport}
      onDeleted={() => {
        // Show success message and trigger report refresh to reflect deletion
        message.success('Report successfully deleted!');
        refetchReports();
      }}
    />
  );
};

const GenerateSelectedReports = ({ selectedReportIds, selectedReports, clearSelection, orgId }) => {
  const { execute: generateReports } = useAsync(() =>
    api.generateReports({ ids: selectedReportIds, org_id: orgId })
  );

  return (
    <Button
      icon={<SendOutlined />}
      disabled={selectedReports.some(({ recipients }) => !recipients?.length)} // Reports without recipients cannot be generated
      onClick={() => {
        confirmAction({
          prompt: `Are you sure you want to generate ${
            selectedReportIds.length === 1
              ? 'this report'
              : `these ${selectedReportIds.length} reports`
          }?`,
          action: generateReports,
          onSuccess: () => {
            // Clear selection and show success modal
            clearSelection();
            Modal.success({
              content: (
                <div>
                  <p>
                    The report{selectedReportIds.length === 1 ? ' is' : 's are'} being generated.
                  </p>
                  {selectedReportIds.length === 1 ? (
                    <p>Once generated this report will be emailed to its associated recipients.</p>
                  ) : (
                    <p>
                      Once generated these reports will be emailed to their associated recipients.
                    </p>
                  )}
                </div>
              ),
            });
          },
          errorMsgPrefix: `Failed to generate report${selectedReportIds.length === 1 ? '' : 's'}.`,
        });
      }}
    >
      Generate
    </Button>
  );
};

const DuplicateSelectedReports = ({ selectedReportIds, clearSelection, refetchReports, orgId }) => {
  const { execute: duplicateReports } = useAsync(() =>
    api.duplicateReports(selectedReportIds, orgId)
  );

  return (
    <Button
      icon={<CopyOutlined />}
      onClick={() => {
        confirmAction({
          prompt: `Are you sure you want to duplicate ${
            selectedReportIds.length === 1
              ? 'this report'
              : `these ${selectedReportIds.length} reports`
          }?`,
          action: duplicateReports,
          onSuccess: () => {
            // Clear selection, show success message, and trigger report refresh to reflect duplication
            clearSelection();
            message.success(
              `Report${selectedReportIds.length === 1 ? '' : 's'} successfully duplicated!`
            );
            refetchReports();
          },
          errorMsgPrefix: `Failed to duplicate report${selectedReportIds.length === 1 ? '' : 's'}.`,
        });
      }}
    >
      Duplicate
    </Button>
  );
};

const DeleteSelectedReports = ({
  selectedReportIds,
  selectedReports,
  clearSelection,
  refetchReports,
}) => {
  const { execute: deleteReports } = useAsync(() => api.deleteReports(selectedReportIds));

  return (
    <Button
      danger
      icon={<DeleteOutlined />}
      disabled={selectedReports.some(({ global }) => !!global)} // Global reports can't be deleted
      onClick={() => {
        confirmDelete({
          prompt: `Are you sure you want to delete ${
            selectedReportIds.length === 1
              ? 'this report'
              : `these ${selectedReportIds.length} reports`
          }?`,
          deleteEntity: deleteReports,
          onDeleted: () => {
            // Clear selection, show success message, and trigger report refresh to reflect deletion
            clearSelection();
            message.success(
              `Report${selectedReportIds.length === 1 ? '' : 's'} successfully deleted!`
            );
            refetchReports();
          },
          errorMsgPrefix: `Failed to delete report${selectedReportIds.length === 1 ? '' : 's'}.`,
        });
      }}
    >
      Delete
    </Button>
  );
};

const selectedReportsActions = ({
  selectedRowKeys: selectedReportIds,
  selectedRows: selectedReports,
  clearSelection,
  refetchReports,
  orgId,
}) => [
  <GenerateSelectedReports
    key="generate-selected"
    selectedReportIds={selectedReportIds}
    selectedReports={selectedReports}
    orgId={orgId}
    clearSelection={clearSelection}
    refetchReports={refetchReports}
  />,
  <DuplicateSelectedReports
    key="duplicate-selected"
    selectedReportIds={selectedReportIds}
    clearSelection={clearSelection}
    refetchReports={refetchReports}
    orgId={orgId}
  />,
  <DeleteSelectedReports
    key="delete-selected"
    selectedReportIds={selectedReportIds}
    selectedReports={selectedReports}
    clearSelection={clearSelection}
    refetchReports={refetchReports}
  />,
];

const SavedReports = ({ orgId }) => {
  const navigate = useNavigate();
  const reportsSwrKey = orgId ? ['/ReportList', orgId] : null;
  const refetchReports = () => {
    if (reportsSwrKey) {
      mutate(reportsSwrKey);
    }
  };

  return (
    <SearchableEntityTable
      title="Saved Reports"
      swrKey={reportsSwrKey}
      swrFetcher={() => api.listReports({ org_id: orgId })}
      textSearchFieldNames={['name']}
      textSearchPlaceholder="Search reports by name..."
      selectable={{
        entityTerm: 'Report',
        actions: selectedReportsActions,
        actionsProps: { refetchReports, orgId },
      }}
      actions={[
        <Button
          key="add-report"
          type="primary"
          onClick={() => navigate(`/reports/create?org_id=${orgId}`)}
        >
          Create New Report
        </Button>,
      ]}
      columns={[
        {
          title: 'Report Name',
          dataIndex: 'name',
          defaultSortOrder: 'ascend',
          sortDirections: ['ascend', 'descend', 'ascend'],
          sorter: (a, b) => (a.global || b.global ? 0 : a.name.localeCompare(b.name)),
          render: (_, { id, name, global, org_id }) =>
            global ? (
              // Pass an org id to scope the Global Template Report to (unlike non-global reports, we can't use the org id associated with the report entity)
              <Link to={`/reports/${id}?org_id=${org_id}`}>
                <Tooltip
                  title="Pre-configured reports cannot be modified or deleted. Duplicate this report to edit or view the report to generate."
                  overlayStyle={{ maxWidth: 320 }}
                >
                  <EyeOutlined />
                </Tooltip>{' '}
                {name}
              </Link>
            ) : (
              <Link to={`/reports/${id}`}>{name}</Link>
            ),
          width: 240,
        },
        {
          title: 'Created By',
          dataIndex: 'created_by',
          sortDirections: ['ascend', 'descend', 'ascend'],
          sorter: (a, b) => (a.global || b.global ? 0 : a.created_by.localeCompare(b.created_by)),
          render: (_, { created_by, global }) => (global ? 'Blockthrough' : created_by),
        },
        {
          title: 'Recipients',
          dataIndex: 'recipients',
          render: (_, { recipients, global }) =>
            global ? '-' : <ShortenedItemsWithTooltip items={recipients} />,
        },
        {
          title: `Created (${TIME_ZONE})`,
          dataIndex: 'created_at',
          sortDirections: ['descend', 'ascend', 'descend'],
          sorter: (a, b) =>
            a.global || b.global ? 0 : compareChronologically(a.created_at, b.created_at),
          render: (dateISOString, { global }) => {
            const date = moment(dateISOString);
            return global ? (
              '-'
            ) : (
              <Space>
                <span style={{ whiteSpace: 'nowrap' }}>{date.format('YYYY-MM-DD')}</span>
                <span>{date.format('(HH:mm)')}</span>
              </Space>
            );
          },
          width: 200,
        },
        {
          title: `Updated At (${TIME_ZONE})`,
          dataIndex: 'updated_at',
          sortDirections: ['descend', 'ascend', 'descend'],
          sorter: (a, b) =>
            a.global || b.global ? 0 : compareChronologically(a.updated_at, b.updated_at),
          render: (dateISOString, { global }) => {
            const date = moment(dateISOString);
            return global ? (
              '-'
            ) : (
              <Space>
                <span style={{ whiteSpace: 'nowrap' }}>{date.format('YYYY-MM-DD')}</span>
                <span>{date.format('(HH:mm)')}</span>
              </Space>
            );
          },
          width: 215,
        },
        {
          title: 'Schedule',
          dataIndex: 'schedule',
          render: (schedule) => <Space>{schedule ? capitalize(schedule) : '-'}</Space>,
        },
        {
          key: 'actions',
          render: (_, { id, global }) =>
            global ? null : (
              <Space size={0} split={<Divider type="vertical" />}>
                <DeleteReport reportId={id} refetchReports={refetchReports} />
              </Space>
            ),
          width: 50,
        },
      ]}
    />
  );
};

export default SavedReports;
