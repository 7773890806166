import { Form, Input } from 'antd';
import styles from './ButtonColorFormItem.module.less';

const ButtonColorFormItem = ({ env, form }) => {
  const ctaButtonColor = Form.useWatch([env, 'am_settings', 'cta_button_color'], form);

  return (
    <Form.Item
      label="Call to Action Button Color"
      tooltip={'Any valid CSS format is accepted: hex, rgb, hsl, css color names.'}
    >
      <div className={styles.buttonColorFormItemContainer}>
        <Form.Item
          noStyle
          name={[env, 'am_settings', 'cta_button_color']}
          rules={[
            {
              message: 'Value must use a valid css color format.',
              validator: (_, value) => {
                if (!value.trim() || CSS.supports('background-color', value)) {
                  return Promise.resolve();
                }

                return Promise.reject();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div
          aria-hidden
          className={styles.colorIndicator}
          style={{ backgroundColor: ctaButtonColor }}
        ></div>
      </div>
    </Form.Item>
  );
};

export default ButtonColorFormItem;
