import db from './database';
import moment from 'moment';
import { sumBy } from 'lodash';

export const orgs = {
  list: () => ({ orgs: db.orgs }),
  create: ({ name }) => {
    if (db.orgs.find((org) => org.name === name)) {
      throw Error(`ErrCode: 1, error: organization name ${name} already exists`);
    }
    const org = { id: 5555555555555555, name, creation_date: moment().toISOString() };
    db.orgs = [...db.orgs, org];
    return org;
  },
};

function getDataByDateRange(data, startDate, endDate, { dateKey = 'ts' } = {}) {
  return data.filter((x) => {
    const xMoment = moment(x[dateKey]);
    return (
      (startDate ? xMoment.isSameOrAfter(startDate) : true) &&
      (endDate ? xMoment.isSameOrBefore(endDate) : true)
    );
  });
}
function randomizeDataByResourceId(data, resourceId, { valueKey = 'value' } = {}) {
  const orgMultiplyer = resourceId ? (resourceId % 9) + 1 : 80; // multiply mock data by 80 if no resourceId is provided (simulates data returned for all orgs)
  return data.map((x) => ({ ...x, [valueKey]: x[valueKey] * orgMultiplyer }));
}

const metricsListWithTotal = ({ id, start_date, end_date }, data, formatTotal = (x) => x) => {
  const dataByDateAndOrg = randomizeDataByResourceId(
    getDataByDateRange(data, start_date, end_date),
    id
  );
  return {
    data: dataByDateAndOrg,
    total: formatTotal(sumBy(dataByDateAndOrg, 'value')),
  };
};

export const metrics = {
  pageViews: {
    list: (params) => metricsListWithTotal(params, db.metrics.pageViews),
  },
  aaPageViews: {
    list: (params) => metricsListWithTotal(params, db.metrics.aaPageViews),
  },
  auctions: {
    list: (params) => metricsListWithTotal(params, db.metrics.auctions),
  },
  impressions: {
    list: (params) => metricsListWithTotal(params, db.metrics.impressions),
  },
  grossRevenue: {
    list: (params) =>
      metricsListWithTotal(params, db.metrics.grossRevenue, (total) => +total.toFixed(2)),
  },
  cpm: {
    list: (params) => metricsListWithTotal(params, db.metrics.cpm, (total) => +total.toFixed(2)),
  },
  topSites: {
    list: (params) => ({ org_id: params.org_id, top_sites: db.metrics.topSites.byRevenue }), // TODO: Support date ranges & other metric types if necessary
  },
};
