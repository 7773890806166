import { Spin } from 'antd';
import React from 'react';
import { generateErrorMessage } from '../services/utils';

const OnceLoaded = ({ render, isLoading, error, children }) => {
  if (error) {
    return <div>{generateErrorMessage(error)}</div>;
  } else if (isLoading) {
    return (
      <div style={{ paddingTop: 100, textAlign: 'center' }}>
        <Spin tip="Loading" />
      </div>
    );
  } else {
    return render ? render() : children;
  }
};

export default OnceLoaded;
