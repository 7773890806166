import React from 'react';
import { Button, Space, Table, Tag, Typography } from 'antd';
import moment from 'moment';
import { TIME_ZONE } from '../../../services/constants';

import {
  LINEITEM_ACTIONS,
  LINEITEM_TYPES_OPTIONS,
  JOB_STATUSES,
  JOB_STATUS_TAG_COLOURS,
  JOB_SCHEDULES,
  JOB_RUN_STATUS_TAG_COLOURS,
  STATUS_TAG_LABELS,
} from './lineItemJobConstants';

import JobActions, { getLineItemFilters } from './JobActions';

const getLineItemJobsColumns = (jobs, setShowJobRuns, setCurrentJob, isViewOnly) =>
  [
    {
      key: 'name',
      render: (_, job) => {
        const name =
          job.schedule === JOB_SCHEDULES.JOB_SCHEDULE_HOURLY
            ? 'Scheduled Exclusion'
            : 'Manual Exclusion';
        return <Space>{name}</Space>;
      },
    },
    {
      title: 'Run Status',
      dataIndex: 'run_status',
      render: (run_status) => (
        <Tag color={JOB_RUN_STATUS_TAG_COLOURS[run_status] || 'default'}>
          {STATUS_TAG_LABELS[run_status] || 'Unavailable'}
        </Tag>
      ),
    },
    {
      title: 'Filter Criteria',
      dataIndex: 'line_item_actions',
      render: (line_item_actions) => (
        <Space>
          {getLineItemFilters(line_item_actions, LINEITEM_ACTIONS.LINEITEM_ACTION_EXCLUSION)
            ?.map((filter) => LINEITEM_TYPES_OPTIONS.find((type) => type.value === filter).label)
            .join(', ')}
        </Space>
      ),
      width: 175,
    },
    {
      title: 'Schedule',
      dataIndex: 'schedule',
      render: (schedule) => {
        return <Space>{schedule === JOB_SCHEDULES.JOB_SCHEDULE_HOURLY ? 'Hourly' : '-'}</Space>;
      },
    },
    {
      title: `Next Scheduled Run (${TIME_ZONE})`,
      dataIndex: 'next_scheduled_run',
      render: (next_scheduled_run, job) => {
        const date = moment(next_scheduled_run);

        return (
          <Space>
            {job.schedule !== JOB_SCHEDULES.JOB_SCHEDULE_HOURLY ||
            !date.isValid() ||
            job.job_status === JOB_STATUSES.JOB_STATUS_DISABLED ? (
              '-'
            ) : (
              <>
                <span style={{ whiteSpace: 'nowrap' }}>{date.format('YYYY-MM-DD')}</span>
                <span>{date.format('(HH:mm)')}</span>
              </>
            )}
          </Space>
        );
      },
    },
    {
      title: 'Jobs Run',
      key: 'jobs_run',
      render: (_, job) => {
        const onClick = () => {
          setShowJobRuns(true);
          setCurrentJob(job);
        };
        return job.has_job_run ? (
          <Button type="link" onClick={onClick}>
            View Jobs
          </Button>
        ) : (
          <Typography.Text style={{ color: '#adadad' }} italic>
            No Jobs Run
          </Typography.Text>
        );
      },
    },
    {
      title: 'Job Status',
      dataIndex: 'job_status',
      render: (job_status) => {
        return (
          <Tag color={JOB_STATUS_TAG_COLOURS[job_status] || 'default'}>
            {STATUS_TAG_LABELS[job_status]}
          </Tag>
        );
      },
    },
    {
      key: 'job_actions',
      render: (_, job) => <JobActions job={job} jobs={jobs} />,
      width: 140,
      hidden: isViewOnly,
    },
  ].filter((column) => !column.hidden);

const LineItemJobs = ({ jobs, setShowJobRuns, setCurrentJob, isViewOnly }) => (
  <Table
    columns={getLineItemJobsColumns(jobs, setShowJobRuns, setCurrentJob, isViewOnly)}
    dataSource={jobs}
    pagination={false}
    rowKey="id"
  />
);

export default LineItemJobs;
