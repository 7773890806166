import React from 'react';

/**
 * Greys-out its children contents when `isDisabled` is true.
 */
const DisabledContent = ({ isDisabled, component = 'div', children }) =>
  React.createElement(
    component,
    {
      style: {
        transition: 'opacity 0.3s',
        opacity: isDisabled ? '0.4' : '1',
      },
    },
    children
  );

export default DisabledContent;
