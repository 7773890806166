import { Typography } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';

export const JOB_RUN_STATUSES = {
  JOB_RUN_STATUS_PENDING_CREATE: 'JOB_RUN_STATUS_PENDING_CREATE',
  JOB_RUN_STATUS_RUNNING: 'JOB_RUN_STATUS_RUNNING',
  JOB_RUN_STATUS_FAIL: 'JOB_RUN_STATUS_FAIL',
};

export const JOB_STATUSES = {
  JOB_STATUS_ENABLED: 'JOB_STATUS_ENABLED',
  JOB_STATUS_DISABLED: 'JOB_STATUS_DISABLED',
};

export const JOB_SCHEDULES = {
  JOB_SCHEDULE_OFF: 'JOB_SCHEDULE_OFF',
  JOB_SCHEDULE_HOURLY: 'JOB_SCHEDULE_HOURLY',
};

export const LINEITEM_ACTIONS = {
  LINEITEM_ACTION_EXCLUSION: 'LINEITEM_ACTION_EXCLUSION',
  LINEITEM_ACTION_REVERT_EXCLUSION: 'LINEITEM_ACTION_REVERT_EXCLUSION',
  LINEITEM_ACTION_IGNORE: 'LINEITEM_ACTION_IGNORE',
};

export const LINEITEM_TYPES_OPTIONS = [
  { value: 'bulk', label: 'Bulk' },
  { value: 'house', label: 'House' },
  { value: 'network', label: 'Network' },
  { value: 'sponsorship', label: 'Sponsorship' },
  { value: 'standard', label: 'Standard' },
];

export const LINEITEM_TYPES = ['bulk', 'house', 'network', 'sponsorship', 'standard'];

export const JOB_RUN_STATUS_TAG_COLOURS = {
  JOB_RUN_STATUS_RUNNING: 'blue',
  JOB_RUN_STATUS_ERROR: 'red',
  JOB_RUN_STATUS_IDLE: 'green',
  JOB_RUN_STATUS_SUCCESS: 'green',
  JOB_RUN_STATUS_PARTIAL_SUCCESS: 'default',
  JOB_RUN_STATUS_FAIL: 'red',
};

export const JOB_STATUS_TAG_COLOURS = {
  JOB_STATUS_ENABLED: 'green',
  JOB_STATUS_ERROR: 'red',
};

export const STATUS_TAG_LABELS = {
  JOB_RUN_STATUS_RUNNING: 'Running',
  JOB_RUN_STATUS_ERROR: 'Error',
  JOB_RUN_STATUS_PENDING_CREATE: 'Pending Create',
  JOB_RUN_STATUS_UNSPECIFIED: 'Unspecified',
  JOB_RUN_STATUS_IDLE: 'Idle',
  JOB_STATUS_ENABLED: 'Enabled',
  JOB_STATUS_DISABLED: 'Disabled',
  JOB_STATUS_ERROR: 'Error',
  JOB_STATUS_UNAVAILABLE: 'Unavailable',
  JOB_RUN_STATUS_SUCCESS: 'Success',
  JOB_RUN_STATUS_PARTIAL_SUCCESS: 'Partial Success',
  JOB_RUN_STATUS_FAIL: 'Fail',
};

export const JOB_ACTIONS_MODAL_PROPS = {
  delete: {
    title: 'Deleting this exclusion will interrupt the job run',
    okText: 'Delete Exclusion',
    modalText: (
      <>
        <Typography.Text>
          This action will end all running jobs and permanently remove the set of exclusion
          settings.
        </Typography.Text>
        <Typography.Text>
          Deleting this exclusion will not change any line items. Take the "Edit" action to revert
          the exclusion on line items already excluded.
        </Typography.Text>
      </>
    ),
  },
  dry_run: {
    title: 'Dry Run',
    okText: 'Trigger Dry Run',
    modalText: (
      <Typography.Text>
        Triggering a dry run will only run a simulation of the line item exclusions based on the
        selected exclusion criteria. A dry run will not apply any changes to the publisher's GAM.
      </Typography.Text>
    ),
  },
  manual_run: {
    title: 'Trigger Run',
    okText: 'Trigger Run',
    modalText: (
      <Typography.Text>
        Triggering a run will change line items in the publisher's GAM by applying targeting to the
        selected exclusion criteria. Any scheduled jobs will be disabled.
      </Typography.Text>
    ),
  },
  schedule: {
    title: 'Schedule',
    okText: 'Schedule Exclusion',
    modalText: (
      <Typography.Text>
        This exclusion will be scheduled to run every hour to exclude newly added line items in the
        publishers's GAM.
      </Typography.Text>
    ),
  },
  edit: {
    title: 'Edit Exclusion',
    okText: 'Save Changes',
  },
};

export const JOB_RUN_TYPE_LABELS = {
  LINEITEM_JOB_RUN_TYPE_MANUAL: 'Manual',
  LINEITEM_JOB_RUN_TYPE_DRYRUN: 'Dry Run',
  LINEITEM_JOB_RUN_TYPE_SCHEDULED: 'Scheduled',
  LINEITEM_JOB_RUN_TYPE_UNSPECIFIED: 'Unspecified',
};

export const JOB_RUN_TYPE_COLOURS = {
  LINEITEM_JOB_RUN_TYPE_MANUAL: 'geekblue',
  LINEITEM_JOB_RUN_TYPE_SCHEDULED: 'blue',
};

export const JOB_RUN_STATUS_ICONS = {
  JOB_RUN_STATUS_RUNNING: <LoadingOutlined />,
  JOB_RUN_STATUS_SUCCESS: <CheckCircleOutlined />,
  JOB_RUN_STATUS_PARTIAL_SUCCESS: <ExclamationCircleOutlined />,
  JOB_RUN_STATUS_FAIL: <ExclamationCircleOutlined />,
};
