import { DownloadOutlined, ExportOutlined } from '@ant-design/icons';
import { Alert, Button, Space, message } from 'antd';
import React from 'react';
import api from '../../services/api';
import { NoSaveModal } from '../../services/handlers';
import { useAsync, useSessionUser } from '../../services/hooks';

const ExportAdUnitCSV = ({ org, numOrgWebsites, selectedWebsites }) => {
  const { email: userEmail } = useSessionUser();
  const { execute: orgAdUnitsDownload, status: orgAdUnitsDownloadStatus } = useAsync(
    api.orgExportAdUnits
  );
  const { execute: websiteAdUnitsDownload, status: websiteAdUnitsDownloadStatus } = useAsync(
    api.websiteExportAdUnits
  );
  const isLoading =
    orgAdUnitsDownloadStatus === 'pending' || websiteAdUnitsDownloadStatus === 'pending';

  const downloadCSV = (selectedWebsites, org, closeModal) => {
    const TIMEOUT_MESSAGE_REGEX = /timeout of \d+ms exceeded/;
    if (!selectedWebsites) {
      orgAdUnitsDownload({ org_id: org.id })
        .catch((error) => {
          if (error.match(TIMEOUT_MESSAGE_REGEX)) {
            api
              .orgEmailAdUnits({ org_id: org.id })
              .then(() => message.success(`Ad units CSV has been sent to ${userEmail}`))
              .catch((e) => message.error(e.message));
          } else {
            message.error(error);
          }
        })
        .finally(closeModal);
    } else {
      const selectedWebsiteIds = selectedWebsites
        .map((website) => `websiteID=${website.id}`)
        .join('&');
      websiteAdUnitsDownload({ selectedWebsiteIds })
        .catch((error) => {
          if (error.match(TIMEOUT_MESSAGE_REGEX)) {
            const website_ids = selectedWebsites.map((selectedWebsite) => selectedWebsite.id);
            api
              .websiteEmailAdUnits({ website_ids })
              .then(() => message.success(`Ad units CSV has been sent to ${userEmail}`))
              .catch((e) => message.error(e.message));
          } else {
            message.error(error);
          }
        })
        .finally(closeModal);
    }
  };

  return (
    <NoSaveModal
      buttonText="Export Spaces"
      buttonProps={{
        type: 'default',
        icon: <ExportOutlined />,
      }}
      modalTitle="Export Static BT Spaces (Production)"
      footerRender={({ closeModal }) => (
        <Space>
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={() => downloadCSV(selectedWebsites, org, closeModal)}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Download CSV'}
          </Button>
        </Space>
      )}
    >
      <p>
        {`This CSV file contains the Static BT Spaces for ${
          selectedWebsites ? `${selectedWebsites.length} selected` : 'all'
        }
        ${org.name}'s website(s) in production.`}
      </p>
      {!selectedWebsites && numOrgWebsites > 25 ? (
        <Alert
          message={`CAUTION: Because this organization has ${numOrgWebsites} websites; the exported CSV file may be quite large and require a bit of time to download. Please be patient.`}
          type="warning"
        />
      ) : null}
      {selectedWebsites && selectedWebsites.length > 25 ? (
        <Alert
          message={`CAUTION: Because you've selected ${selectedWebsites.length} websites; the exported CSV file may be quite large and require a bit of time to download. Please be patient.`}
          type="warning"
        />
      ) : null}
    </NoSaveModal>
  );
};

export default ExportAdUnitCSV;
