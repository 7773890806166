import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Collapse, Dropdown, Form, Input, Select, Space } from 'antd';
import React from 'react';
import { FormItemList } from '../..';
import { STRING_MATCH_OPTIONS_SINGLE, WhenStringProp } from './formComponents';

const slotPropLabelByKey = { elementId: 'Slot div ID', adUnitPath: 'Slot Path' };

const Mapping = ({ formInstance, field, disabled, parentFieldNamePath }) => {
  const { method, slotPropKey } =
    formInstance.getFieldValue([...parentFieldNamePath, field.name]) || {};

  const slotPropLabel = slotPropLabelByKey[slotPropKey];

  // Render different form items depending on the mapping method
  switch (method) {
    case 'slotPropToSpaceName':
      return (
        <Form.Item label={`If ${slotPropLabel}`} colon={false} style={field.style}>
          <Space size="small">
            <Form.Item
              {...field}
              key={`${field.key}-whenSlotProp`}
              name={[field.name, 'whenSlotProp']}
              noStyle
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={STRING_MATCH_OPTIONS_SINGLE}
                disabled={disabled}
                size="small"
                dropdownMatchSelectWidth={false}
              />
            </Form.Item>
            <span>Space Name</span>
          </Space>
        </Form.Item>
      );
    case 'slotPropToString':
      return (
        <>
          <WhenStringProp
            propKey="slotProp"
            propDisplayName={slotPropLabel}
            conditionPropKey="whenSlotProp"
            field={field}
            disabled={disabled}
            formInstance={formInstance}
            parentFieldNamePath={parentFieldNamePath}
            tagsSelectorInputWidth="100%"
          />
          <Space>
            <Form.Item
              {...field}
              label={`Target Space`}
              colon={false}
              key={`${field.key}-spaceName`}
              name={[field.name, 'spaceName']}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: `Please input the name of the Space to map to!`,
                },
              ]}
            >
              <Input disabled={disabled} size="small" style={{ width: 200 }} />
            </Form.Item>
          </Space>
        </>
      );
    default:
      return null;
  }
};

const Exception = ({ formInstance, field, disabled, parentFieldNamePath }) => {
  const { method, slotPropKey } =
    formInstance.getFieldValue([...parentFieldNamePath, field.name]) || {};

  const slotPropLabel = slotPropLabelByKey[slotPropKey];

  // Render different form items depending on the exception method
  switch (method) {
    case 'slotPropToString':
      return (
        <WhenStringProp
          propKey="slotProp"
          propDisplayName={slotPropLabel}
          conditionPropKey="whenSlotProp"
          field={field}
          disabled={disabled}
          formInstance={formInstance}
          parentFieldNamePath={parentFieldNamePath}
          tagsSelectorInputWidth="100%"
        />
      );
    default:
      return null;
  }
};

const MapSlotsToSpacesForm = ({ formInstance, isViewOnly, saveError }) => (
  <Form
    name="settings-map-slots-to-spaces"
    form={formInstance}
    disabled={isViewOnly}
    preserve={false}
    requiredMark={false}
  >
    <Collapse defaultActiveKey={['mappings', 'exceptions']}>
      <Collapse.Panel key="mappings" header="Map googletag Slot to BT Space">
        <FormItemList
          name={['value', 'mappings']}
          disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
          renderAddItem={({ add }) => (
            <Dropdown
              menu={{
                items: [
                  {
                    label: 'Map googletag slot div ID to space name',
                    key: 'map-div-id-to-space-name',
                  },
                  {
                    label: 'Map googletag slot path to space name',
                    key: 'map-slot-path-to-space-name',
                  },
                  {
                    label: 'Map googletag slot div ID to custom string',
                    key: 'map-div-id-to-string',
                  },
                  {
                    label: 'Map googletag slot path to custom string',
                    key: 'map-slot-path-to-string',
                  },
                ],
                onClick: ({ key }) => {
                  switch (key) {
                    case 'map-div-id-to-space-name':
                      add({
                        method: 'slotPropToSpaceName',
                        slotPropKey: 'elementId',
                        whenSlotProp: 'equals',
                      });
                      break;
                    case 'map-slot-path-to-space-name':
                      add({
                        method: 'slotPropToSpaceName',
                        slotPropKey: 'adUnitPath',
                        whenSlotProp: 'equals',
                      });
                      break;
                    case 'map-div-id-to-string':
                      add({
                        method: 'slotPropToString',
                        slotPropKey: 'elementId',
                        whenSlotProp: 'equals',
                      });
                      break;
                    case 'map-slot-path-to-string':
                      add({
                        method: 'slotPropToString',
                        slotPropKey: 'adUnitPath',
                        whenSlotProp: 'equals',
                      });
                      break;
                    default:
                      break;
                  }
                },
              }}
            >
              <Button type="dashed" block icon={<PlusOutlined />}>
                Add mapping
              </Button>
            </Dropdown>
          )}
          renderFormItem={({ field, formItemListName, disabled }) => (
            <Mapping
              formInstance={formInstance}
              field={field}
              disabled={disabled}
              parentFieldNamePath={formItemListName}
            />
          )}
        />
      </Collapse.Panel>
      <Collapse.Panel key="exceptions" header="Bypass Mapping">
        <FormItemList
          name={['value', 'exceptions']}
          disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
          renderAddItem={({ add }) => (
            <Dropdown
              menu={{
                items: [
                  {
                    label: 'By googletag slot div ID',
                    key: 'by-div-id',
                  },
                  {
                    label: 'By googletag slot path',
                    key: 'by-slot-path',
                  },
                ],
                onClick: ({ key }) => {
                  switch (key) {
                    case 'by-div-id':
                      add({
                        method: 'slotPropToString',
                        slotPropKey: 'elementId',
                        whenSlotProp: 'equalsOneOf',
                      });
                      break;
                    case 'by-slot-path':
                      add({
                        method: 'slotPropToString',
                        slotPropKey: 'adUnitPath',
                        whenSlotProp: 'equalsOneOf',
                      });
                      break;
                    default:
                      break;
                  }
                },
              }}
            >
              <Button type="dashed" block icon={<PlusOutlined />}>
                Add exception to mapping
              </Button>
            </Dropdown>
          )}
          renderFormItem={({ field, formItemListName, disabled }) => (
            <Exception
              formInstance={formInstance}
              field={field}
              disabled={disabled}
              parentFieldNamePath={formItemListName}
            />
          )}
        />
      </Collapse.Panel>
    </Collapse>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const settingConfig = {
  name: 'Map googletag Slots to Spaces',
  description:
    "Whether or not (and how) to map slots defined in the GPT `googletag` variable on the page to BT Spaces (which are used as templates to create new Spaces with the same bidders, but with a selector that will match the slot's div ID).",
  Form: MapSlotsToSpacesForm,
  initialSettingValue: {},
};

export default settingConfig;
