import jwtDecode from 'jwt-decode';
import React, { createContext, useContext, useReducer } from 'react';
import { mutate } from 'swr';

const StoreContext = createContext();

const initialState = {
  isDev:
    process.env.REACT_APP_BUILD_ENV === 'local' ||
    process.env.REACT_APP_BUILD_ENV === 'development',
  isAuthenticated: () => {
    const tokenExpiryDate = localStorage.getItem('expires_at');
    return tokenExpiryDate && Date.now() / 1000 < tokenExpiryDate;
  },
  getExperimentalFeaturesFlag: () => {
    return !!localStorage.getItem('bt_dash_enable_experimental_features');
  },
  user: {},
  recentSearch: JSON.parse(localStorage.getItem('recent_search') || '[]'),
};

// Ensures old user data isn't returned from swr cache
const clearSWRCache = () => mutate(() => true, undefined, { revalidate: false });

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTHENTICATE':
      localStorage.setItem('expires_at', jwtDecode(action.token).exp);
      return { ...state };
    case 'UNAUTHENTICATE':
      localStorage.removeItem('expires_at');
      clearSWRCache();
      return { ...state, user: {} }; // Clear user data
    case 'LOAD_USER':
      return { ...state, user: { isLoaded: true, ...action.user } };
    case 'SET_USER_SCOPED_ORG':
      return { ...state, user: { ...state.user, scopedOrgId: action.scopedOrgId } };
    case 'IMPERSONATE_USER':
      return { ...state, impersonatedUser: { isLoaded: true, ...action.user } };
    case 'STOP_IMPERSONATING_USER':
      return { ...state, impersonatedUser: undefined };
    case 'SET_IMPERSONATED_USER_SCOPED_ORG':
      return {
        ...state,
        impersonatedUser: { ...state.impersonatedUser, scopedOrgId: action.scopedOrgId },
      };
    case 'ADD_TO_RECENT_SEARCH':
      return {
        ...state,
        recentSearch: addToRecentSearch(action.newEntry, state.recentSearch),
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>;
};

export const useStore = () => useContext(StoreContext);

/**
 * @typedef {Object} RecentSearchEntry
 * @property {Object} [org] // Either `org` or `website` need to be included
 * @property {Object} [website] // Either `org` or `website` need to be included
 */

/**
 * Returns a new list of recent search entries with `newEntry` at the beginning of `existingEntries`,
 * while ensuring there are at most 5 entries by removing the last (oldest) entry if needed.
 * Also sets the new recent search entries to localStorage.
 * @returns {Array<RecentSearchEntry>}
 */
function addToRecentSearch(newEntry, existingEntries) {
  // If `newEntry` already exists in `existingEntries`, omit it from `newEntries`
  // since it will be added to the beginning below; essentially moving it to the front/beginning.
  const newEntries = existingEntries.filter(
    ({ website, org }) =>
      !(
        (newEntry.org && org && newEntry.org.id === org.id) ||
        (newEntry.website && website && newEntry.website.id === website.id)
      )
  );

  // Add `newEntry` to the beginning of `newEntries`
  newEntries.unshift(newEntry);

  // Remove older entries if necessary
  if (newEntries.length > 5) {
    newEntries.splice(5);
  }

  // Update localStorage
  localStorage.setItem('recent_search', JSON.stringify(newEntries));

  return newEntries;
}
