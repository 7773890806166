import { ArrowLeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import { PageContents } from '../../layout';
import { useStore } from '../../services/store';
import styles from './Error.module.less';

const { Title } = Typography;

const ERROR_TEXT = {
  DEFAULT: {
    title: 'An unexpected error has occurred',
    description: `Apologies that didn't go as planned. Please try again and if this issue persists, reach out to success@blockthrough.com or your Publisher Success Manager for assistance.`,
  },
  '404': {
    title: 'Are you lost?',
    description: `We couldn't find the page you were looking for.`,
  },
  invite_already_used: {
    title: 'This invite has already been used',
    description: `It appears you have already accepted this invite, and thus should already have access to the organization you were invited to.`,
  },
  invite_expired: {
    title: 'This invite is no longer valid',
    description: `It appears this invite has expired. If you still require access to an organization, please request a new invitation from your administrator.`,
  },
  invite_not_found: {
    title: 'This invite could not be found',
    description: `We could not find this invite in our system. If you still require access to an organization, please request a new invitation from your administrator.`,
  },
  user_not_found: {
    title: 'A user for this invite could not be found',
    description: `We could not find a user associated with this invite in our system. If you still require access to an organization, please request a new invitation from your administrator and ensure the invitation is associated with the correct user email address.`,
  },
};

const ErrorMessage = ({ className, code }) => {
  const { state } = useStore();
  const isLoggedIn = state.isAuthenticated();

  const { title, description } = ERROR_TEXT[code] || ERROR_TEXT.DEFAULT;

  return (
    <Row className={[className, styles.errorMessage]} type="flex" justify="center" align="middle">
      <Col>
        <Space align="center" direction="vertical" size={40} style={{ width: '100%' }}>
          <ExclamationCircleOutlined style={{ fontSize: 100 }} />
          <Space align="center" direction="vertical" size={0} style={{ maxWidth: 650 }}>
            <Title level={2}>{title}</Title>
            <Title level={5}>{description}</Title>
          </Space>
          <Link to="/">
            <Button icon={<ArrowLeftOutlined />}>
              Return to {isLoggedIn ? 'the home page' : 'log in screen'}
            </Button>
          </Link>
        </Space>
      </Col>
    </Row>
  );
};

// Used for BE error redirects
export const ErrorRedirect = () => {
  // Extract error code from `code` URL search param
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  return <ErrorMessage code={code} className={styles.errorRedirect} />;
};

// Used for error pages of logged-in users
export const Error = ({ code }) => (
  <PageContents fillHeight>
    <Card style={{ height: '100%' }} bodyStyle={{ height: '100%' }}>
      <ErrorMessage code={code} />
    </Card>
  </PageContents>
);
