import { PlusOutlined } from '@ant-design/icons';
import { Input, Popconfirm, Tag, Tooltip } from 'antd';
import React, { useState } from 'react';

const tagStyle = {
  marginTop: 2,
  marginRight: 8,
  marginBottom: 2,
  marginLeft: 0,
  whiteSpace: 'normal', // overwrite .ant-tag `white-space` CSS rule
  overflowWrap: 'anywhere', // ensure extra-long tags with no whitespaces don't overflow
};

const addInputTextboxStyle = {
  lineHeight: '20px',
  minHeight: 0,
};

const TagsSelector = ({
  value: tags = [],
  onChange: setTags,
  validateTag,
  truncateTagsLongerThan, // optional - will truncate any characters after value provided & show ellipsis; hovering over text will show full text in tooltip
  inputWidth = 100,
  inputSize = 'small',
  addPrompt = 'Add',
  disabled,
}) => {
  const [isAddInputVisible, setIsAddInputVisible] = useState(false);
  const [addInputValue, setAddInputValue] = useState('');

  const [editIndex, setEditIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');

  const inputStyle = {
    ...tagStyle,
    height: 22,
    float: 'none',
  };
  inputStyle.width = inputWidth;

  const showAddInput = () => {
    setIsAddInputVisible(true);
  };
  const resetAndHideAddInput = () => {
    setIsAddInputVisible(false);
    setAddInputValue('');
  };
  const handleAddInputPressEnter = (e) => {
    // Enter will signal the user is finished editing, however Shift+Enter will simply add a new line
    if (!e.shiftKey) {
      const updatedTags = [...tags];

      addInputValue.split('\n').forEach((newTagRaw) => {
        // For each new tag, trim whitespace and only add if non-empty, valid, and unique
        const newTag = newTagRaw.trim();

        const tagIsEmpty = newTag === '';
        const tagIsUnique = !updatedTags.includes(newTag);
        const tagIsValid = validateTag ? validateTag(newTag) : true;
        if (!tagIsEmpty && tagIsUnique && tagIsValid) {
          updatedTags.push(newTag);
        }
      });
      setTags(updatedTags);
      resetAndHideAddInput();
    }
  };

  const editTag = (editIndex) => {
    setEditIndex(editIndex);
    setEditInputValue(tags[editIndex]);
  };
  const resetAndHideEditInput = () => {
    setEditIndex(-1);
    setEditInputValue('');
  };
  const handleEditInputPressEnter = () => {
    // Trim updated tag whitespace and only save if valid and still unique
    const oldTag = tags[editIndex];
    const editedTag = editInputValue.trim();

    const tagIsEmpty = editedTag === '';
    const tagIsUnchanged = editedTag === oldTag;
    if (tagIsEmpty || tagIsUnchanged) {
      resetAndHideEditInput();
    } else {
      const tagIsUnique = !tags.includes(editedTag);
      const tagIsValid = validateTag ? validateTag(editedTag) : true;

      if (tagIsUnique && tagIsValid) {
        const updatedTags = [...tags];
        updatedTags[editIndex] = editedTag;
        setTags(updatedTags);
        resetAndHideEditInput();
      }
    }
  };

  const clearAllTags = () => {
    setTags([]);
  };

  const removeTag = (removeIndex) => {
    setTags(tags.filter((_tag, index) => index !== removeIndex));
  };

  return (
    <div style={{ minHeight: 26, margin: '4px 0' }}>
      {tags.map((tag, index) => {
        if (editIndex === index) {
          return (
            <Input
              key={`input-${index}-${tag}`}
              type="text"
              autoFocus
              size={inputSize}
              value={editInputValue}
              onChange={(event) => {
                setEditInputValue(event.target.value);
              }}
              onBlur={resetAndHideEditInput}
              onPressEnter={handleEditInputPressEnter}
              style={inputStyle}
            />
          );
        }

        const isLongTag =
          Number.isInteger(truncateTagsLongerThan) && truncateTagsLongerThan > 0
            ? tag.length > truncateTagsLongerThan
            : false;

        const tagElem = (
          <Tag
            key={`tag-${index}-${tag}`}
            closable={!disabled}
            onClose={() => removeTag(index)}
            style={{
              userSelect: disabled ? 'text' : 'none',
              ...tagStyle,
              // Ensure 'X' is shown on the right and is centre-aligned with tag text
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <span
              onDoubleClick={(event) => {
                if (disabled) {
                  return;
                }

                editTag(index);
                event.preventDefault();
              }}
            >
              {isLongTag ? `${tag.slice(0, truncateTagsLongerThan)}...` : tag}
            </span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={`tooltip-${index}-${tag}`}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {disabled ? null : isAddInputVisible ? (
        <Input.TextArea
          autoFocus
          size={inputSize}
          value={addInputValue}
          onChange={(event) => {
            setAddInputValue(event.target.value);
          }}
          onBlur={resetAndHideAddInput}
          onPressEnter={handleAddInputPressEnter}
          autoSize={{ maxRows: 4 }}
          style={{ ...inputStyle, ...addInputTextboxStyle }}
        />
      ) : (
        <>
          <Tag
            onClick={showAddInput}
            style={{
              backgroundColor: 'transparent',
              borderStyle: 'dashed',
              cursor: 'pointer',
              ...tagStyle,
            }}
          >
            <PlusOutlined /> {addPrompt}
          </Tag>
          {tags.length > 0 && (
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={clearAllTags}
              placement="topRight"
            >
              <Tag
                color="processing"
                style={{
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                  ...tagStyle,
                }}
              >
                Clear All
              </Tag>
            </Popconfirm>
          )}
        </>
      )}
    </div>
  );
};

export default TagsSelector;
