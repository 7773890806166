import { Alert, Card, Collapse, Form, Input, InputNumber, Select } from 'antd';
import React, { useState } from 'react';

const validAdUnitNamePattern = /^[A-Za-z0-9_\-.*\\!<:()]+$/; // from https://support.google.com/admanager/answer/1628457#ad-unit-codes minus forward slashes

function determineNewSlotPath(oldSlotPath, btUnitName, insertionDepth, maxDepth) {
  if (oldSlotPath[0] !== '/') {
    oldSlotPath = `/${oldSlotPath}`; // Ensure old path begins with `/` so that our insertion algorithm works as expected
  }
  let newPathParts = oldSlotPath.split('/');

  const btUnitNameString = btUnitName || 'Blockthrough'; // Defaults to "Blockthrough" if no custom BT ad unit name is provided

  if (
    !validAdUnitNamePattern.test(btUnitNameString) || // Invalid ad unit name
    newPathParts.filter((part) => part !== '').length < 2 || // Invalid old slot path
    maxDepth < 2 // Invalid max depth
  ) {
    // A valid rewritten path cannot be creatRed, return empty string
    return '';
  } else {
    // Insert BT ad unit at `insertionDepth`
    // NOTE: If `insertionDepth` is equal or longer than the array length, it's appended at the end.
    newPathParts.splice(insertionDepth, 0, btUnitNameString);

    if (maxDepth && maxDepth > 0) {
      newPathParts = newPathParts.slice(0, maxDepth + 1);
    }

    return newPathParts.join('/');
  }
}

const mainLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const previewLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const rewritePathMethodOptions = [
  {
    value: 'insertBTAdUnit',
    label: 'Insert BT ad unit into slot path',
  },
];

const RewriteGoogletagSlotsForm = ({ formInstance, isViewOnly, saveError }) => {
  const [previewOldPath, setPreviewOldPath] = useState('');

  // Track `value.rewritePathMethod` form value to alter the displayed fields depending on method selected
  const rewritePathMethod = Form.useWatch(['value', 'rewritePathMethod'], formInstance);

  // Parse `value.rewritePathData` form value to alter associated fields & path preview display
  const rewritePathData = Form.useWatch(['value', 'rewritePathData'], formInstance);
  const { btUnitName, insertionDepth, maxDepth } = rewritePathData || {};

  const showPreviewPanel = insertionDepth && insertionDepth >= 2;
  const previewNewPath =
    showPreviewPanel && determineNewSlotPath(previewOldPath, btUnitName, insertionDepth, maxDepth);

  return (
    <Form
      name="settings-rewrite-googletag-slots"
      form={formInstance}
      disabled={isViewOnly}
      preserve={false}
      requiredMark={false}
      {...mainLayout}
    >
      <Collapse defaultActiveKey={['rewritePath']}>
        <Collapse.Panel key="rewritePath" header="Rewrite Slot Path">
          <Form.Item
            name={['value', 'rewritePathMethod']}
            label="Rewrite Path Method"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select options={rewritePathMethodOptions} />
          </Form.Item>

          <Form.Item noStyle>
            {rewritePathMethod === 'insertBTAdUnit' ? (
              <>
                <Form.Item
                  name={['value', 'rewritePathData', 'btUnitName']}
                  label={`BT Ad Unit Name`}
                  tooltip={`(Optional) The name of the BT ad unit that will be inserted into the new path (if not specified "Blockthrough" will be used).`}
                  rules={[
                    {
                      pattern: validAdUnitNamePattern,
                      message: 'Please input a valid ad unit name!',
                    },
                  ]}
                >
                  <Input placeholder="Blockthrough" />
                </Form.Item>
                <Form.Item
                  name={['value', 'rewritePathData', 'insertionDepth']}
                  label={`Insert at Depth`}
                  tooltip={`Determines which part of the slot path to insert the BT ad unit into. GAM network code is always located at depth 1.`}
                  rules={[
                    {
                      required: true,
                      type: 'integer',
                      min: 2,
                      message: 'Please input an insertion depth of at least 2!',
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  name={['value', 'rewritePathData', 'maxDepth']}
                  dependencies={[['value', 'rewritePathData', 'insertionDepth']]}
                  label={`Max Depth`}
                  tooltip={`(Optional) Determines the max length of the new rewritten slot path.`}
                  rules={[
                    {
                      type: 'integer',
                      min: insertionDepth || 2,
                      message: `Max depth cannot be smaller than ${
                        insertionDepth ? 'the depth of insertion' : '2'
                      }!`,
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
                {showPreviewPanel ? (
                  <Card
                    size="small"
                    title="Preview Path Rewrite"
                    style={{ opacity: 0.88, backgroundColor: '#FAFAFA' }}
                  >
                    <Form.Item
                      label="Old Slot Path"
                      style={{ marginTop: 4, marginBottom: 4 }}
                      {...previewLayout}
                    >
                      <Input
                        value={previewOldPath}
                        size="small"
                        onChange={(event) => {
                          setPreviewOldPath(event.target.value);
                        }}
                      />
                    </Form.Item>
                    {previewNewPath ? (
                      <Form.Item label="New Slot Path" {...previewLayout}>
                        <Input
                          bordered={false}
                          contentEditable={false}
                          value={previewNewPath}
                          size="small"
                        />
                      </Form.Item>
                    ) : null}
                  </Card>
                ) : null}
              </>
            ) : null}
          </Form.Item>
        </Collapse.Panel>
      </Collapse>
      {saveError && <Alert message={saveError.message} type="error" showIcon />}
    </Form>
  );
};

const settingConfig = {
  name: 'Rewrite googletag Slots',
  description:
    'Whether or not (and how) slots defined in the GPT `googletag` variable on the page should be redefined.',
  Form: RewriteGoogletagSlotsForm,
  initialSettingValue: { rewritePathMethod: 'insertBTAdUnit' },
};

export default settingConfig;
