import { Form, Input, Select } from 'antd';
import React from 'react';
import { TagsSelector } from '../../../components';

const DEVICE_MATCH_OPTIONS = [
  { value: 'any', label: 'Any device' },
  { value: 'desktop', label: 'Desktop' },
  { value: 'mobile', label: 'Mobile' },
];

export const WhenDevice = ({ field }) => (
  <Form.Item
    {...field}
    key={`${field.key}-when-device`}
    name={[field.name, 'whenDevice']}
    label="On"
    colon={false}
    rules={[
      {
        required: true,
      },
    ]}
  >
    <Select options={DEVICE_MATCH_OPTIONS} size="small" style={{ width: 110 }} />
  </Form.Item>
);

export const STRING_MATCH_OPTIONS_SINGLE = [
  { value: 'equals', label: 'Equals' },
  { value: 'startsWith', label: 'Starts with' },
  { value: 'endsWith', label: 'Ends with' },
  { value: 'includes', label: 'Includes' },
];

const STRING_MATCH_OPTIONS_LIST = [
  { value: 'equalsOneOf', label: 'Equals 1 of' },
  { value: 'startsWithOneOf', label: 'Starts with 1 of' },
  { value: 'endsWithOneOf', label: 'Ends with 1 of' },
  { value: 'includesOneOf', label: 'Includes 1 of' },
];

export const STRING_MATCH_OPTIONS = [...STRING_MATCH_OPTIONS_LIST, ...STRING_MATCH_OPTIONS_SINGLE];

export const WhenStringProp = ({
  propKey,
  propDisplayName,
  conditionPropKey,
  field,
  disabled,
  formInstance,
  parentFieldNamePath,
  tagsSelectorInputWidth = 180,
}) => {
  const conditionValue = formInstance.getFieldValue([
    ...parentFieldNamePath,
    field.name,
    conditionPropKey,
  ]);
  const isSetToListCondition = conditionValue ? conditionValue.endsWith('OneOf') : true; // Since "equalsOneOf" is the default condition value, this should default to `true` on initial render

  const handleConditionChange = (newConditionValue) => {
    // Clear the list/non-list prop value if switching between a list and non-list match/"when" condition
    let propValueTypeToClear;
    if (newConditionValue.endsWith('OneOf')) {
      if (!isSetToListCondition) {
        // Switching from non-list to list condition, clear non-list prop value
        propValueTypeToClear = 'single';
      }
    } else {
      if (isSetToListCondition) {
        // Switching from list to non-list condition, clear list prop value
        propValueTypeToClear = 'list';
      }
    }

    if (propValueTypeToClear) {
      // Ant Design forms require us to set values by providing the entire Object hierarchy up to the new value

      const currentParentFieldValue = formInstance.getFieldValue(parentFieldNamePath);
      const currentFieldValue = formInstance.getFieldValue([...parentFieldNamePath, field.name]);

      const newFieldValue = {
        ...currentFieldValue,
        [conditionPropKey]: newConditionValue,
      };
      delete newFieldValue[`${propValueTypeToClear === 'single' ? propKey : `${propKey}List`}`];

      let newParentFieldValue;
      if (Array.isArray(currentParentFieldValue)) {
        newParentFieldValue = [...currentParentFieldValue];
      } else {
        newParentFieldValue = { ...currentParentFieldValue };
      }
      newParentFieldValue[field.name] = newFieldValue;

      // Create the object hierarchy up to and including the field value we want to change
      const newFieldsValueObject = [...parentFieldNamePath]
        .reverse()
        .reduce((accumulator, pathKey) => ({ [pathKey]: accumulator }), newParentFieldValue);

      // Set the new field value with the now cleared prop value
      formInstance.setFieldsValue(newFieldsValueObject);
    }
  };

  return (
    <Form.Item label={`If ${propDisplayName}`} colon={false} style={field.style}>
      <Input.Group
        compact={!isSetToListCondition}
        style={{
          margin: '4px 0', // ensures `If ${propDisplayName}` label aligns with ensuing inputs
        }}
      >
        <Form.Item
          {...field}
          key={`${field.key}-${conditionPropKey}`}
          name={[field.name, conditionPropKey]}
          noStyle
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={STRING_MATCH_OPTIONS}
            disabled={disabled}
            size="small"
            dropdownMatchSelectWidth={false}
            onChange={handleConditionChange}
          />
        </Form.Item>
        {isSetToListCondition ? (
          <Form.Item
            {...field}
            key={`${field.key}-${propKey}List`}
            name={[field.name, `${propKey}List`]}
            rules={[
              {
                required: true,
                message: `Please input at least 1 ${propDisplayName} to match against!`,
              },
            ]}
            style={{ width: '100%' }}
          >
            <TagsSelector
              addPrompt={propDisplayName}
              disabled={disabled}
              inputWidth={tagsSelectorInputWidth}
            />
          </Form.Item>
        ) : (
          <Form.Item
            {...field}
            key={`${field.key}-${propKey}`}
            name={[field.name, propKey]}
            rules={[
              {
                required: true,
                whitespace: true,
                message: `Please input a ${propDisplayName} to match against!`,
              },
            ]}
            noStyle
          >
            <Input disabled={disabled} size="small" style={{ width: 200 }} />
          </Form.Item>
        )}
      </Input.Group>
    </Form.Item>
  );
};
