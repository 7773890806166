import { Alert, Form, Input } from 'antd';
import React from 'react';
import { CodeEditor } from '../../../components';
import { validateJson } from '../../../services/utils';

const btDemandConfigPlaceholder = `{
  "onetag": {
    "pubId": "75804861b76a852"
  },
  "criteo": [
    {
      "bottom_leaderboard": {
        "zoneId": "1409112"
      }
    },
    {
      "incontent_boxad_1-bottom": {
        "zoneId": "1405747"
      }
    }
  ]
}
`;

const AddBTDemandForm = ({ formInstance, isViewOnly, saveError }) => (
  <Form
    name="settings-add-bt-demand"
    form={formInstance}
    disabled={isViewOnly}
    preserve={false}
    requiredMark={false}
  >
    <Form.Item
      name={['value', 'sid']}
      label="Seller ID (sid)"
      tooltip="If left empty, the Organization ID will be used by default. Otherwise, this can be set to override that default value if the `seller_id` in `blockthrough.com/sellers.json` differs from the Organization ID."
      rules={[
        {
          whitespace: true,
          message: 'Please enter a valid Seller ID!',
        },
      ]}
    >
      <Input placeholder="5741666032943100" />
    </Form.Item>
    <Form.Item
      name={['value', 'asi']}
      label="Seller domain (asi)"
      tooltip="If left empty, 'blockthrough.com' will be used by default."
      rules={[
        {
          whitespace: true,
          message: 'Please enter a valid Seller domain (asi)!',
        },
      ]}
    >
      <Input placeholder="blockthrough.com" />
    </Form.Item>
    <Form.Item
      name={['value', 'btDemandConfig']}
      label="BT Demand Config"
      tooltip="BT Demand Config JSON object - If specifying a bidder's params by placement, provide them in an array where each entry includes the corresponding Space name."
      labelCol={{ span: 24 }}
      rules={[
        {
          required: true,
          validator: (rule, value) => validateJson(value),
          message: 'Please enter a valid BT Demand Config JSON object!',
        },
      ]}
    >
      <CodeEditor
        placeholder={btDemandConfigPlaceholder}
        height={360}
        disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
      />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const settingConfig = {
  name: 'Enable Unified Auction (BT Demand)',
  description:
    "Enables and configures BT Demand bidders within a publisher's demand stack in a unified auction.",
  Form: AddBTDemandForm,
  // Transform bt demand config to and from JS Object <-> JSON string because form must use string object but we want to store the value as an Object
  transformBeforeInit: ({ btDemandConfig, ...rest }) => ({
    btDemandConfig: JSON.stringify(btDemandConfig, null, 2),
    ...rest,
  }),
  transformBeforeSave: ({ btDemandConfig, ...rest }) => ({
    btDemandConfig: JSON.parse(btDemandConfig),
    ...rest,
  }),
  initialSettingValue: {},
};

export default settingConfig;
