import { ArrowLeftOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import api from '../services/api';
import { useAsync } from '../services/hooks';
import { useStore } from '../services/store';
import { PasswordForm } from './ForgotPassword';

const SetPassword = () => {
  const { execute: setPassword, status: setPasswordStatus, error: setPasswordError } = useAsync(
    api.setPassword
  );

  const onFinish = ({ password }) => setPassword({ password });

  const formText = {
    header: 'Set a password to activate your new account',
    successMsg: 'Password successfully set',
    successDesc: 'You can now login with your new account.',
    submitBtn: 'Set Password',
    loginRedirect: (
      <>
        <ArrowLeftOutlined /> Go to login screen
      </>
    ),
  };

  const formFields = (
    <Form.Item
      name="password"
      label="New Password"
      labelAlign="left"
      validateTrigger="onBlur"
      rules={[
        { required: true, min: 16, message: 'Please enter a password (min. 16 characters)!' },
      ]}
    >
      <Input.Password autoComplete="new-password" />
    </Form.Item>
  );

  return (
    <PasswordForm
      formText={formText}
      formFields={formFields}
      hideLoginLinkUntilSuccess
      onFinish={onFinish}
      status={setPasswordStatus}
      error={setPasswordError}
    />
  );
};

const InviteRedirectToLogin = () => {
  const { state } = useStore();
  const isLoggedIn = state.isAuthenticated();

  const formText = {
    successMsg: 'Invitation Accepted',
    successDesc: isLoggedIn
      ? 'You can now return to the home page.'
      : 'You can now login with your existing account.',
    loginRedirect: isLoggedIn ? (
      <>
        <ArrowLeftOutlined /> Go to the home page
      </>
    ) : (
      <>
        <ArrowLeftOutlined /> Go to login screen
      </>
    ),
  };

  return <PasswordForm formText={formText} formFields={null} status="success" />;
};

const InviteAccepted = () => {
  // Extract `new_user` from URL search params
  const [searchParams] = useSearchParams();
  const isNewUser = searchParams.get('new_user') === 'true';

  if (!isNewUser) {
    return <InviteRedirectToLogin />;
  } else {
    return <SetPassword />;
  }
};

export default InviteAccepted;
