import { Alert, Form } from 'antd';
import React from 'react';
import { CodeEditor, FormItemList } from '../../../components';
import { WhenDevice } from './formComponents';

const InsertCustomCSSForm = ({ formInstance, isViewOnly, saveError }) => (
  <Form
    name="settings-insert-custom-css"
    form={formInstance}
    disabled={isViewOnly}
    preserve={false}
    requiredMark={false}
  >
    <FormItemList
      name="value"
      disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
      addPrompt="Add CSS Styles Rules"
      addInitialValue={{ whenDevice: 'any' }}
      renderFormItem={({ field, disabled }) => (
        <>
          <WhenDevice field={field} />
          <Form.Item
            {...field}
            name={[field.name, 'customCSS']}
            rules={[
              {
                required: true,
                // TODO: Validate CSS
                // validator: (rule, value) => validateJson(value),
                message: 'Please enter some CSS style rules!',
              },
            ]}
          >
            <CodeEditor
              placeholder="CSS style rules"
              height={360}
              language="css"
              disabled={disabled}
            />
          </Form.Item>
        </>
      )}
    />
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const settingConfig = {
  name: 'Insert Custom CSS',
  description:
    'Insert Custom CSS styles onto the page (to fix styling issues or what have you) which are loaded on initialization of the client script.',
  Form: InsertCustomCSSForm,
  initialSettingValue: [],
};

export default settingConfig;
