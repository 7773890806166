import { ArrowRightOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { DragAndDropTable, OnceLoaded, TagsSelector } from '../../../components';
import api from '../../../services/api';
import { useAsync, useSessionUser } from '../../../services/hooks';
import { validateEmailAddress } from '../../../services/utils';
import FiltersSelect, { getFiltersSelectValue } from './FiltersSelect';
import ScheduleDays from './ScheduleDays';
import { hasSelectableMetrics, isDimensionSelectable, isMetricSelectable } from './predicates';

import styles from './ReportBuilder.module.less';

const ReportDimensions = ({
  value: selectedDimensions = [],
  onChange,
  dimensions,
  selectedMetrics,
}) => {
  const onDimensionsChange = (newSelectedDimensions) => {
    // 'Timestamp' must always be the first dimension if selected
    if (newSelectedDimensions.includes('ts')) {
      const timestampRemoved = newSelectedDimensions.filter((dimension) => dimension !== 'ts');
      onChange(['ts', ...timestampRemoved]);
    } else {
      onChange([...newSelectedDimensions]);
    }
  };

  return (
    <div className={styles.reportDimensions}>
      <div>
        <div className={styles.dimensionsHeader}>
          <Typography.Text strong>Report Dimensions</Typography.Text>
        </div>
        <Table
          size="small"
          bordered
          className={styles.availableReportDimensions}
          dataSource={dimensions}
          rowKey="value"
          rowSelection={{
            selectedRowKeys: selectedDimensions,
            onChange: onDimensionsChange,
            getCheckboxProps: (dimension) => ({
              disabled: !isDimensionSelectable({
                dimension,
                selectedMetrics,
              }),
            }),
          }}
          columns={[{ title: 'Dimensions', dataIndex: 'label' }]}
          pagination={false}
        />
      </div>
      <div className={styles.arrowContainer}>
        <ArrowRightOutlined />
      </div>
      <div className={styles.selectedReportDimensions}>
        <div className={styles.dimensionsHeader}>
          <Typography.Text strong>Selected Report Dimensions</Typography.Text>
        </div>
        <DragAndDropTable
          name="selected-report-dimensions"
          className={styles.selectedReportDimensions}
          columns={[{ dataIndex: 'label' }]}
          data={selectedDimensions.map((selectedDimension) =>
            dimensions.find(({ value }) => value === selectedDimension)
          )}
          setData={(newDimensions) => onDimensionsChange(newDimensions.map(({ value }) => value))}
          rowKey="value"
          fixedFirstRowKeyValue="ts"
        />
      </div>
    </div>
  );
};

const ReportMetrics = ({ value: selectedMetrics, onChange, metrics, selectedDimensions }) => {
  return (
    <Table
      size="small"
      bordered
      dataSource={metrics}
      rowKey="value"
      rowSelection={{
        selectedRowKeys: selectedMetrics,
        onChange,
        getCheckboxProps: (metric) => ({
          disabled: !isMetricSelectable({ metric, selectedDimensions }),
        }),
      }}
      columns={[{ title: 'Metrics', dataIndex: 'label' }]}
      pagination={false}
    />
  );
};

/** Transforms `fixedDateRange` (if defined/applicable) from the report builder form's values into payload values the Create/Edit Report API calls require */
function handleFixedDateRangeValues(formValues) {
  const { fixedDateRange, ...reportValuesToSave } = formValues;

  if (reportValuesToSave.range === 'fixed' && fixedDateRange) {
    // Parse `start_date` and `end_date` from `fixedDateRange`
    const startDate = fixedDateRange[0];
    const endDate = fixedDateRange[1];

    // Ensure `start_date` & `end_date` are set to the very start of the day and end of the day respectively
    reportValuesToSave.start_date = moment
      .utc(startDate.format('YYYY-MM-DD'))
      .startOf('day')
      .format();
    reportValuesToSave.end_date = moment.utc(endDate.format('YYYY-MM-DD')).endOf('day').format();
  }

  return reportValuesToSave;
}

const SCHEDULING_MESSAGE = {
  daily:
    'Report will be processed daily starting at 1pm (13:00:00) UTC time. Please allow some time to receive the report.',
  weekly:
    'Report will be processed on the selected days starting at 1pm (13:00:00) UTC time. Please allow some time to receive the report.',
  monthly:
    'The monthly report will be sent on the 1st of the month and include data from the prior month. The report will be processed starting at 1pm (13:00:00) UTC time, please allow some time to receive the report.',
};

const ReportBuilderForm = ({ orgId, loadedReport, reportFields, userEmail }) => {
  const shouldShowScheduling = !loadedReport?.global; // global template reports do not support scheduling

  const navigate = useNavigate();
  const navigateToSavedReports = () => navigate(`/reports?org_id=${orgId}`);

  const { ranges, filters, dimensions, metrics, scheduling_options } = reportFields;

  const [reportForm] = Form.useForm();

  // Initialize report builder form values
  useEffect(() => {
    // Can't seem to just pass in initialValues to the Form component when using Form.useForm() without bugs occuring, have to do this instead
    let initialValues;
    if (loadedReport) {
      // If loading an existing report into the Report Builder, pass in the loaded report's data into the form
      const { start_date, end_date, ...loadedReportValues } = loadedReport;
      // Parse & add `fixedDateRange` form input value if applicable
      if (loadedReportValues.range === 'fixed' && start_date && end_date) {
        initialValues = {
          ...loadedReportValues,
          fixedDateRange: [moment.utc(start_date), moment.utc(end_date)],
        };
      } else {
        initialValues = loadedReportValues;
      }
    } else {
      // If creating a new report in the Report Builder, initialize Date Range form input value to 'Last 30 days', schedule to 'Unscheduled', and recipient to logged-in user
      initialValues = { range: '30days', schedule: 'none', recipients: [userEmail] };
    }
    reportForm.setFieldsValue(initialValues);
  }, [reportForm, loadedReport, userEmail]);

  // Track the selected date range option (used to determine whether or not to display the fixed date range picker form item)
  const selectedDateRange = Form.useWatch('range', reportForm);

  // Track the currently selected dimensions & metrics (used to determine which dimensions & metrics are selectable)
  const selectedDimensions = Form.useWatch('dimensions', reportForm) || [];
  const selectedFilters = Form.useWatch('filters', reportForm) || {};
  const selectedDimensionsWithFilters = selectedDimensions.concat(
    getFiltersSelectValue({ value: selectedFilters })
  );
  const selectedMetrics = Form.useWatch('metrics', reportForm) || [];

  // Track the selected schedule (used to determine whether or not to display week day picker)
  const selectedSchedule = Form.useWatch('schedule', reportForm);

  const saveReportFunction = loadedReport
    ? (report) => api.updateReport({ id: loadedReport.id, ...report })
    : (report) => api.createReport({ org_id: orgId, ...report });

  const { execute: saveReport, status: saveReportStatus } = useAsync(saveReportFunction);

  // Generates the report according to the values submitted in the report builder form (without creating a new report or saving an existing report loaded into the report builder)
  const { execute: generateReport, status: generateReportStatus } = useAsync((report) =>
    api.generateReport({ org_id: orgId, ...report }).then(() => {
      return { report }; // Return generated report to be used in success modal
    })
  );

  const { execute: saveAndGenerateReport, status: saveAndGenerateReportStatus } = useAsync(
    (report) =>
      saveReportFunction(report).then(({ report }) =>
        api
          .generateReports({ ids: [report.id], org_id: orgId })
          .then(({ generated_ids, errors }) => {
            // TODO: More specific error handling to discern between errors in the save vs. errors in the generate
            return { report }; // Return generated report to be used in success modal
          })
      )
  );

  const isSavingOrGeneratingReport =
    saveReportStatus === 'pending' ||
    saveAndGenerateReportStatus === 'pending' ||
    generateReportStatus === 'pending';

  return (
    <Card>
      <Form
        name="report"
        form={reportForm}
        preserve={false}
        requiredMark="optional"
        layout="vertical"
        className={styles.reportBuilder}
      >
        <div className={styles.leftSection}>
          <Form.Item name="is_message_wall" hidden />
          <Form.Item
            name="name"
            label="Name of Report"
            rules={[
              {
                required: true,
                whitespace: true, // Providing only whitespace is considered invalid
                message: 'Please input a report name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="range"
            label="Date Range"
            rules={[
              {
                required: true,
                message: 'Please select a date range!',
              },
            ]}
          >
            <Select options={ranges} />
          </Form.Item>
          {selectedDateRange === 'fixed' ? (
            <Form.Item
              name="fixedDateRange"
              rules={[
                {
                  required: true,
                  message: 'Please specify a fixed date range!',
                },
              ]}
            >
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                disabledDate={(current) => {
                  // Disable dates after "Yesterday"
                  const dateIsAfterYesterday =
                    current && current.isAfter(moment().subtract(1, 'day'), 'day');
                  return dateIsAfterYesterday;
                }}
              />
            </Form.Item>
          ) : null}

          <Form.Item label="Timezone" required>
            <Typography.Text italic>All reports are generated in UTC time</Typography.Text>
          </Form.Item>

          <Form.Item
            name="recipients"
            label="Recipient Emails"
            rules={[
              {
                required: true,
                message: 'Please input at least 1 email address!',
              },
            ]}
          >
            <TagsSelector inputWidth="100%" inputSize="middle" validateTag={validateEmailAddress} />
          </Form.Item>
          <Form.Item name="filters" label="Filters">
            <FiltersSelect
              filters={filters}
              dimensions={dimensions}
              selectedMetrics={selectedMetrics}
            />
          </Form.Item>
          {shouldShowScheduling ? (
            <>
              <Form.Item name="schedule" label="Schedule">
                <Select
                  options={scheduling_options.map(({ label, value }) => ({ label, value }))}
                />
              </Form.Item>
              {selectedSchedule === 'weekly' ? (
                <Form.Item
                  name="schedule_days"
                  label="Repeat On"
                  rules={[
                    {
                      required: true,
                      message: 'Please select at least 1 day!',
                    },
                  ]}
                >
                  <ScheduleDays
                    options={scheduling_options.find(({ value }) => value === 'weekly').options}
                  />
                </Form.Item>
              ) : null}
              {SCHEDULING_MESSAGE[selectedSchedule] ? (
                <div className={styles.scheduleMessage}>
                  <Typography.Text italic>{SCHEDULING_MESSAGE[selectedSchedule]}</Typography.Text>
                </div>
              ) : null}
            </>
          ) : null}
        </div>

        <Divider type="vertical" style={{ margin: '0 30px' }} />

        <div>
          <Form.Item
            name="metrics"
            label="Report Metrics"
            rules={[
              {
                required: true,
                validator: (rule, selectedMetrics) =>
                  selectedMetrics?.length > 0
                    ? Promise.resolve(selectedMetrics)
                    : Promise.reject('Please select at least 1 report metric!'),
              },
            ]}
            extra={
              selectedMetrics.length === 0 &&
              !hasSelectableMetrics({
                metrics,
                selectedDimensions: selectedDimensionsWithFilters,
              })
                ? 'There are no selectable metrics based on the dimensions you currently have selected.'
                : ''
            }
          >
            <ReportMetrics metrics={metrics} selectedDimensions={selectedDimensionsWithFilters} />
          </Form.Item>

          <Form.Item name="dimensions">
            <ReportDimensions dimensions={dimensions} selectedMetrics={selectedMetrics} />
          </Form.Item>
        </div>

        {/* NOTE: The Report Builder does not support any "Save"-related actions when a global template report has been loaded into it */}
        <Space className={styles.reportBuilderFooter}>
          <Button onClick={navigateToSavedReports}>Cancel</Button>
          {loadedReport?.global ? null : (
            <Button
              loading={saveReportStatus === 'pending'}
              onClick={() => {
                if (!isSavingOrGeneratingReport) {
                  reportForm
                    .validateFields()
                    .then((formValues) => {
                      const reportValuesToSave = handleFixedDateRangeValues(formValues);

                      saveReport(reportValuesToSave)
                        .then(({ report }) => {
                          message.success('Report successfully saved!');
                          mutate(['/ReportGet', report.id]);
                          mutate(['/ReportList', report.org_id]);
                          navigateToSavedReports();
                        })
                        .catch((errorMessage) => {
                          Modal.error({
                            title: 'Failed to save report',
                            content: <Alert description={errorMessage} type="error" />,
                            width: 500,
                          });
                        });
                    })
                    .catch(() => {}); // ignore `validateFields` promise rejection
                }
              }}
            >
              Save Report
            </Button>
          )}

          <Button
            type={loadedReport?.global ? 'primary' : 'default'} // Show "Generate" as a primary button/action when a global template report has been loaded in to the Report Builder
            loading={generateReportStatus === 'pending'}
            onClick={() => {
              if (!isSavingOrGeneratingReport) {
                reportForm
                  .validateFields()
                  .then((formValues) => {
                    const reportValuesToGenerate = handleFixedDateRangeValues(formValues);

                    generateReport(reportValuesToGenerate)
                      .then(({ report }) => {
                        Modal.success({
                          content: (
                            <div>
                              <p>The report is being generated.</p>
                              <p>Once generated the report will be emailed to:</p>
                              <ul>
                                {report.recipients?.map((recipient) => (
                                  <li key={recipient}>{recipient}</li>
                                ))}
                              </ul>
                            </div>
                          ),
                          onOk: () => {
                            mutate(['/ReportGet', report.id]);
                            mutate(['/ReportList', report.org_id]);
                            navigateToSavedReports();
                          },
                          width: 450,
                        });
                      })
                      .catch((errorMessage) => {
                        Modal.error({
                          title: 'Failed to generate report',
                          content: <Alert description={errorMessage} type="error" />,
                          width: 500,
                        });
                      });
                  })
                  .catch(() => {}); // ignore `validateFields` promise rejection
              }
            }}
          >
            Generate Report
          </Button>

          {loadedReport?.global ? null : (
            <Button
              type="primary"
              loading={saveAndGenerateReportStatus === 'pending'}
              onClick={() => {
                if (!isSavingOrGeneratingReport) {
                  reportForm
                    .validateFields()
                    .then((formValues) => {
                      const reportValuesToSave = handleFixedDateRangeValues(formValues);

                      saveAndGenerateReport(reportValuesToSave)
                        .then(({ report }) => {
                          Modal.success({
                            content: (
                              <div>
                                <p>The report has been saved and is being generated.</p>
                                <p>Once generated the report will be emailed to:</p>
                                <ul>
                                  {report.recipients?.map((recipient) => (
                                    <li key={recipient}>{recipient}</li>
                                  ))}
                                </ul>
                              </div>
                            ),
                            onOk: () => {
                              mutate(['/ReportGet', report.id]);
                              mutate(['/ReportList', report.org_id]);
                              navigateToSavedReports();
                            },
                            width: 450,
                          });
                        })
                        .catch((errorMessage) => {
                          // TODO: More specific error handling to discern between errors in the save vs. errors in the generate
                          Modal.error({
                            title: 'Failed to save & generate report',
                            content: <Alert description={errorMessage} type="error" />,
                            width: 500,
                          });
                        });
                    })
                    .catch(() => {}); // ignore `validateFields` promise rejection
                }
              }}
            >
              Save and Generate Report
            </Button>
          )}
        </Space>
      </Form>
    </Card>
  );
};

const ReportBuilder = ({ orgId, report: loadedReport }) => {
  const { email: userEmail } = useSessionUser();

  const { data: reportFields, error: reportFieldsError } = useSWR(
    ['/ReportMetricsOptions', orgId],
    () => api.getReportFields(orgId, loadedReport?.is_message_wall)
  );

  return (
    <OnceLoaded error={reportFieldsError} isLoading={!reportFields}>
      <ReportBuilderForm
        orgId={orgId}
        loadedReport={loadedReport}
        reportFields={reportFields}
        userEmail={userEmail}
      />
    </OnceLoaded>
  );
};

export default ReportBuilder;
