import { PageHeader, Tabs } from 'antd';
import React from 'react';
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { OnceLoaded, ScopePageByOrg, ScopePageForm, useScopePageByOrg } from '../../components';
import { PageContents } from '../../layout';
import ReportBuilder from './ReportBuilder';
import styles from './Reports.module.less';
import SavedReports from './SavedReports';

const Reports = () => {
  const navigate = useNavigate();

  const onSavedReports = useMatch('/reports');
  const onReportBuilder = useMatch('/reports/:id');

  const activeTabKey = onSavedReports ? 'saved-reports' : 'report-builder';

  const isCreatingNewReport = onReportBuilder?.params?.id === 'create';
  const existingReportId = !isCreatingNewReport && onReportBuilder?.params?.id;

  // Fetch and load the existing report into the Report Builder (if applicable)
  const { data: report, error: reportError, isLoading: isLoadingReport } = useSWR(
    existingReportId ? ['/ReportGet', existingReportId] : null
  );

  // When editing an existing (non-global) report in the Report Builder, use the `org_id` of the report entity as the source of truth (and override any `org_id` search param that may be in the URL)
  // (We wait until we can determine if it's global or not before the page gets scoped to an org)
  const { scopedOrg, orgSelectProps, orgsError } = useScopePageByOrg({
    waitToScopeOrg: isLoadingReport,
    orgIdOverride: report && !report.global ? report?.org_id : null,
  });

  const orgId = scopedOrg?.id;

  return (
    <OnceLoaded
      error={orgsError}
      isLoading={!scopedOrg} // We always need an org to scope the Saved Reports or Report Builder page to
      render={() => (
        <>
          <PageHeader
            key="header"
            ghost={false}
            title="Reports"
            extra={
              <ScopePageForm>
                <ScopePageByOrg
                  scopedOrg={scopedOrg}
                  orgSelectProps={{
                    ...orgSelectProps,
                    isLocked: orgSelectProps.isLocked || activeTabKey === 'report-builder',
                  }}
                />
              </ScopePageForm>
            }
            footer={
              <Tabs
                activeKey={activeTabKey}
                onChange={(newTabKey) => {
                  if (newTabKey === 'saved-reports') {
                    navigate(`/reports?org_id=${orgId}`);
                  } else if (newTabKey === 'report-builder') {
                    navigate(`/reports/create?org_id=${orgId}`);
                  }
                }}
              >
                <Tabs.TabPane tab="Saved Reports" key="saved-reports" />
                <Tabs.TabPane tab="Report Builder" key="report-builder" />
              </Tabs>
            }
          />
          <PageContents
            className={activeTabKey === 'report-builder' ? styles.reportBuilderContainer : ''}
          >
            <Routes>
              <Route index element={<SavedReports orgId={orgId} />} />
              <Route path="/create" element={<ReportBuilder orgId={orgId} />} />
              <Route
                path="/:reportId"
                element={
                  <OnceLoaded
                    error={reportError}
                    isLoading={!report}
                    render={() => <ReportBuilder orgId={orgId} report={report} />}
                  />
                }
              />
            </Routes>
          </PageContents>
        </>
      )}
    />
  );
};

export default Reports;
