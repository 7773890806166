import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-clike'; // required to load prism-javascript
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
import './CodeEditor.css';
import React from 'react';
import ReactDiffViewer from 'react-diff-viewer';

/**
 * NOTE: `language` can be either: 'json', 'js', or 'css'
 */
const CodeDiffViewer = ({
  value = { before: '', after: '' },
  language = 'json',
  splitView = true,
  leftTitle,
  rightTitle,
  height = 550,
  overflowY = 'auto',
}) => {
  return (
    <div style={{ height, overflowY }}>
      <ReactDiffViewer
        oldValue={value.before}
        newValue={value.after}
        splitView={splitView}
        leftTitle={leftTitle}
        rightTitle={rightTitle}
        disableWordDiff={true}
        showDiffOnly={false}
        styles={{
          line: {
            fontSize: '12px !important',
            '& pre': {
              lineHeight: '1.5 !important',
            },
          },
        }}
        renderContent={(str) =>
          str ? (
            <pre
              style={{ display: 'inline' }}
              dangerouslySetInnerHTML={{
                __html: highlight(str, languages[language]),
              }}
            />
          ) : null
        }
      />
    </div>
  );
};

export default CodeDiffViewer;
