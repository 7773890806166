import { Form, Input } from 'antd';

const CustomCTASettings = ({ env }) => {
  return (
    <>
      <Form.Item
        label="Primary CTA URL"
        required
        name={[env, 'am_settings', 'custom_cta_modal', 'primary_cta_url']}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Secondary CTA URL"
        required
        name={[env, 'am_settings', 'custom_cta_modal', 'secondary_cta_url']}
      >
        <Input />
      </Form.Item>
    </>
  );
};

export default CustomCTASettings;
