import { createBooleanSettingForm } from './booleanSetting';

const settingConfig = {
  name: 'Replace Incompliant Sizes',
  description: `Whether or not to enable the (legacy) size replacement logic which swaps incompliant sizes with compliant ones (as opposed to just removing them, which is the default behaviour).`,
  Form: createBooleanSettingForm(
    'settings-enable-legacy-sizes-replacer',
    'Replace incompliant sizes'
  ),
  initialSettingValue: true,
};

export default settingConfig;
