import { Alert, Form, Input, message } from 'antd';
import React from 'react';
import api from '../services/api';
import { SaveEntityModalControlledVisibility } from '../services/handlers';

const ChangePasswordForm = ({ formInstance, saveError }) => (
  <Form
    name="change-password"
    form={formInstance}
    preserve={false}
    requiredMark={false}
    labelCol={{ span: 7 }}
    wrapperCol={{ span: 17 }}
  >
    <Form.Item
      name="old_password"
      label="Current Password"
      rules={[{ required: true, message: 'Please confirm your current password!' }]}
    >
      <Input.Password autoComplete="current-password" />
    </Form.Item>
    <Form.Item
      name="password"
      label="New Password"
      hasFeedback
      validateTrigger="onBlur"
      rules={[
        { required: true, min: 16, message: 'Please input a new password (min. 16 characters)!' },
      ]}
    >
      <Input.Password autoComplete="new-password" />
    </Form.Item>
    <Form.Item
      name="confirm"
      label="Confirm Password"
      dependencies={['password']}
      hasFeedback
      validateTrigger="onBlur"
      rules={[
        {
          required: true,
          message: 'Please confirm your new password!',
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject('The two passwords that you entered do not match!');
          },
        }),
      ]}
    >
      <Input.Password autoComplete="new-password" />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const ChangePassword = ({ isVisible, setVisibility }) => {
  const changeUserPassword = ({ old_password, password }) =>
    api.setPassword({ old_password, password });

  return (
    <SaveEntityModalControlledVisibility
      key="change-password"
      isVisible={isVisible}
      setVisibility={setVisibility}
      modalTitle="Change Password"
      saveEntity={changeUserPassword}
      onSuccess={() => {
        // Show success message
        message.success('Password successfully updated!');
      }}
      formComponent={ChangePasswordForm}
    />
  );
};

export default ChangePassword;
