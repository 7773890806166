import { ArrowLeftOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, Row, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logos/bt-logo-round-word-inline-blue-alpha.svg';
import api from '../services/api';
import { useAsync } from '../services/hooks';

const successAlertStyle = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '14px',
  padding: '30px 35px',
  marginBottom: '30px',
};

export const PasswordForm = ({
  formText,
  formFields,
  hideLoginLinkUntilSuccess,
  onFinish,
  status,
  error,
}) => (
  <>
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ width: '100%', padding: '0 20px 80px' }}
    >
      <Col style={{ width: 490 }}>
        <img
          src={logo}
          alt="Blockthrough logo"
          style={{ display: 'block', margin: '0 auto', height: 160, maxWidth: '100%' }}
        />
        {status === 'success' ? (
          <Alert
            type="success"
            style={successAlertStyle}
            icon={<CheckCircleOutlined style={{ fontSize: 45 }} />}
            message={formText.successMsg}
            description={formText?.successDesc}
            showIcon
          />
        ) : (
          <Form
            name="password-form"
            onFinish={onFinish}
            layout="vertical"
            style={{ minHeight: 230, maxWidth: 320, margin: '0 auto' }}
          >
            <Form.Item>
              <Typography.Text style={{ fontSize: '17px' }}>{formText.header}</Typography.Text>
            </Form.Item>
            {formFields}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={status === 'pending'}
                style={{ width: '100%' }}
              >
                {formText.submitBtn}
              </Button>
            </Form.Item>
            {error && (
              <Alert message={error.message} type="error" showIcon style={{ marginBottom: 20 }} />
            )}
          </Form>
        )}
        {!hideLoginLinkUntilSuccess || (hideLoginLinkUntilSuccess && status === 'success') ? (
          <div style={{ textAlign: 'center' }}>
            <Link to="/login">
              {formText.loginRedirect || (
                <>
                  <ArrowLeftOutlined /> Return to login screen
                </>
              )}
            </Link>
          </div>
        ) : null}
      </Col>
    </Row>
  </>
);

export const ForgotPassword = () => {
  const {
    execute: sendForgotPasswordEmail,
    status: forgotPasswordEmailStatus,
    error: forgotPasswordEmailError,
  } = useAsync(api.forgotPassword);

  const onFinish = ({ email }) => sendForgotPasswordEmail({ email });

  const formText = {
    header: "Enter your email and we'll send you a link to reset your password",
    successMsg: 'Email sent',
    successDesc: (
      <p>
        Check your email and open the link we sent to reset your password.
        <br />
        If you don't see the email in your inbox, make sure you typed your email address correctly.
      </p>
    ),
    submitBtn: 'Send password reset link',
  };

  const formFields = (
    <Form.Item
      name="email"
      label="Email"
      validateTrigger="onBlur"
      rules={[
        { required: true, message: 'Please enter your email!' },
        { type: 'email', message: 'Please enter a valid email address!' },
      ]}
    >
      <Input autoComplete="username" />
    </Form.Item>
  );

  return (
    <PasswordForm
      formText={formText}
      formFields={formFields}
      onFinish={onFinish}
      status={forgotPasswordEmailStatus}
      error={forgotPasswordEmailError}
    />
  );
};

export const ResetPassword = () => {
  const {
    execute: resetPassword,
    status: resetPasswordStatus,
    error: resetPasswordError,
  } = useAsync(api.setPassword);

  const onFinish = ({ password }) => resetPassword({ password });

  const formText = {
    header: 'Reset your password below',
    successMsg: 'Password successfully reset',
    successDesc: 'You can now login with your new password.',
    submitBtn: 'Reset Password',
  };

  const formFields = (
    <Form.Item
      name="password"
      label="New Password"
      labelAlign="left"
      validateTrigger="onBlur"
      rules={[
        { required: true, min: 16, message: 'Please input a new password (min. 16 characters)!' },
      ]}
    >
      <Input.Password autoComplete="new-password" />
    </Form.Item>
  );

  return (
    <PasswordForm
      formText={formText}
      formFields={formFields}
      hideLoginLinkUntilSuccess
      onFinish={onFinish}
      status={resetPasswordStatus}
      error={resetPasswordError}
    />
  );
};
