import { DownloadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { CSVLink } from 'react-csv';

const DownloadCSV = ({ filename, data, headers, tooltip = 'Download CSV', className }) => {
  return data ? (
    <Tooltip title={tooltip} className={className}>
      <CSVLink filename={filename} data={data} headers={headers} target="_blank">
        <DownloadOutlined />
      </CSVLink>
    </Tooltip>
  ) : null;
};

export default DownloadCSV;
