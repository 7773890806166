import { Alert, Radio, Form } from 'antd';
import React from 'react';
import { HelpTooltip } from '../../../components';

/**
 * NOTE: using radio input here with the intention of adding other default methods here in the future
 * Default fallback methods will likely be mutually exclusive
 */
const DefaultCreativeSettingsForm = ({ formInstance, isViewOnly, saveError }) => (
  <Form
    name="settings-default-creatives"
    form={formInstance}
    disabled={isViewOnly}
    preserve={false}
    requiredMark={false}
  >
    <Form.Item
      label="Fallback Creative Type"
      name={['value', 'method']}
      rules={[{ required: true, message: 'Please select a fallback creative type!' }]}
    >
      <Radio.Group>
        <Radio value="adDefend">
          Enable AdDefend Fallback Creatives
          <HelpTooltip title="Select this to enable AdDefend fallback using generic AdDefend tag." />
        </Radio>
        <Radio value="none">
          Disable all Default Creatives
          <HelpTooltip title="This option will disable all default creatives, including SMC." />
        </Radio>
      </Radio.Group>
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const settingConfig = {
  name: 'Default Creative Settings',
  description: 'Used to control settings for default/fallback creatives.',
  Form: DefaultCreativeSettingsForm,
  initialSettingValue: {},
};

export default settingConfig;
