import { Alert, Form, Select } from 'antd';
import React, { useMemo } from 'react';
import useSWR from 'swr';
import { isUserInternal } from '../pages/Users';
import { SaveEntityModalControlledVisibility } from '../services/handlers';
import { useSessionUser } from '../services/hooks';
import { sortAlphabetically } from '../services/utils';

const ImpersonateUserForm = ({ externalUsers, formInstance, saveError }) => (
  <Form name="impersonate-user" form={formInstance} preserve={false} requiredMark={false}>
    <Form.Item
      name="email"
      rules={[{ required: true, message: 'Please select a user to impersonate!' }]}
    >
      <Select
        allowClear
        options={externalUsers}
        loading={!externalUsers}
        showSearch
        fieldNames={{ label: 'email', value: 'email' }}
        placeholder="Select a user to impersonate"
      />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const ImpersonateUser = ({ isVisible, setVisibility }) => {
  const { impersonateUser } = useSessionUser();

  const { data: users } = useSWR('/UserList');

  // Only external users who are associated with an organization can be impersonated
  const externalUsers = useMemo(
    () =>
      sortAlphabetically(
        users?.filter((user) => !isUserInternal(user) && user.permissions.length > 0),
        'email'
      ),
    [users]
  );

  return (
    <SaveEntityModalControlledVisibility
      key="impersonate-user"
      isVisible={isVisible}
      setVisibility={setVisibility}
      modalTitle="Impersonate User"
      transformBeforeSave={({ email }) =>
        // Pass in the associated user object to `impersonateUser` from the email selected in the form
        externalUsers.find((user) => user.email === email)
      }
      saveEntity={impersonateUser}
      formComponent={ImpersonateUserForm}
      formComponentProps={{ externalUsers }}
    />
  );
};

export default ImpersonateUser;
