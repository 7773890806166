import { Typography } from 'antd';
import React from 'react';

const LoggedOutFooter = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        paddingBottom: '24px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ paddingRight: '8px' }}>
          <a
            href="https://blockthrough.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            PRIVACY POLICY
          </a>
        </div>
        <div style={{ paddingLeft: '8px' }}>
          <a href="https://blockthrough.com" target="_blank" rel="noopener noreferrer">
            BACK TO WEBSITE
          </a>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography.Text style={{ fontSize: '12px', color: '#888888' }}>
          Copyrights © 2023 Blockthrough, Inc., an eyeo group company. All rights reserved.
        </Typography.Text>
      </div>
    </div>
  );
};

export default LoggedOutFooter;
