import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Collapse, Dropdown, Form, Select, Space, Tooltip } from 'antd';
import React from 'react';
import { FormItemList } from '../../../components';
import { WhenDevice, WhenStringProp } from './formComponents';

const RefreshUnit = ({ formInstance, field, disabled, parentFieldNamePath }) => {
  const fieldValue = formInstance.getFieldValue([...parentFieldNamePath, field.name]);

  if (!fieldValue) {
    // Don't render anything until we've determined if we're selecting unit by 'space name' or by 'slot path'
    return null;
  }

  // Render different form items depending on if we're selecting unit by 'name' or by 'slot path'
  // (Either `whenName` or `whenSlotPath` will be set in the initial value when added in <FormItemList>.)
  const isSelectBySpaceName = !!fieldValue.whenName;
  const isSelectBySlotPath = !!fieldValue.whenSlotPath;

  const whenStringPropProps = {
    field,
    disabled,
    formInstance,
    parentFieldNamePath,
  };
  if (isSelectBySpaceName) {
    whenStringPropProps.propKey = 'name';
    whenStringPropProps.propDisplayName = 'Name';
    whenStringPropProps.conditionPropKey = 'whenName';
  } else if (isSelectBySlotPath) {
    whenStringPropProps.propKey = 'slotPath';
    whenStringPropProps.propDisplayName = 'Slot Path';
    whenStringPropProps.conditionPropKey = 'whenSlotPath';
    whenStringPropProps.tagsSelectorInputWidth = '100%';
  }

  return (
    <>
      <WhenStringProp {...whenStringPropProps} />
      <WhenDevice field={field} />
    </>
  );
};

const unitVisibilityOptions = [
  { value: 'IS_VISIBLE', label: 'Only visible ad units' },
  { value: 'IS_HIDDEN', label: 'Only hidden ad units' },
  { value: 'ANY', label: 'All ad units' },
];

const intervalOptions = [
  { value: 30000, label: '30s' },
  { value: 60000, label: '60s' },
];

const RefreshOnIntervalForm = ({ formInstance, isViewOnly, saveError }) => {
  const specificAdUnitsToRefresh = Form.useWatch(['value', 'forSpaces'], formInstance);

  return (
    <Form
      name="settings-refresh-on-interval"
      form={formInstance}
      disabled={isViewOnly}
      preserve={false}
      requiredMark={false}
    >
      <Form.Item
        name={['value', 'whenVisibility']}
        label="Ad Units Visibility"
        tooltip={`An ad unit is considered "visible" if at least 50% of it is within the user's viewport (otherwise, it's considered "hidden").`}
      >
        <Select options={unitVisibilityOptions} disabled />
      </Form.Item>

      <Form.Item
        name={['value', 'interval']}
        label="Refresh Interval"
        tooltip={`The amount of time after the end of the Space's Unit's auction to wait before attempting the refresh.`}
      >
        <Select options={intervalOptions} />
      </Form.Item>

      <Collapse defaultActiveKey={['forSpaces']}>
        <Collapse.Panel
          key="forSpaces"
          header={
            <Tooltip title="In-view ad refreshes are only permitted on a single ad unit on the page. If you input more than one ad unit, ensure that the ad units do not appear on the same page.">
              Ad Units to Refresh
            </Tooltip>
          }
        >
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            {!specificAdUnitsToRefresh?.length ? (
              <Alert
                message={`No ad units to refresh have been specified; therefore, all ad units on the page with the "Ad Units Visibility" set above will be refreshed.`}
              />
            ) : null}
            <FormItemList
              name={['value', 'forSpaces']}
              disabled={isViewOnly} // Form-level `disabled` prop doesn't work with 3rd-party inputs
              renderAddItem={({ add }) => (
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: 'By space name',
                        key: 'by-space-name',
                      },
                      {
                        label: 'By googletag slot path',
                        key: 'by-slot-path',
                      },
                    ],
                    onClick: ({ key }) => {
                      if (key === 'by-space-name') {
                        add({ whenName: 'equalsOneOf', whenDevice: 'any' });
                      } else if (key === 'by-slot-path') {
                        add({ whenSlotPath: 'equalsOneOf', whenDevice: 'any' });
                      }
                    },
                  }}
                >
                  <Button type="dashed" block icon={<PlusOutlined />}>
                    Add specific ad units to refresh
                  </Button>
                </Dropdown>
              )}
              renderFormItem={({ field, formItemListName, disabled }) => (
                <RefreshUnit
                  formInstance={formInstance}
                  field={field}
                  disabled={disabled}
                  parentFieldNamePath={formItemListName}
                />
              )}
            />
          </Space>
        </Collapse.Panel>
      </Collapse>
      {saveError && <Alert message={saveError.message} type="error" showIcon />}
    </Form>
  );
};

const settingConfig = {
  name: 'Refresh with BT Focus',
  description: `Refreshes selected ad unit(s) when in-view and after the indicated time interval.`,
  Form: RefreshOnIntervalForm,
  initialSettingValue: { whenVisibility: 'IS_VISIBLE', interval: 30000 },
};

export default settingConfig;
